import React from "react";
import {Input, Select, Tooltip} from "antd";
import {inject, observer} from "mobx-react";
import {SiretValidation} from "../../../helpers";
import lang from "../../../lang/fr"
import CpEditForm from "../CpEditForm";
import CpAjaxSelect from "../CpAjaxSelect";
import CpAjaxSelectAdd from "../CpAjaxSelectAdd";
import DataService from "../../../services/dataService";
import endpoint from "../../../services/endpointEnum";

/**
 * So ugly code, in future don't reproduce this
 */

@inject("dictionary")
@observer
class FormAgency extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      values: props.values && props.values.department ?
        {...props.values, department: props.values.department.code} :
        {...props.values},
      isCreationMode: !props.values.id, //define if new or edit mode
      cities: [],
      selectedDepartment: props.values.id ? props.values.department : {}, //for fill data source
      isAdvancedMode: false,
      organismeRegroupements: [],
    };

    this.handleSearchPostalCode = this.handleSearchPostalCode.bind(this);
  }

  componentDidMount() {
    const {isCreationMode, values} = this.state;
    this.setState(
      {organismeRegroupements: this.props.dictionary.organismeRegroupements});
    //preload cities data for select
    if (!isCreationMode && values.addressZipcode) {
      this.handleSearchPostalCode(values.addressZipcode, true);
    }
  }

  /**
   * On typing in the select, perform a search
   * @param {string|number} postalCode
   * @param {boolean} updateOnlyCities
   */
  handleSearchPostalCode = (postalCode, updateOnlyCities = false) => {
    if (postalCode.length === 5) {
      DataService.getVilles(postalCode).then(({data}) => {
        if (updateOnlyCities) {
          this.setState({
            cities: data["hydra:member"],
          });
        } else {
          this.setState({
            cities: data["hydra:member"],
            values: {
              ...this.state.values,
              addressZipcode: postalCode,
              addressCity: "",
              department: "",
            },
            selectedDepartment: data["hydra:member"].length > 0 ?
              data["hydra:member"][0].department :
              {},
          });
        }
      });
    }
  };

  onSelectCity = (city) => {
    if (city == null)
      return;

    this.setState({
      values: {
        ...this.state.values,
        department: (city.zipCode === "" ?
          this.state.selectedDepartment?.code :
          city.department.code),
        addressCity: city.name,
      },
    });
  };

  handleSiretChange = (siretValue) => {
    if (SiretValidation(siretValue)) {
      DataService.getBySiret(siretValue).then(({data}) => {
        this.setState({
          values: {
            ...this.state.values,
            addressStreet: data.addressStreet,
            addressCity: data.addressCity,
            addressZipcode: data.addressZipcode,
            department: data.department.code,
            name: data.name,
            siret: siretValue,
            siren: siretValue.substring(0, 9),
          },
          isAdvancedMode: true,
          selectedDepartment: data.department,
        });
      }).catch(() => {
        this.setState({
          values: {
            ...this.state.values,
            addressStreet: null,
            addressCity: null,
            addressZipcode: null,
            department: null,
            name: null,
            siret: siretValue,
            siren: siretValue.substring(0, 9),
          },
        });
      });
    } else {
      this.setState({
        values: {
          ...this.state.values,
          siret: siretValue,
          siren: siretValue.substring(0, 9),
        },
      });
    }
  };

  handleAdvancedModeChange = () => {
    this.setState({
      isAdvancedMode: !this.state.isAdvancedMode,
    });
  };
  getDepartementByCode = (selectedValue = {}) => {
    this.setState({
      values: {...this.state.values, department: selectedValue},
      selectedDepartment: selectedValue,
    });
  };

  render() {
    const {formRef, setRequiredFields, onFieldsChange} = this.props;
    const {values, cities, isCreationMode, isAdvancedMode} = this.state;
    const fields = [
      {
        label: lang.agence.formulaire.label.nom,
        flex: "100%",
        key: "name",
        input: <Input placeholder="-" onChange={(e) =>
          this.setState({
            values: {
              ...this.state.values,
              name: e.target.value,
            },
          })
        }
        />,
        options: {
          rules:
            [{required: true, message: lang.agence.formulaire.messageErreur.nomObligatoire}],
        },
      },
      {
        label: lang.agence.formulaire.label.siret,
        flex: "50%",
        key: "siret",
        input: <Input placeholder="-" onChange={(e) => this.handleSiretChange(
          e.target.value)}/>,
        options: {
          rules:
            [
              {required: true, message: lang.agence.formulaire.messageErreur.siretObligatoire},
              {
                validator: (_, value) => {
                  return !value || SiretValidation(value) ?
                    Promise.resolve() :
                    Promise.reject(new Error(lang.agence.formulaire.messageErreur.siretInvalide));
                },
              },
            ],
        },
      },
      {
        label: lang.agence.formulaire.label.siren,
        flex: "50%",
        key: "siren",
        input: <Tooltip
          title={lang.agence.formulaire.titreInput.sirenTooltip}>
          <Input placeholder="-" value={this.state.values.siren} disabled/>
        </Tooltip>,
        options: {
          rules:
            [{required: true, message: lang.agence.formulaire.messageErreur.sirenObligatoire}],
        },
      },
    ];

    if (isCreationMode) {
      fields.push({
        label: isAdvancedMode ? lang.agence.formulaire.label.creationSimple : lang.agence.formulaire.label.creationAvance,
        key: "advancedMode",
        type: "advancedMode",
      });
    }
    if (isAdvancedMode || !isCreationMode) {
      fields.push({
        label: lang.agence.formulaire.label.adresse,
        flex: "67%",
        key: "addressStreet",
        input: <Input placeholder="-" onChange={(e) =>
          this.setState({
            values: {
              ...this.state.values,
              addressStreet: e.target.value,
            },
          })
        }
        />,
      });
      fields.push({
        label: "Regroupement",
        flex: "33%",
        key: "organismeRegroupement",
        input:
          <Select
            placeholder="-"
          >
            {
              this.state.organismeRegroupements.map((o) =>
                <Select.Option
                  value={o && o.id ? o.id : null}
                  key={o ? o?.name : "sansOrganisme"}
                >
                  {o.name || ""} {o && o !== "" ?
                  <img className={"select-option-logo"} src={o.logo}
                       alt={"logo"}/>
                  : ""}
                </Select.Option>)}
          </Select>,
      });
      fields.push({
        label: lang.agence.formulaire.label.codePostal,
        flex: "33%",
        key: "addressZipcode",
        input: <Input
          onChange={(event) => this.handleSearchPostalCode(event.target.value)}
          placeholder="-"/>,
        options: {
          rules: [{pattern: "^[0-9]*$", message: lang.agence.formulaire.messageErreur.formatInvalide}],
        },
      });
      fields.push({
        label: lang.agence.formulaire.label.ville,
        flex: "34%",
        clone: true,
        key: "addressCity",
        input: <CpAjaxSelectAdd
          endPoint={endpoint.VILLE_RECHERCHE()}
          labelField="name"
          cities={cities}
          onSelectCity={this.onSelectCity}
          selectedValue={values.addressCity}
          placeholder="-"
        />,
        options: {
          rules: [{required: false}],
        },
      });

      fields.push({
        label: lang.agence.formulaire.label.departement,
        flex: "33%",
        key: "department",
        input: <CpAjaxSelect
          endPoint={endpoint.DEPARTEMENT_RECHERCHE}
          labelField="name"
          searchField="name"
          selectedField="code"
          updateExternalDatasource={true}
          dataSource={this.state.selectedDepartment ?
            [this.state.selectedDepartment] :
            []}
          placeholder="-"
          selectedValue={values?.department ? values?.department : undefined}
          onChange={(department) => {
            if (!department)
              return;
            //can be string (selected by user) or object (injected by another component like city field)
            if (isCreationMode) {
              this.setState(
                {values: {...this.state.values, department: department}});
            } else {
              this.getDepartementByCode(department);
            }
          }}

        />,
        options: {
          // value: this.props.values && this.props.values.department ? this.props.values.department : undefined,
          rules: [{required: false}],
        },
      });
    }

    return <>
      <CpEditForm formRef={formRef}
                  title={!values.id ? lang.agence.formulaire.titreInput.ajoutAgence : null}
                  fields={fields}
                  values={values}
                  setRequiredFields={setRequiredFields}
                  onFieldsChange={onFieldsChange}
                  handleClickAdvancedMode={this.handleAdvancedModeChange}
      />
    </>;
  }
}

export default (props) => <FormAgency {...props} />
