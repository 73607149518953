import React from "react";
import PropTypes from "prop-types";

const CpProvider = ({logo, name, style}) => {

  const finalStyle = {
    height: "25px",
    width: "auto",
    marginRight: "20px",
    ...style,
  };

  return (
    name ?
      (logo ?
        <img style={finalStyle} src={logo} title={name}/> :
        <span style={finalStyle}>{name}</span>) :
      null
  );
};

CpProvider.propTypes = {
  name: PropTypes.string,
  logo: PropTypes.string,
};

export default CpProvider;
