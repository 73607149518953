import React from "react";
import PropTypes from "prop-types";
import CpText from "../common/CpText";
import lang from "../../lang/fr"
/**
 * Affiche le nombre de copro à échoir par ordre de priorité définis par serveur
 * Si rien n'a été retourné, alors aucune copro à échoir n'est affiché.
 * @param deadlines
 * @param total
 * @returns {*}
 */
const CpCoproDeadline = ({deadlines, total}) => {
  let countDeadlines = 0;
  return (
    <>
      {Object.keys(deadlines).map((d, key) => {
        if (deadlines[d] > 0) {
          countDeadlines++;
          return <CpText key={key} type={d <= 3 ?
            "danger" :
            d <= 6 ? "warning" : "success"}
                         style={{
                           verticalAlign: "top",
                           display: "block",
                           position: "relative",
                           lineHeight: "15px",
                           fontWeight: 550,
                           fontSize: "12px",
                         }}>
            {deadlines[d]}/{total.toString()} {lang.global.echoirDans} {
            d ?
            d :
            lang.global.moinsUn} {lang.global.mois}
          </CpText>;
        }
      })}
      {!countDeadlines ? (total ? total : lang.global.aucune) : ""}
    </>
  );
};

CpCoproDeadline.propTypes = {
  deadlines: PropTypes.object.isRequired,
  total: PropTypes.number.isRequired,
};

export default CpCoproDeadline;
