import React from "react";
import {Button, Col, Divider, Form, Row} from "antd";
import CpButton from "./buttons/CpButton";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import CpTitle from "./CpTitle";

class CpEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.updateFieldsValue = this.updateFieldsValue.bind(this);
  }

  updateFieldsValue() {
    const {values = {}, fields, formRef, setRequiredFields} = this.props;

    let requiredFields = [];

    //Create the fields object to pass values to our form
    let initialValues = {};
    _.each(fields, ({key, type, options}) => {

      if (setRequiredFields && options) {
        const {rules = []} = options;
        let requiredEnabled = !!rules.filter(rule => rule.required)[0];

        if (requiredEnabled) {
          requiredFields.push(key);
        }
      }

      if (type == "date" && values[key]) {
        initialValues[key] = moment(values[key]);
      } else if (_.isArray(values[key]) &&
        values[key].filter(v => v["@id"])[0] instanceof Object) {
        initialValues[key] = values[key].map(t => t["@id"]);
      } else if (_.isObject(values[key]) && values[key]["@id"]) {
        initialValues[key] = values[key]["@id"];
      } else if (_.isObject(values[key]) && values[key]["id"]) {
        initialValues[key] = values[key]["id"];
      } else {
        initialValues[key] = values[key];
      }
    });

    if(values.comment){
      initialValues.commentaire = values.commentaire;
    }
    //Set the required fields to formEdit HoC
    if (setRequiredFields) {
      setRequiredFields(requiredFields);
    }

    //Set the default fields values
    formRef.current.setFieldsValue(initialValues);
  }

  componentDidMount() {
    this.updateFieldsValue();
  }

  componentDidUpdate() {
    this.updateFieldsValue();
  }

  render() {
    const {
      title,
      validateText,
      fields,
      icon,
      onCancel,
      handleSubmit,
      formRef,
      onFieldsChange,
      onValuesChange,
      handleClickAdvancedMode,
    } = this.props;

    return (
      <Form ref={formRef} onValuesChange={onValuesChange}
            onFieldsChange={onFieldsChange} layout="vertical">
        {title || icon ? <CpTitle level={3}>{icon} {title}</CpTitle> : null}

        <Row gutter={[20, 0]}>
          {fields.map((field) => {
            if (field.type === "separator") {
              return <Divider key={field.key}>{field.label}</Divider>;
            }

            if (field.type === "advancedMode") {
              return <div key={field.key} style={{width: "100%", display: "flex", justifyContent:"flex-end", marginBottom: 10, paddingRight:10}}><CpButton onClick={handleClickAdvancedMode}>{field.label}</CpButton></div>;
            }

            return (
              field.key ?
                <Col key={field.key} flex={field.flex ? field.flex : "100%"}>
                  <Form.Item name={field.key}
                             label={field.label} {...field.options}>
                    {field.clone ?
                      React.cloneElement(field.input, {formRef: formRef}) :
                      field.input}
                  </Form.Item>
                </Col> :
                null
            );
          })}
        </Row>

        <Row justify="end">
          {onCancel ?
            <Button type="default" htmlType="button" onClick={onCancel}>
              Annuler
            </Button>
            : null}


          {handleSubmit ? <Button
            type="primary"
            htmlType="submit"
            onClick={handleSubmit}
          >
            {validateText ? validateText : "Valider"}
          </Button> : null}
        </Row>

      </Form>
    );
  }
}

CpEditForm.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  validateText: PropTypes.string,
  icon: PropTypes.node,
  fields: PropTypes.array.isRequired,
  values: PropTypes.object,
  handleSubmit: PropTypes.func, //called from HoC formEdit
  onCancel: PropTypes.func,
  onFieldsChange: PropTypes.func, //disabled submit button from HoC formEdit
  setRequiredFields: PropTypes.func, //set requiredFields on formEdit HoC
  handleClickAdvancedMode: PropTypes.func, //set advancedMode
};

export default CpEditForm;

