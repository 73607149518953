import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import AgencyDetail from "../../../containers/agency/AgencyDetail";
import ManagerDetail from "../../../containers/agency/ManagerDetail";

function AgencyAccessRoutes({agencyId}) {
  return (
    <Switch>
      <Route exact path="/manager/:id" render={props => <ManagerDetail
        key={props.match.params.id} {...props}/>}/>
      <Route exact path="/agency" render={() => <AgencyDetail agencyId={agencyId}/>}/>,
      <Route component={() => <Redirect to={"/agency"}/>}/>
    </Switch>
  );
}

export default AgencyAccessRoutes;
