import React from "react";
import { Layout, List } from "antd";

import { inject, observer } from "mobx-react";

import CpListFilter from "../../components/common/list/CpListFilter";
import CpListHeader from "../../components/common/list/CpListHeader";
import { CpIconUser } from "../../components/common/CpIcon";
import CpTitle from "../../components/common/CpTitle";
import { roles } from "../../constants";
import { DataTypeEnum } from "../../components/common/enums/DataTypeEnum";
import CpModalEdit from "../../components/common/modal/CpModalEdit";
import FormUser from "../../components/common/forms/FormUser";
import CpDeleteButton from "../../components/common/buttons/CpDeleteButton";
import DataService from "../../services/dataService";
import { filtrerListe } from "../../helpers/FormatagesDonnees";

@inject("dictionary", "list")
@observer
class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { filtres: {}, editedItem: null };
        //instance type for detail
        this.props.list.setType(DataTypeEnum.USER);
        this.onFilterChange = this.onFilterChange.bind(this);
    }

    componentDidMount() {
        //Preload List items
        this.props.list.load();
    }

    modalClose = () => {
        this.props.list.load();
    };

    onDeleteUser = () => {
        this.props.list.load();
    };

    onEditModalValid(user) {
        const { managerId } = this.props;
        return DataService.edit(DataTypeEnum.USER, user.id, user).then(() => {
            this.setState({ activePanel: null });
            this.props.list.load(managerId);
        });
    }

    onEditModalOpen(id, callback) {
        DataService.getById(DataTypeEnum.USER, id).then((response) => {
            const editedItem = response.data;
            this.setState({ editedItem });
            callback();
        });
    }

    onFilterChange(nouveauFiltre) {
        const nouveauxFiltres = { ...this.state.filtres, ...nouveauFiltre };
        this.setState({ filtres: nouveauxFiltres });
    }

    render() {
        const items = this.props.list.items || [];
        const { editedItem } = this.state;
        const displayedItems = filtrerListe(this.state.filtres, items);

        return (
            <Layout>
                <CpTitle level={1}>Utilisateurs</CpTitle>
                <List
                    header={
                        <CpListHeader
                            icon={<CpIconUser />}
                            title="Utilisateur"
                            count={displayedItems.length}
                            filter={
                                <CpListFilter
                                    fields={["firstname", "lastname", "email"]}
                                    onFilterChange={this.onFilterChange}
                                />
                            }
                        />
                    }
                    bordered={false}
                    dataSource={displayedItems}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                title={<>Nom</>}
                                description={<>{item.lastname}</>}
                            />
                            <List.Item.Meta
                                title={<>Prénom</>}
                                description={<>{item.firstname}</>}
                            />
                            <List.Item.Meta
                                title={<>Email</>}
                                description={<>{item.email}</>}
                            />
                            <List.Item.Meta
                                title={<>Roles</>}
                                description={
                                    <>
                                        {item.roles.map((role, key) => (
                                            <div key={key}>
                                                {roles[role] ? roles[role].label : role}
                                            </div>
                                        ))}
                                    </>
                                }
                            />
                            <List.Item.Meta
                                description={
                                    <>
                                        {item.roles.indexOf("ROLE_AGENCY") === -1 ? (
                                            <CpModalEdit
                                                buttonProps={{
                                                    block: true,
                                                    style: { marginBottom: 5, width: "46%" },
                                                }}
                                                form={FormUser}
                                                onModalOpen={(callback) =>
                                                    this.onEditModalOpen(item.id, callback)
                                                }
                                                values={{ ...editedItem }}
                                                onModalClose={this.modalClose}
                                                mobxFunc={(object) => this.onEditModalValid(object)}
                                            />
                                        ) : null}
                                        <CpDeleteButton
                                            text={`Êtes-vous sûr(e) de vouloir supprimer cet utilisateur ?`}
                                            buttonProps={{
                                                style: {
                                                    marginLeft: "2%",
                                                    marginBottom: 5,
                                                    width: "52%",
                                                },
                                            }}
                                            entityIri={`/api/users/${item.id}`}
                                            title={"Supprimer"}
                                            onDelete={() => this.onDeleteUser()}
                                        />
                                    </>
                                }
                                style={{ flex: "0 0 125px" }}
                            />
                        </List.Item>
                    )}
                />
            </Layout>
        );
    }
}

export default UserList;
