import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Layout, List, Select, Switch } from "antd";
import { ApiOutlined, RiseOutlined } from "@ant-design/icons";
import lang from "../../lang/fr";
import moment from "moment";

import { inject, observer } from "mobx-react";

import CpCompteurTendanceMarche from "../../components/compteur/CpCompteurTendanceMarche";
import CpListFilter from "../../components/common/list/CpListFilter";
import CpListHeader from "../../components/common/list/CpListHeader";
import { CpIconCompteur } from "../../components/common/CpIcon";
import CpTitle from "../../components/common/CpTitle";
import {
    SwitchAgencyCompteurData,
} from "../../components/common/enums/DataTypeEnum";
import variables from "../../stylesheets/tools/variables";

import CpCompteurTypeFilter from "../../components/common/filters/CpCompteurTypeFilter";
import CpFiltreEcheance from "../../components/common/filters/CpFiltreEcheance";
import CpDateRangeFilter from "../../components/common/filters/CpDateRangeFilter";
import CpTypeCompteurFormat from "../../components/common/CpTypeCompteurFormat";
import CpIconTarif from "../../components/common/CpIconTarif";
import CpProvider from "../../components/common/CpProvider";
import CpCompteurDeadline from "../../components/compteur/CpCompteurDeadline";
import SwitchPE from "../../components/common/SwitchPE";
import BreadCrumbPE from "../../components/common/BreadCrumbPE";
import DataService from "../../services/dataService";
import { filtrerListe } from "../../helpers/FormatagesDonnees";
import {recupererListeCompteurs} from "../../helpers/compteurs/listeCompteurs";

@inject("dictionary")
@observer
class CompteursListe extends React.Component {
    state = {
        fournisseurs: [],
        editedItem: null,
        tendance: false,
        transac: false,
        compteurs: [],
        compteursFiltres: [],
        filtres: {},
    };

    componentDidMount() {
        DataService.getCheckSession().then(({ data }) => {
            if (data) {
                this.recupererListe();
            } else {
                if (window.location.pathname !== "/login") {
                    this.props.history.push("/login");
                }
            }
        });
    }

    onTendanceMarcheCheck = () => {
        const avecTendance = !this.state.tendance;
        this.setState({ tendance: avecTendance }, () => {
            this.onGestionFiltre({ tendance: avecTendance });
        });
    };

    onTransactionCheck = () => {
        const avecTransaction = !this.state.transac;
        this.setState({ transac: avecTransaction }, () => {
            this.onGestionFiltre({ transac: avecTransaction });
        });
    };

    onGestionFiltre = (nouveauFiltre) => {
        const nouveauxFiltres = { ...this.state.filtres, ...nouveauFiltre };
        return this.setState({ compteursFiltres: filtrerListe(nouveauxFiltres, this.state.compteurs), filtres: nouveauxFiltres });
    };

    recupererListe = async () => {
            const compteurs = await recupererListeCompteurs('getAll')
            this.setState({
                compteurs: compteurs,
                compteursFiltres: compteurs,
            });
    };

    render() {
        const { enums, providers } = this.props.dictionary;
        const { compteursFiltres, tendance, transac } = this.state;
        const listeNavigation = [{ titre: "Compteurs" }];

        return (
            <Layout>
                <BreadCrumbPE listeNavigation={listeNavigation} />
                <SwitchPE
                    marginTop
                    handleClick={this.handleClick}
                    selected={"compteur"}
                    switchObject={SwitchAgencyCompteurData}
                />
                <CpTitle level={1}>{lang.compteur.mesCompteurs}</CpTitle>
                <List
                    rowKey={(item, index) => index}
                    header={
                        <CpListHeader
                            visible={true}
                            icon={<CpIconCompteur />}
                            title={lang.compteur.compteur}
                            count={compteursFiltres.length}
                            filter={
                                <div>
                                    <CpCompteurTypeFilter
                                        compteurs={this.state.compteurs}
                                        onFilterChange={this.onGestionFiltre}
                                        field={"type"}
                                    />
                                    <div style={{ alignItems: "center", display: "flex" }}>
                                        <CpFiltreEcheance
                                            compteurs={this.state.compteurs}
                                            onFilterChange={this.onGestionFiltre}
                                            field={"date_echeance"}
                                        />
                                        <CpDateRangeFilter
                                            onFilterChange={this.onGestionFiltre}
                                            field={"date_echeance"}
                                            compteurs={this.state.compteurs}
                                            extraFooter={lang.compteur.filtre.filtrerParEcheance}
                                        />
                                        <CpListFilter
                                            onFilterChange={this.onGestionFiltre}
                                            compteurs={this.state.compteurs}
                                            fields={[
                                                "pdl",
                                                "segment",
                                                "filtre_nom_fournisseur",
                                                "filtre_adresse_copro",
                                            ]}
                                        />
                                        <div>
                                            <Switch
                                                className="switch-filter"
                                                unCheckedChildren={lang.compteur.filtre.tendance}
                                                checkedChildren={
                                                    <>
                            <span className="span-switch-filter">
                              <RiseOutlined style={{ color: "black" }} />
                            </span>{" "}
                                                        {lang.compteur.filtre.tendance}
                                                    </>
                                                }
                                                checked={tendance}
                                                onChange={this.onTendanceMarcheCheck}
                                            />
                                            <Switch
                                                className="switch-filter"
                                                unCheckedChildren={
                                                    lang.compteur.filtre.toutesTransactions
                                                }
                                                checkedChildren={
                                                    <>
                            <span className="span-switch-filter">
                              <ApiOutlined style={{ color: "black" }} />
                            </span>{" "}
                                                        {lang.compteur.filtre.sansTransactions}
                                                    </>
                                                }
                                                checked={transac}
                                                onChange={this.onTransactionCheck}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "self-end" }}>
                                        <Select
                                            style={{ width: "200px", marginRight: "20px" }}
                                            placeholder="Fournisseur"
                                            name="fournisseur"
                                            allowClear={true}
                                            optionFilterProp="children"
                                            showSearch
                                            onChange={(e) =>
                                                this.onGestionFiltre({ fournisseur: e })
                                            }
                                        >
                                            {providers.map((fournisseur, index) => (
                                                <Select.Option key={index} value={fournisseur.id}>
                                                    {fournisseur.nom}
                                                    <img
                                                        src={fournisseur.logo}
                                                        alt="logo"
                                                        height="20px"
                                                    />
                                                </Select.Option>
                                            ))}
                                            <Select.Option value="inconnu">Inconnu</Select.Option>
                                        </Select>
                                    </div>
                                </div>
                            }
                        />
                    }
                    bordered={false}
                    dataSource={compteursFiltres}
                    renderItem={(item) => (
                        <Link
                            to={`/manager/${item.copropriete.gestionnaire}/compteurs?filter=${item.pdl}`}
                        >
                            <List.Item>
                                <List.Item.Meta
                                    title={
                                        <CpTitle level={4}>
                                            <CpTypeCompteurFormat
                                                type={item.type}
                                                style={{ fontSize: 22 }}
                                            />
                                            {item.pdl}
                                        </CpTitle>
                                    }
                                    description={
                                        <>
                      <span style={{ color: variables.waterGreenPrimary }}>
                        {item.copropriete?.nom}
                      </span>{" "}
                                            {item.copropriete?.adresse}
                                        </>
                                    }
                                    style={{ flex: "0 0 300px", marginRight: 10 }}
                                />
                                <List.Item.Meta
                                    title={
                                        item.type === "ELEC"
                                            ? lang.compteur.label.segment
                                            : lang.compteur.label.tarif
                                    }
                                    description={
                                        <>
                                            <CpIconTarif type={item.segment} />
                                            {item.segment}
                                        </>
                                    }
                                    style={{ flex: "0 0 70px" }}
                                />
                                <List.Item.Meta
                                    title={lang.compteur.label.consommation}
                                    description={
                                        item.consommation ? (
                                            <>
                                                {item.consommation.totale}{" "}
                                                {enums.powerTypeEnum
                                                    ? enums.powerTypeEnum[item.type]
                                                    : ""}
                                            </>
                                        ) : (
                                            <> - </>
                                        )
                                    }
                                    style={{ flex: "0 0 50px" }}
                                />
                                <List.Item.Meta
                                    description={
                                        <CpProvider
                                            name={item.fournisseur?.nom}
                                            logo={item.fournisseur?.logo}
                                            style={{ height: 40 }}
                                        />
                                    }
                                    style={{ textAlign: "center" }}
                                />
                                <List.Item.Meta
                                    title={lang.compteur.label.dateEcheance}
                                    description={
                                        <CpCompteurDeadline
                                            date_end={item.date_echeance}
                                            deadline={item.deadline}
                                        />
                                    }
                                />
                                {item.tendance_marche ? (
                                    <List.Item.Meta
                                        title={lang.compteur.label.tendanceMarche}
                                        align="center"
                                        description={
                                            <CpCompteurTendanceMarche prix={item.tendance_marche} />
                                        }
                                    />
                                ) : (
                                    <List.Item.Meta title="" />
                                )}
                                {!item.transaction_id ? (
                                    <List.Item.Meta
                                        title={lang.compteur.label.sansTransaction}
                                        align="center"
                                    />
                                ) : (
                                    <List.Item.Meta
                                        align="center"
                                        title={
                                            <>
                                                {lang.compteur.label.transactionEnCours}{" "}
                                                {moment(item.date_creation_transaction).format(
                                                    "DD/MM/YYYY"
                                                )}
                                            </>
                                        }
                                    />
                                )}
                            </List.Item>
                        </Link>
                    )}
                />
            </Layout>
        );
    }
}

export default withRouter(CompteursListe);
