import React from "react";
import {Link} from "react-router-dom";
import {Menu} from "antd";

const SwitchPE = ({handleClick, selected, switchObject, marginTop, marginBottom}) => {
  const elementMenu = (item) => (
    <Menu.Item key={item.key}>
      <Link to={item.path}>
        {item.pathName}
      </Link>
    </Menu.Item>
  );
  return (
      <Menu
        className={`styled-switch ${marginTop ? 'margin-top' :''} ${marginBottom ? 'margin-bottom' : ""}`}
        onClick={handleClick}
        selectedKeys={selected}
        mode="horizontal"
      >
          {switchObject ? switchObject.map(elementMenu) : ''}
      </Menu>
  );
};

export default SwitchPE;
