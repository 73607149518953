import React from "react";
import PropTypes from "prop-types";
import {Col, Radio, Row, Typography} from "antd";

const QuestionFormulaire = (props) => {
  const {question, buttonPrecedent} = props;
  const {Title} = Typography;
  const onSelection = (e) => {
    props.actionChoix(e.target.value);
  };


  return (
    <div style={{marginTop: 16}}>
      <Row justify={"center"}>
        <Typography>
          <Title level={4}>{question}</Title>
        </Typography>
      </Row>

      <Row justify={"center"}>
        <Col>
          <Radio.Group
            size="large"
            onChange={(e) => onSelection(e)}
          >
            <Radio.Button value={false}>
              Non
            </Radio.Button>
            <Radio.Button value={true}>
              Oui
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      {buttonPrecedent}
    </div>
  );
};
QuestionFormulaire.propTypes = {
  question: PropTypes.string,
  actionChoix: PropTypes.func,
  valid: PropTypes.bool,
};

export default (props) => <QuestionFormulaire {...props}/>
