import {action, observable} from "mobx";
import _ from "lodash";
import DataService from "../services/dataService";
import User from "./user";

class Dictionary {

  @observable segments = [];
  @observable segmentsByIRI = {};
  @observable providers = [];
  @observable organismeRegroupements = [];
  @observable providersByIRI = {};
  @observable enums = {};
  @observable isLoading = false;
  @observable agencyId = null;
  @observable commercials = null;
  @observable promise;

  $req = async () => {
    const {data} = await DataService.getDictionnaire()
    return data;
  };

  /**
   * Initial load
   * @returns {*}
   */
  @action load() {
    this.isLoading = true;
    this.promise = this.$req().then((dictionaries) => {
      if (dictionaries.user) {
        User.updateUser(dictionaries.user, dictionaries.agencyId);
        this.segments = _.sortBy(dictionaries.segments, "label");
        this.providers = _.sortBy(dictionaries.fournisseurs, "nom");
        this.organismeRegroupements = dictionaries.organismeRegroupements.map(
          (organismeRegroupement) => {
            return {...organismeRegroupement, logo:`/images/organismeRegroupements/${organismeRegroupement.id.toLowerCase()}.png`};
          });
        this.organismeRegroupements.unshift("");
        this.segmentsByIRI = _.keyBy(dictionaries.segments, "id");
        this.providersByIRI = _.keyBy(dictionaries.fournisseurs, "id");
        this.enums = dictionaries.enums;
        this.reasons = dictionaries.raisons;
        this.civility = dictionaries.civilité;
        this.civility.unshift("");
        this.commercials = dictionaries.courtiers;
      }
    }).catch( () => {}
    ).finally(action(() => {
      this.isLoading = false;
    }));

    return this.promise;
  }
}

export default new Dictionary();
