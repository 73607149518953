import React from "react";
import {inject, observer} from "mobx-react";
import PropTypes from "prop-types";
import {Popconfirm, Tooltip} from "antd";
import DataService from "../../../services/dataService";

@inject("interface", "dictionary", "user")
@observer
class CpHubspotButton extends React.Component {

  constructor(props) {
    super(props);

    //Initial state
    this.state = {
      exists: false,
      loading: false,
      show: false,
    };
  }

  componentDidMount() {
    this.setState({exists: !!this.props.item.hubspotId});
    this.setState({
      show: (this.props.user.user.roles.indexOf("ROLE_COMMERCIAL") !== -1),
    });
  }

  handleCancelClick = (e) => {
    e.preventDefault();
  };
  handleHubspotClick = (e) => {
    e.preventDefault();
    DataService.getContactHubspot(this.props.item.id).then((res) => {
      window.open(res.data, '_blank').focus()
    })
  };
  handleHubspotCreateClick = (e) => {
    e.preventDefault();
    if (this.props.item.hubspotId) {
      DataService.getContactHubspot(this.props.item.id).then((res) => {
        window.open(res.data, '_blank').focus()
      })
    } else {
      DataService.getContactHubspot(this.props.item.id).then(({data}) => {
        this.setState({exists: true});
        window.open(data);
      });
    }
  };

  render() {
    const {exists, show} = this.state;
    const {title} = this.props;

    if (show && !exists) {
      return <Popconfirm
        title={"Êtes-vous sûr(e) de vouloir créer le contact dans Hubspot ?"}
        placement="left"
        onConfirm={this.handleHubspotCreateClick}
        onCancel={this.handleCancelClick}
        okText="Oui"
        cancelText="Annuler"
        style={{zIndex: 555, display: "block"}}
      ><Tooltip placement="bottom"
                title={(exists ? "Voir " : "Créer ") + title}>
        <svg width="28px" height="28px" viewBox="0 0 70 70" style={{
          cursor: "pointer",
        }}>
          <g id="icon/hubspot" stroke="none" strokeWidth="1"
             fill="none" fillRule="evenodd">
            <path
              d="M56.7164087,27.9817391 C54.7816873,26.433962 52.2665495,25.4666013 49.7514117,25.079657 L49.7514117,18.11466 C51.6861331,17.3407715 52.846966,15.4060501 52.846966,13.4713287 L52.846966,13.2778566 C52.846966,10.3757745 50.5253003,8.05410882 47.6232182,8.05410882 L47.4297461,8.05410882 C44.527664,8.05410882 42.2059983,10.3757745 42.2059983,13.2778566 L42.2059983,13.4713287 C42.2059983,15.5995222 43.3668312,17.3407715 45.3015526,18.11466 L45.3015526,24.8861849 C42.7864148,25.2731291 40.271277,26.433962 38.1430834,27.9817391 L19.376286,13.4713287 C19.5697581,13.0843844 19.5697581,12.503968 19.5697581,11.9235516 C19.5697581,8.82799737 17.0546203,6.11938743 13.765594,6.11938743 C10.4765676,6.11938743 7.76795769,8.82799737 7.76795769,12.1170237 C7.76795769,15.4060501 10.4765676,18.11466 13.765594,18.11466 C13.765594,18.11466 13.765594,18.11466 13.765594,18.11466 C14.7329547,18.11466 15.7003154,17.9211879 16.6676761,17.3407715 L35.0475292,31.6577097 C31.5650307,36.6879853 31.7585029,43.4595102 35.2410014,48.4897858 L29.6303093,54.1004778 C29.2433651,53.9070056 28.6629486,53.9070056 28.2760044,53.9070056 C25.5673944,53.9070056 23.4392009,56.0351992 23.4392009,58.7438091 C23.4392009,61.452419 25.5673944,63.5806126 28.2760044,63.5806126 C30.9846143,63.5806126 33.1128078,61.452419 33.1128078,58.7438091 C33.1128078,58.3568648 33.1128078,57.7764484 32.9193357,57.3895041 L38.5300277,51.7788121 C45.1080804,56.8090877 54.3947431,55.6482549 59.4250187,49.0702022 C64.4552943,42.2986773 63.2944614,33.0120147 56.7164087,27.9817391 Z M47.4297461,47.5224251 L47.4297461,47.5224251 C43.173359,47.7158972 39.6908605,44.2333987 39.4973884,39.9770117 C39.3039163,35.7206246 42.7864148,32.2381261 47.0428018,32.044654 C47.2362739,32.044654 47.2362739,32.044654 47.4297461,32.044654 C51.6861331,32.2381261 54.9751595,35.7206246 54.7816873,39.9770117 C54.7816873,44.0399266 51.492661,47.3289529 47.4297461,47.5224251 Z"
              id="Shape" fill={exists ? "#ff7a59" : "#969898"}></path>
          </g>
        </svg>
      </Tooltip>
      </Popconfirm>;
    } else if (exists) {
      return <svg width="28px" height="28px" viewBox="0 0 70 70"
                  onClick={(e) => {
                    this.handleHubspotClick(e);
                  }} style={{
        cursor: "pointer",
      }}>
        <g id="icon/hubspot" stroke="none" strokeWidth="1"
           fill="none" fillRule="evenodd">
          <path
            d="M56.7164087,27.9817391 C54.7816873,26.433962 52.2665495,25.4666013 49.7514117,25.079657 L49.7514117,18.11466 C51.6861331,17.3407715 52.846966,15.4060501 52.846966,13.4713287 L52.846966,13.2778566 C52.846966,10.3757745 50.5253003,8.05410882 47.6232182,8.05410882 L47.4297461,8.05410882 C44.527664,8.05410882 42.2059983,10.3757745 42.2059983,13.2778566 L42.2059983,13.4713287 C42.2059983,15.5995222 43.3668312,17.3407715 45.3015526,18.11466 L45.3015526,24.8861849 C42.7864148,25.2731291 40.271277,26.433962 38.1430834,27.9817391 L19.376286,13.4713287 C19.5697581,13.0843844 19.5697581,12.503968 19.5697581,11.9235516 C19.5697581,8.82799737 17.0546203,6.11938743 13.765594,6.11938743 C10.4765676,6.11938743 7.76795769,8.82799737 7.76795769,12.1170237 C7.76795769,15.4060501 10.4765676,18.11466 13.765594,18.11466 C13.765594,18.11466 13.765594,18.11466 13.765594,18.11466 C14.7329547,18.11466 15.7003154,17.9211879 16.6676761,17.3407715 L35.0475292,31.6577097 C31.5650307,36.6879853 31.7585029,43.4595102 35.2410014,48.4897858 L29.6303093,54.1004778 C29.2433651,53.9070056 28.6629486,53.9070056 28.2760044,53.9070056 C25.5673944,53.9070056 23.4392009,56.0351992 23.4392009,58.7438091 C23.4392009,61.452419 25.5673944,63.5806126 28.2760044,63.5806126 C30.9846143,63.5806126 33.1128078,61.452419 33.1128078,58.7438091 C33.1128078,58.3568648 33.1128078,57.7764484 32.9193357,57.3895041 L38.5300277,51.7788121 C45.1080804,56.8090877 54.3947431,55.6482549 59.4250187,49.0702022 C64.4552943,42.2986773 63.2944614,33.0120147 56.7164087,27.9817391 Z M47.4297461,47.5224251 L47.4297461,47.5224251 C43.173359,47.7158972 39.6908605,44.2333987 39.4973884,39.9770117 C39.3039163,35.7206246 42.7864148,32.2381261 47.0428018,32.044654 C47.2362739,32.044654 47.2362739,32.044654 47.4297461,32.044654 C51.6861331,32.2381261 54.9751595,35.7206246 54.7816873,39.9770117 C54.7816873,44.0399266 51.492661,47.3289529 47.4297461,47.5224251 Z"
            id="Shape" fill={"#ff7a59"}></path>
        </g>
      </svg>;
    } else {
      return null;
    }
  }
}

CpHubspotButton.propTypes = {
  item: PropTypes.object,
  type: PropTypes.string.isRequired,
};

export default CpHubspotButton;
