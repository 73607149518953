import React from "react";
import {withRouter} from "react-router-dom";
import {Popconfirm} from "antd";
import PropTypes from "prop-types";

import CpButton from "./CpButton";
import {CpIconDelete} from "../CpIcon";
import DataService from "../../../services/dataService";

class CpDeleteButton extends React.Component {

  constructor(props) {
    super(props);
    this.state = {loading: false};

    this.onConfirm = this.onConfirm.bind(this);
  }

  onConfirm() {
    if (this.props.onBeforeDelete()) {
      //Read our props
      const {
              entityIri, afterDeleteUrl, onDelete = () => {
        },
            } = this.props;
      //Set loading state
      this.setState({loading: true});
      const cancelLoading = () => this.setState({loading: false});

      //Call our delete
      DataService.deleteButton(entityIri).then(() => {
        if (afterDeleteUrl) {
          //If we need to redirect, do it
          this.props.history.push(afterDeleteUrl);
        }
        //Execute callback
        cancelLoading();
        onDelete();
      }).catch(cancelLoading);
    }
  }

  render() {
    const {
            style = {float: "right"},
            buttonProps = {},
            text,
            title,
          } = this.props;
    const {loading} = this.state;

    return <Popconfirm
      title={text ?
        text :
        "Êtes-vous sûr(e) de vouloir supprimer cet élément ?"}
      placement="left"
      onConfirm={this.onConfirm}
      okText="Supprimer"
      okType="danger"
      cancelText="Annuler"
      style={style}
    >
      <CpButton
        loading={loading}
        icon={buttonProps.size == "small" ?
          <CpIconDelete size={15}/> :
          null}
        {...buttonProps}
      >
        {title}
      </CpButton>
    </Popconfirm>;
  }
}

CpDeleteButton.propTypes = {
  // entityIri: PropTypes.string.isRequired,
  afterDeleteUrl: PropTypes.string,
  onBeforeDelete: PropTypes.func,
};

CpDeleteButton.defaultProps = {
  onBeforeDelete: () => true,
};

export default withRouter(CpDeleteButton);
