import React, {useEffect, useState} from "react";
import {Switch} from "antd";
import {CpIconElec, CpIconGaz} from "../CpIcon";

const types = {
    ELEC: { icon: <CpIconElec /> },
    GAZ: { icon: <CpIconGaz /> },
};

function CpCompteurTypeFilter({ onFilterChange }) {
    const [state, setState] = useState({ ELEC: true, GAZ: true });
    const { ELEC, GAZ } = state;

    const handleChange = (type) => (value) => {
        setState((prevState) => {
            return {...prevState, [type]: value};
        });
    };

    // Effet pour appeler onFilterChange lorsque ELEC ou GAZ change
    useEffect(() => {
        const typesSelectionnes = [];
        if (ELEC) typesSelectionnes.push("ELEC");
        if (GAZ) typesSelectionnes.push("GAZ");
        onFilterChange({ type: typesSelectionnes });
    }, [ELEC, GAZ, onFilterChange]);

    return (
        <>
            {Object.keys(types).map((type) => (
                <Switch
                    key={type}
                    style={{
                        minWidth: 65,
                        width: 65,
                        margin: 5,
                        marginRight: 15,
                        marginLeft: 0,
                    }}
                    unCheckedChildren={type}
                    checkedChildren={
                        <>
              <span
                  style={{
                      position: "absolute",
                      right: "4px",
                      top: "0",
                      fontSize: "116%",
                  }}
              >
                {types[type].icon}
              </span>
                            {type}
                        </>
                    }
                    checked={state[type]}
                    onChange={handleChange(type)}
                />
            ))}
        </>
    );
}

export default CpCompteurTypeFilter;
