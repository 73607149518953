import {action, computed, observable} from "mobx";

class User {
  @observable user = null;

  @computed get isConnected() {
    return !!this.user;
  }

  @computed get isAdmin() {
    return !(!this.user || !this.user.roles.includes("ROLE_ADMIN"));
  }

  @computed get isAgency() {
    return !(!this.user || !this.user.roles.includes("ROLE_AGENCY") ||
      !this.agencyId);
  }

  /**
   * Update user data
   * @param {object} userData
   */
  @action updateUser(userData) {
    if (userData === null) {
      this.user = null;
      this.agencyId = null;
    } else {
      this.user = userData;
      this.agencyId = userData.agencyIdAccess;
    }
  }
}

export default new User();
