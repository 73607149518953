import {Redirect, Route, Switch} from "react-router-dom";
import Login from "../../../containers/Login";
import React from "react";

function DefaultRoutes() {
  return (
    <Switch>
      <Route exact path="/login" component={Login}/>
      <Route exact path="/" render={() => <Redirect to='/login'/>}/>
    </Switch>
  );
}

export default DefaultRoutes;
