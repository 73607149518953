import React from "react";
import {notification} from "antd";
import Interface from "../stores/interface";

const axios = require("axios");

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_ROOT,
  mode: "no-cors",
  withCredentials: true,
  headers: {
    accept: "application/ld+json",
  },
});

notification.config({top: 60})

/**
 * Intercept axios errors to notify everything
 */
instance.interceptors.response.use(response => response, function(error) {
  //log the error
  //console.error("AXIOS RESPONSE ERROR", error.response);
  //console.log("error", error);

  //Retrieve status
  const status = error.response && error.response.status ?
    error.response.status :
    "???";
  const data = error.response ? error.response.data : {};
  const detail = data.detail ? data.detail : data["hydra:description"];
  let message;

  //Authorization errors
  if ((status === 401 && detail !== "Nom d'utilisateur ou mot de passe incorrect.") || (status === 500 && detail == "Must be authentified.")) {
    message =
      <span>Vous devez vous identifier pour accéder à cette ressource.</span>;
    // window.location.href = "/login";
    Interface.needAuthenticationRedirect = true;
    window.location.href = "/login";
    //Handling Symfony validator/assertion errors
  } else if (status == 400 && data["violations"]) {
    message = data["violations"];
  } else if (status >= 400 && detail) {
      //Other errors
      message = detail;
  }

  //If we have a message, notify
  if (Array.isArray(message)) {
    message.map(function(m, i) {
      notification.error({
        className: "error-server",
        message: m.message,
        duration: i + 3,
      });
    });
  } else if (message) {
    notification.error({
      className: "error-server",
      message,
      duration: 3,
    });
  }
  return Promise.reject(error.response ? error.response : {});
});

//Loading indicator
instance.interceptors.request.use(
  (request) => {
    Interface.loadingRequests++;
    return request;
  },
  (error) => {
    Interface.loadingRequests--;
    return Promise.reject(error);
  },
);
instance.interceptors.response.use(
  (response) => {
    Interface.loadingRequests--;
    return response;
  },
  (error) => {
    Interface.loadingRequests--;
    return Promise.reject(error);
  },
);

export default instance;
