import React from "react";
import {inject, observer} from "mobx-react";
import {Col, Icon, message, Modal, Row, Statistic, Tree, Upload} from "antd";
import CpButton from "./CpButton";
import {
  CpIconCheck,
  CpIconCompteur,
  CpIconContrats,
  CpIconCopro,
  CpIconUser,
} from "../CpIcon";

import PropTypes from "prop-types";

const {Dragger} = Upload;
const {TreeNode} = Tree;

@inject("interface")
@observer
class CpUploadButton extends React.Component {

  constructor(props) {
    super(props);

    //Initial state
    this.state = {
      visible: false,
      loading: false,
      reload: false,
      response: [],
      fileList: [],
    };

    //Bindings
    this.modalOpen = this.modalOpen.bind(this);
    this.modalClose = this.modalClose.bind(this);
  }

  downloadTemplate = () => {
    const {templateUrl} = this.props;
    const url = `${process.env.REACT_APP_SERVER_ROOT}/api/${templateUrl}`;
    window.location = url;
  };

  modalOpen(e) {
    //Remove focus around buttons on click
    e.currentTarget.blur();
    this.setState({visible: true});
    this.setState({reload: false});
    this.setState({response: []});
    this.setState({fileList: []});
  }

  modalClose() {
    const {
            onEndUpload = () => {
            },
          } = this.props;
    this.setState({visible: false});
    if (this.state.reload) {
      onEndUpload();
    }
  }

  beforeUpload = file => {
    this.setState({response: []});
    const isExcel = file.type === "application/vnd.ms-excel" || file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    if (!isExcel) {
      //Vérifiez que les en-têtes de vos colonnes correspondent au schéma de l'ensemble de données.
      message.error("You can only upload Excel file!");
    }
    const isLt = file.size / 1024 / 1024 < 100;
    if (!isLt) {
      //Réduisez la quantité de données que vous importez.
      //Examinez les limites d'importation de données
      message.error("Image must smaller than 100MB!");
    }

    return isExcel && isLt;
  };

  onChange = info => {
    const {status, response} = info.file;

    if (status === "uploading") {
      this.setState({loading: true});
    } else {
      this.setState({loading: false});
      this.setState({reload: true});
    }
    if (status === "done") {
      if (!response.file_is_valid) {
        message.error(response.file_error);
      } else {
        message.success(`${info.file.name} fichier a été chargé avec succès.`);
      }
      this.setState({response: response});
    } else if (status === "error") {
      message.error(
        `Une erreur s'est produite, fichier n'a pas pu être importé.`);
    }
  };

  render() {
    const {values = {}, buttonProps = {}, endPoint} = this.props;
    const buttonTitle = this.props.btnTitle ?? "Importer";
    const modalTitle = "Import";

    const id = values.id;

    const props = {
      name: "file",
      accept: ".xlsx",
      headers: {
        "X-Requested-With": null, //Avoids Unauthorized header x-requested-with
      },
      withCredentials: true, //ajax upload with cookie sent
      method: "post",
      showUploadList: {
        showPreviewIcon: false,
        showDownloadIcon: false,
        showRemoveIcon: false,
      },
      action: process.env.REACT_APP_SERVER_ROOT + endPoint + id,
      beforeUpload: this.beforeUpload,
      onChange: this.onChange,
      disabled: this.state.loading,

    };

    return <span>

            <CpButton
              onClick={this.modalOpen} {...buttonProps}>{buttonTitle}</CpButton>

            <Modal
              // title={modalTitle}
              title={[
                <div key="label">{modalTitle}</div>,
                <CpButton key="download" onClick={this.downloadTemplate}
                          style={{float: "right", top: -25}}>
                  Télécharger le template
                </CpButton>,
              ]}
              width={800}
              visible={this.state.visible}
              closable={false}
              onCancel={this.modalClose}
              maskClosable={false} //Whether to close the modal dialog when the mask (area outside the modal) is clicked
              keyboard={false} //Whether support press esc to close

              footer={[
                <CpButton key="back" onClick={this.modalClose}
                          loading={this.state.loading} style={{
                  display: this.state.response.length !== 0 ?
                    "none" :
                    "unset",
                }}>
                  Annuler
                </CpButton>,
                <CpButton style={{
                  display: this.state.response.length === 0 ?
                    "none" :
                    "unset",
                }} type="principal" key="ok" onClick={this.modalClose}>
                  Fermer <CpIconCheck/>
                </CpButton>,
              ]}
              destroyOnClose={true} //we do this to force a brand new form when re-opening the modal
            >
             <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox"/>
                </p>
                <p className="ant-upload-text">Cliquez ou glissez ici vos fichier pour les ajouter.</p>
                <p className="ant-upload-hint">
                 L’import permet de mettre à jour ou créer le profil d’un gestionnaire, une copropriété, un compteur ou un contrat dans Pilote Energie.
                </p>
              </Dragger>
              {this.state.reload === true && !this.state.loading &&
              this.state.response.file_is_valid ? (
                <>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Statistic
                        title="Lignes traitées"
                        value={this.state.response.processed_rows}
                        suffix={"/" + this.state.response.total_rows}
                      />
                    </Col>
                    <Col span={12}>
                      <Statistic
                        title="Erreurs"
                        value={this.state.response.total_errors}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={6}>
                      <Statistic
                        title="Gestionnaires créés"
                        prefix={<CpIconUser/>}
                        value={this.state.response.created_manager}
                      />
                    </Col>
                    <Col span={6}>
                      <Statistic
                        title="Gestionnaires maj."
                        prefix={<CpIconUser/>}
                        value={this.state.response.updated_manager}
                      />
                    </Col>
                    <Col span={6}>
                      <Statistic
                        title="Copropriétés créées"
                        prefix={<CpIconCopro/>}
                        value={this.state.response.created_copro}
                      />
                    </Col>
                    <Col span={6}>
                      <Statistic
                        title="Copropriétés maj."
                        prefix={<CpIconCopro/>}
                        value={this.state.response.updated_copro}
                      />
                    </Col>
                    <Col span={6}>
                      <Statistic
                        title="Compteurs créés"
                        prefix={<CpIconCompteur/>}
                        value={this.state.response.created_compteur}
                      />
                    </Col>
                    <Col span={6}>
                      <Statistic
                        title="Compteurs maj."
                        prefix={<CpIconCompteur/>}
                        value={this.state.response.updated_compteur}
                      />
                    </Col>
                    <Col span={6}>
                      <Statistic
                        title="Contrats créés"
                        prefix={<CpIconContrats/>}
                        value={this.state.response.created_contract}
                      />
                    </Col>
                    <Col span={6}>
                      <Statistic
                        title="Contrats maj."
                        prefix={<CpIconContrats/>}
                        value={this.state.response.updated_contract}
                      />
                    </Col>
                  </Row>
                  {this.state.response.success === false ? (
                    <Tree
                      showLine={true}
                      showIcon={true}
                    >
                      <TreeNode
                        title="Liste de problèmes d'importation de données"
                        key="0" icon={<Icon type="file-excel" theme="twoTone"
                                            twoToneColor="#eb2f96"/>}>
                        {Object.keys(this.state.response.data_errors).
                          map((type) => (
                            <TreeNode
                              title={this.state.response.data_errors[type].desc}
                              key={"0-" + type}
                              icon={<Icon type="info-circle" theme="twoTone"
                                          twoToneColor="#eb2f96"/>}>
                              {Object.keys(
                                this.state.response.data_errors[type].errors).
                                map((line, indexLine) => (
                                  <TreeNode title={"Ligne N° " + line}
                                            key={"0-" + line}
                                            icon={<Icon type="info-circle"
                                                        theme="twoTone"
                                                        twoToneColor="#eb2f96"/>}>
                                    <TreeNode
                                      title={this.state.response.data_errors[type].errors[line]}
                                      key={"0-0-" + indexLine}/>
                                  </TreeNode>
                                ))}
                            </TreeNode>
                          ))}
                      </TreeNode>
                    </Tree>
                  ) : (
                    ""
                  )}
                </>) : ""}
            </Modal>
        </span>;
  }
}

CpUploadButton.propTypes = {
  endPoint: PropTypes.string.isRequired,
  templateUrl: PropTypes.string.isRequired,
  btnTitle: PropTypes.string,
};

export default CpUploadButton;
