import React from "react";

import {types} from "./../../constants";

const CpTypeCompteurFormat = ({type, withText, ...rest}) => {

  if (!type || !types[type]) return null;

  return <span {...rest}>{types[type].icon} {withText ?
    types[type].label :
    null}</span>;
};

export default CpTypeCompteurFormat;
