import {inject, observer} from "mobx-react";
import React from "react";
import {Redirect} from "react-router-dom";

@inject("interface", "dictionary", "user")
@observer
class AuthenticationRedirect extends React.Component {

  componentDidMount() {
    this.props.interface.setUpdateAuthenticationRedirect(false);
    // this.props.user.updateUser(null);
  }

  render() {
    return (<Redirect to="/login"/>);
  }
}

export default AuthenticationRedirect;
