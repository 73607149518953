export default {
  titre: "Mes Agences",
  sousTitre: "Agence",
  copro: "Copro",
  echue: "échue",
  agences: "Agences",
  liste: {
    gestionnaires: "Gestionnaires",
    coproEchoir: "Copros à échoir",
    filtreCommerciaux: "FILTRER (Commerciaux)",
    ajoutGestionnaire: "Ajouter un Gestionnaire",
  },
  formulaire: {
    label: {
      departement: "Département",
      nom: "Nom",
      siret: "Siret",
      siren: "Siren",
      creationSimple: "Création simple",
      creationAvance: "Création avancée",
      adresse: "Adresse",
      adressePostale: "Adresse postale",
      codePostal: "Code Postal",
      ville: "Ville",
      regroupement: "Regroupement",
    },
    messageErreur: {
      nomObligatoire: "Le nom est obligatoire",
      siretObligatoire: "Le siret est obligatoire",
      sirenObligatoire: "Le siren est obligatoire",
      siretInvalide: "SIRET invalide",
      formatInvalide: "Format invalide",
    },
    titreInput: {
      sirenTooltip: "Ce champ est automatiquement rempli avec le SIRET",
      ajoutAgence: "Ajouter une agence",
      envoiAcces: "Envoyer accès",
    },
  },
  detail: {
    adresse: "Adresse postale",
    siren: "Siren",
    gestionnaire: "Gestionnaire",
    directeur: "Directeur",
    nbCopros: "Nb copros",
  },
  message:{
    agenceMessage: "L'agence",
    suppressionEffectue: "a bien été supprimée.",
    confirmationSuppression: "Êtes-vous sûr(e) de vouloir supprimer cette agence ?",
  }
};
