import React from "react";
import {Select, Spin} from "antd";
import {CancelToken} from "axios";
import _ from "lodash";
import PropTypes from "prop-types";
import DataService from "../../services/dataService";

class CpAjaxSelect extends React.Component {

  constructor(props) {
    super(props);

    //Initial state
    const {dataSource = [], selectedValue} = props;
    this.state = {
      dataSource,
      loading: false,
      source: null,
      value: selectedValue,
    };
  }

  componentDidUpdate() {
    const {
            selectedValue,
            dataSource,
            updateExternalDatasource = false,
          } = this.props;

    //update dataSource externaly
    if (updateExternalDatasource && this.state.value !== selectedValue) {
      this.setState({
        value: selectedValue,
        dataSource: dataSource,
      });
    }
  }

  /**
   * On typing in the select, perform a search
   * @param searchedValue
   */
  handleSearch = (searchedValue) => {

    const {value, mode = "", endPoint, searchField} = this.props;
    const {dataSource} = this.state;

    if (this.state.source) {
      //Cancel previously running request, if any
      this.state.source.cancel();
    }

    if (!searchedValue) {
      //Cleared search: reset & do nothing
      this.setState({loading: false, dataSource: []});
      return;
    }

    //Create cancel token
    const source = CancelToken.source();

    //Set state
    // this.setState({ source, loading: true, dataSource: [] });
    this.setState({source, loading: true});
    DataService.getAjax(endPoint, searchField, searchedValue,{cancelToken: source.token})
    .then(({data}) => {

      let results = [];

      switch (mode) {
        case "multiple": {
          let filteredSource = dataSource.filter(d => value.includes(d["@id"]));
          let resultHydra = data["hydra:member"].filter(
            d => !value.includes(d["@id"]));

          results = [...filteredSource, ...resultHydra];
          break;
        }
        default:
          results = data["hydra:member"];
          break;
      }

      this.setState({loading: false, dataSource: results});
    });
  };

  render() {
    const {
            labelField,
            selectedField = "@id",
            onChange,
            placeholder   = "Rechercher...",
            mode          = "",
            showArrow     = false,
            onSelect,
          } = this.props;

    const {dataSource, loading, value} = this.state;
    const handleSearch = _.debounce(this.handleSearch, 500);

    return <Select
      showSearch
      showArrow={showArrow}
      filterOption={false}
      placeholder={placeholder}
      notFoundContent={loading ? <Spin size="small"/> : null}
      loading={loading}
      onSearch={handleSearch}
      onChange={(value) => {
        this.setState({value});
        onChange(value);
      }}
      {...onSelect}
      style={{width: "100%"}}
      mode={mode}
      value={value}
    >
      {dataSource.map((d, index) => (
        <Select.Option key={index}
                       value={d[selectedField] ? d[selectedField] : d["id"]}>
          {
            labelField.split(",").
              map(field => d[field.trim()] ? d[field.trim()] : "").
              filter(x => x.length).
              join(" - ")
          }
        </Select.Option>
      ))}
    </Select>;
  }
}

CpAjaxSelect.propTypes = {
  endPoint: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  showArrow: PropTypes.bool,
  mode: PropTypes.string,
  selectedValue: PropTypes.oneOfType(
    [PropTypes.array, PropTypes.string, PropTypes.number]),
};

export default CpAjaxSelect;
