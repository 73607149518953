import agence from "./modules/agence"
import global from "./modules/global"
import compteur from "./modules/compteur"
import copro from "./modules/copro"
import gestionnaire from "./modules/gestionnaire"

export default {
  agence,
  global,
  compteur,
  copro,
  gestionnaire,
}
