import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { CpIconSearch } from "../CpIcon";
import styled from "styled-components";

const { Option } = Select;

const SelectStylee = styled.div`
    position: relative;
    display: inline-block;
    text-align: right;
    .ant-select-selector {
        position: absolute;
        text-align: left;
        width: 100%;
        top: 0;
        left: 0;
    }
`;

function CpListFilter({ onFilterChange, fields, placeholder, optionList }) {
    const [filtresSelectionnes, setFiltresSelectionnes] = useState([]);

    const gererChangement = (valeurs) => {
        setFiltresSelectionnes(valeurs);
    };

    // Effet pour appeler onFilterChange lorsque filtresSelectionnes change
    useEffect(() => {
        if (typeof onFilterChange === "function") {
            onFilterChange({
                champsRecherche: filtresSelectionnes,
                fields: fields,
            });
        }
    }, [filtresSelectionnes]);

    return (
        <SelectStylee>
            <Select
                className="filter"
                mode="tags"
                style={{ width: 180, margin: "0 10px", verticalAlign: "top" }}
                placeholder={
                    <span style={{ color: "grey" }}>
            <CpIconSearch
                style={{ marginRight: "10px", fontSize: "20px" }}
            />
                        {placeholder ? placeholder.toUpperCase() : "FILTRER"}
          </span>
                }
                onChange={gererChangement} // Appel lors de l'ajout ou suppression d'un tag
                value={filtresSelectionnes} // Valeurs actuelles des filtres sélectionnés
                notFoundContent={null}
            >
                {optionList == null
                    ? null
                    : optionList.map((c) => (
                        <Option key={c} value={c}>
                            {c}
                        </Option>
                    ))}
            </Select>
        </SelectStylee>
    );
}

export default CpListFilter;
