exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "\n", ""]);

// Exports
exports.locals = {
	"width": "1080px",
	"borderRadius": "4px",
	"waterGreenPrimary": "#17aab5",
	"waterGreenDark": "#0097a2",
	"waterGreenMoreDark": "#037078",
	"bodyBackground": "#FAFAFA",
	"black0": "#242129",
	"black1": "#07051D",
	"white": "#FFFFFF",
	"grey0": "#FFFFFF",
	"grey1": "#F7F7F7",
	"grey2": "#F2F4F6",
	"grey3": "#EAEEF0",
	"grey4": "#C7C9CE",
	"grey5": "#BBBCC0",
	"grey6": "#666666",
	"grey7": "#47454C",
	"grey8": "#242129",
	"grey9": "#222028",
	"grey10": "#f6f6f7",
	"grey11": "#eaedee",
	"textHighlightDanger": "#D00000",
	"textHighlightWarning": "#F4AD08",
	"textHighlightSuccess": "#6bd368",
	"iconTarifC1": "#EB5A46",
	"iconTarifC2": "#61BD4F",
	"iconTarifC3": "#8EE881",
	"iconTarifC4": "#F2D600",
	"iconTarifC5": "#0079BF",
	"prioriteAucune": "#BBBCC0",
	"prioriteVert": "#6bd368",
	"prioriteRouge": "#D00000",
	"prioriteOrange": "#F4AD08"
};