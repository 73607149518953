import React from "react";
import {Input} from "antd";
import {inject, observer} from "mobx-react";
import CpEditForm from "../CpEditForm";

@inject("dictionary")
@observer
class FormSendMailAccess extends React.Component {

  constructor(props) {
    super(props);
    this.state = {dataSource: this.props.dataSource};

    this.formRef = React.createRef();

    this.fields = [
      {
        label: "Mails",
        key: "mails",
        input: <Input.TextArea maxLength={255} rows={2}/>,
        options: {
          rules: [
            {
              required: true,
              message: "Veuillez remplir ce champ",
            }],
        },
      }];
  }

  render() {
    return <CpEditForm formRef={this.formRef}
                       fields={this.fields} {...this.props}/>;
  }
}

export default (props) => <FormSendMailAccess {...props}/>;
