import PropTypes from "prop-types";

const SiretValidation = (siret) => {
  var estValide;
  if ( (siret.length !== 14) || (isNaN(siret)) )
    estValide = false;
  else {
    let somme = 0;
    let valeurCalculee;
    for (let compteur = 0; compteur<siret.length; compteur++) {
      if ((compteur % 2) === 0) {
        valeurCalculee = siret.charAt(compteur) * 2;
        if (valeurCalculee > 9)
          valeurCalculee -= 9;
      }
      else
        valeurCalculee = siret.charAt(compteur);
      somme += parseInt(valeurCalculee);
    }
    estValide = (somme % 10) === 0;
  }
  return estValide;
};

SiretValidation.propTypes = {
  siret: PropTypes.number.isRequired,
};

export default SiretValidation;
