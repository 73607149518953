import React from "react";
import {Button, Radio, Col, Form, Input, Row} from "antd";
import propTypes from "prop-types";

const CpTransactionFormCreate = (props) => {
  const {onFinish, onBack, dealname, reconduction, disabled} = props;
  const [form] = Form.useForm();

  const validateMessages = {
    required: "${label} est obligatoire",
  };

  return (
    <Row gutter={24} justify={"center"}>
      <Col span={24} style={{textAlign: "right"}}>
        <Form
          form={form}
          initialValues={{dealname: dealname, reconduction: reconduction}}
          name="transaction-form"
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <Form.Item
            labelCol={{span: 5}}
            name={"dealname"}
            label="Nom de la transaction"
            rules={[{ required: true }]}
          >
            <Input/>
          </Form.Item>
            <Form.Item labelCol={{span: 5}} name={"reconduction"}
                               label="S’agit-il d’une reconduction ?"
                               rules={[{ required: true }]}
                       style={{marginTop: "15px"}}>
              <Radio.Group value={reconduction}
                           style={{display: "flex", marginLeft: "5px"}}>
                <Radio.Button value={true}>OUI
                </Radio.Button>
                <Radio.Button value={false}>NON
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          <Form.Item labelAlign={"left"} style={{marginTop: "15px"}}>
            <Button onClick={onBack} type="secondary" style={{float: "left"}}>
              Précédent
            </Button>
            <Button htmlType="submit"
                    type="primary"
                    disabled={disabled}
            >
              Valider la transaction
            </Button>
          </Form.Item>
        </Form>

      </Col>
    </Row>
  );
};

CpTransactionFormCreate.propTypes = {
  disabled: propTypes.bool,
  onFinish: propTypes.func.isRequired,
  dealname: propTypes.string,
};

export default (props) => <CpTransactionFormCreate {...props} />
