import axios from "./axios";
import {DataTypeEnum} from "../components/common/enums/DataTypeEnum";

var genericUrl = {
  [DataTypeEnum.AGENCY]: {
    getById: (id) => axios.get(`/api/agences/${id}`),
    getBySiret: (siret) => axios.get(`/api/agences/rechercher/${siret}`),
    getAll: () => axios.get(`/api/agences`),
    getAccess: (id) => axios.get(`/api/agency/generate/account/${id}`),
    edit: (id, object) => {
      let dataToSubmit = {...object};
      delete dataToSubmit.id;
      delete dataToSubmit.activeManagers;
      delete dataToSubmit.countCopros;
      delete dataToSubmit.countManagers;
      delete dataToSubmit.documents;
      delete dataToSubmit.qualifiedAddress;
      delete dataToSubmit.qualifiedExecutive;
      delete dataToSubmit.managers;
      delete dataToSubmit.coprosEchues;
      delete dataToSubmit.filtreDepartement;
      delete dataToSubmit.filtreListeCommerciaux;
      delete dataToSubmit.priorite;
      return axios.put(`/api/agences/${id}`, dataToSubmit);
    },
    delete: (object) => axios.delete(`/api/agences/${object.id}`),
    deleteCommercial: (agenceId, commercialId) => axios.delete(`/api/agence/${agenceId}/courtier/${commercialId}`),
    getAgencyHubspotId: (id) => axios.get(`api/agences/${id}/hubspot`),
    postAccount: (id, params) => axios.post(`/api/agency/send/account/${id}`, params),
    postPriorite: (id) => axios.post(`/api/agences/${id}/augmenter-priorite`)
  },
  [DataTypeEnum.COPRO]: {
    getById: (id) => axios.get(`/api/copros/${id}`),
    getAll: (idCopro) => axios.get(`api/gestionnaire/${idCopro}/coproprietes`),
    edit: (id, object) => {
      return axios.put(`/api/copros/${id}`, object);
    },
    create: (object) => axios.post(`/api/copros`, object),
    getDependenciesFromId: (id) => axios.get(`/api/dependency/copro/${id}`),
    postDeplacerCopro: (params) => axios.post("api/copropriete/deplacer", params),
  },
  [DataTypeEnum.MANAGER]: {
    getById: (id) => axios.get(`/api/managers/${id}`),
    rechercheById: (params) => axios.get(`/api/managers`, {params}),
    getAll: () => axios.get("api/managers"),
    getContactHubspot: (id) => axios.get(`api/gestionnaires/contact/${id}`),
    edit: (id, object) => {
      let params = {...object};
      delete params.id;
      delete params['@type'];
      delete params['@id'];
      delete params['@context'];
      delete params.coprosEchues;
      delete params.isActif;
      delete params.isDirector;
      delete params.qualifiedName;
      delete params.hubspotId;
      return axios.put(`/api/gestionnaires/${id}`, params);
    },
    create: (params) => {
      return axios.post(`/api/gestionnaires`, params);
    }
  },
  [DataTypeEnum.COMPTEUR]: {
    getById: (id) => axios.get(`/api/compteur/${id}`),
    getByAgencyId: (agencyId) => {
      return axios.get(`/api/agences/${agencyId}/compteurs`);
    },
    getAdresseByPdl: (typeEnergie, pdl) => axios.get(`/api/compteur/${typeEnergie}/recuperer-adresse/${pdl}`),
    getAll: (idCompteur) => axios.get(`api/liste/compteurs/${idCompteur ? idCompteur : ""}`),
    getAllObjectGestionnaire: (idGestionnaire) => axios.get(`api/liste/compteurs/${idGestionnaire}`),
    getConsommation: (compteurId) => axios.get(`/api/compteur/${compteurId}/synchro-consommation`),
    edit: (idObject, object) => {
      return axios.put(`/api/compteur/${idObject}`, object);
    },
    postCompteurTransaction: (objetCompteur) => axios.post(`/api/compteur-transaction`,
      objetCompteur),
    postCompteurTransactionHubspot: (objetCompteur) => axios.post(`/api/compteur-transaction-hubspot`,
      objetCompteur),
    create: (params) => axios.post('/api/compteur', params)
  },
  [DataTypeEnum.USER]: {
    getAll: () => axios.get(`api/users`),
    getById: (id) => axios.get(`api/users/${id}`),
    edit: (id, object) => axios.put(`/api/users/${id}`, object),
  },
  [DataTypeEnum.DIVERS]: {
    getRechercheMagique: (valeur, params) => axios.get(`/api/recherche-magique/${valeur}`, params),
    getVilles: (codePostal) => axios.get(`/api/cities?zipCode=${codePostal}`),
    getLogout: () => axios.get("/logout"),
    getAjax: (endpoint, champRecherche, contenuRecherche, params) => axios.get(`${endpoint}?${champRecherche}=${contenuRecherche}`, params),
    getDictionnaire: () => axios.get("/api/dictionnaire"),
    putFormEdit: (endpoint, id, values) => axios.put(`${endpoint}/${id}`, values),
    getLogin: (data) => axios.post("login", data),
    createTransaction: (data) => axios.post(`/api/transactions`, data),
    postFormEdit: (endpoint, values) => axios.post(endpoint, values),
    postDocument: (data) => axios.post("api/documents", data),
    deleteButton: (entityIri) => axios.delete(entityIri),
    getCheckSession: () => axios.get("/check_session"),
  },
  [DataTypeEnum.MAESTRO]: {
    getDemandes: (uuid) => axios.get(`/api/gestionnaires/${uuid}/demandes-traitement-facture/`),
    getFacture: (uuid) => axios.get(`/api/demandes-traitement-facture/${uuid}`),
    putFacture: (uuid, data) => axios.put(`/api/demandes-traitement-facture/${uuid}`, data),
    postFacture: (id, data) => axios.post(`/api/gestionnaires/${id}/demander-traitement-facture`, data)
  }
};

const DataService = {
  getById: (type, id) => genericUrl[type].getById(id),
  rechercheById: (params) => genericUrl[DataTypeEnum.MANAGER].rechercheById(params),
  getByAgencyId: (type, id) => genericUrl[type].getByAgencyId(id),
  getAll: (type, params) => genericUrl[type].getAll(params),
  getAllObjectGestionnaire: (type, idGestionnaire) => genericUrl[type].getAllObjectGestionnaire(idGestionnaire),
  getAdresseByPdl: (typeEnergie, pdl) => genericUrl[DataTypeEnum.COMPTEUR].getAdresseByPdl(typeEnergie, pdl),
  getConsommation: (idCompteur) => genericUrl[DataTypeEnum.COMPTEUR].getConsommation(idCompteur),
  postCompteurTransaction: (objetCompteur) => genericUrl[DataTypeEnum.COMPTEUR].postCompteurTransaction(objetCompteur),
  postCompteurTransactionHubspot: (objetCompteur) => genericUrl[DataTypeEnum.COMPTEUR].postCompteurTransactionHubspot(objetCompteur),
  edit: (type, id, object) => {
      if (object) {
          let params = Object.keys(object).reduce((acc, key) => {
              acc[key] = object[key] === undefined ? null : object[key];
              return acc;
          }, {});
          return genericUrl[type].edit(id, params)
      }

      return genericUrl[type].edit(id, object)
  },
  create: (type, object) => genericUrl[type].create(object),
  delete: (type, object) => genericUrl[type].delete(object),
  deleteCommercial: (agencyId, commercialId) => genericUrl[DataTypeEnum.AGENCY].deleteCommercial(agencyId, commercialId),
  getDependenciesFromId: (id) => genericUrl[DataTypeEnum.COPRO].getDependenciesFromId(
    id),
  getAgencyHubspotId: (id) => genericUrl[DataTypeEnum.AGENCY].getAgencyHubspotId(id),
  postDeplacerCopro: (params) => genericUrl[DataTypeEnum.COPRO].postDeplacerCopro(params),
  getContactHubspot: (id) => genericUrl[DataTypeEnum.MANAGER].getContactHubspot(id),
  postAccount: (id, params) => genericUrl[DataTypeEnum.AGENCY].postAccount(id, params),
  postPriorite: (id) => genericUrl[DataTypeEnum.AGENCY].postPriorite(id),
  getAccess: (id) => genericUrl[DataTypeEnum.AGENCY].getAccess(id),
  createTransaction: (data) => genericUrl[DataTypeEnum.DIVERS].createTransaction(data),
  getRechercheMagique: (valeur, params) => genericUrl[DataTypeEnum.DIVERS].getRechercheMagique(valeur, params),
  getVilles: (codePostal) => genericUrl[DataTypeEnum.DIVERS].getVilles(codePostal),
  getBySiret: (siret) => genericUrl[DataTypeEnum.AGENCY].getBySiret(siret),
  postDocument: (data) => genericUrl[DataTypeEnum.DIVERS].postDocument(data),
  getLogout: () => genericUrl[DataTypeEnum.DIVERS].getLogout(),
  getLogin: (data) => genericUrl[DataTypeEnum.DIVERS].getLogin(data),
  putFormEdit: (endpoint, id, values) => genericUrl[DataTypeEnum.DIVERS].putFormEdit(endpoint, id, values),
  postFormEdit: (endpoint, values) => genericUrl[DataTypeEnum.DIVERS].postFormEdit(endpoint, values),
  getAjax: (endpoint, champRecherche, contenuRecherche, params) => genericUrl[DataTypeEnum.DIVERS].getAjax(endpoint, champRecherche, contenuRecherche, params),
  deleteButton: (entityIri) => genericUrl[DataTypeEnum.DIVERS].deleteButton(entityIri),
  getDictionnaire: () => genericUrl[DataTypeEnum.DIVERS].getDictionnaire(),
  getCheckSession: () => genericUrl[DataTypeEnum.DIVERS].getCheckSession(),
  getDemandes: (id) => genericUrl[DataTypeEnum.MAESTRO].getDemandes(id),
  getFacture: (id) => genericUrl[DataTypeEnum.MAESTRO].getFacture(id),
  putFacture: (uuid, data) => genericUrl[DataTypeEnum.MAESTRO].putFacture(uuid, data),
  postFacture: (id, data) => genericUrl[DataTypeEnum.MAESTRO].postFacture(id, data),
};

export default DataService;
