import styled from "styled-components";
import variables from "../../stylesheets/tools/variables";

const CpFormContainer = styled.div`
    border-radius: ${variables.borderRadius};
    padding: 20px;
    background-color: ${variables.white};

    .ant-form-item{
        margin-bottom: 10px;
    }
`;

export default CpFormContainer;
