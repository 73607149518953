import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Button, Collapse, Form, Layout, notification} from "antd";
import MaestroCpListCompteurs from "./MaestroCpListCompteurs";
import CpTitle from "../common/CpTitle";
import CpTypeCompteurFormat from "../common/CpTypeCompteurFormat";
import styled from "styled-components";
import variables from "../../stylesheets/tools/variables";
import MaestroCpListHeader from "./MaestroCpListHeader";
import dictionary from "../../stores/dictionary";
import DataService from "../../services/dataService";
import moment from "moment";

const StyledCollapse = styled(Collapse)`
  .ant-collapse-header {
    padding: 0 !important;
  }

  .show-btn, .hide-btn {
    margin-top: 5px;
  }

  .ant-collapse-item {
    background: ${variables.white};

    &.ant-collapse-item-active {
      margin-bottom: 5px;
    }
  }
`;

function MaestroCpSection(props) {
  const {Panel} = Collapse;
  const [factures, setFactures] = useState(props.factureMaestro.factures);
  const [copros, setCopros] = useState(props.factureMaestro.copros);
  const [form] = Form.useForm();
  const [segments, setSegments] = useState(dictionary.segments);
  const nomLocalStorage = props.nomLocalStorage;

  useEffect(() => {
    setSegments(dictionary.segments);
  }, [factures, copros]);

  const suppressionDepuisEnfant = async (value) => {
    const factureSeule = await factures.findIndex(
      (element) => element.id === value);
    if (!factureSeule) {
      const factureTriees = factures.filter(facture => facture.id !== value);
      setFactures(factureTriees);
    } else {
      const factureCoprosTriees = copros.map((copro) => {
        const facturesFiltrees = copro.factures.filter(
          facture => facture.id !== value);
        return {
          ...copro,
          factures: facturesFiltrees,
        };
      });
      const dataTransform = transformData(factureCoprosTriees);
      setCopros(dataTransform);
    }
  };
  const miseAJourInfosLocalStorage = (arg, valeursFormulaire) => {
    localStorage.setItem(nomLocalStorage, JSON.stringify(valeursFormulaire));
  };
  const vidangeLocalStorage = () => {
    localStorage.removeItem(nomLocalStorage);
  };

  const onFinish = (values) => {
    const idGestionnaire = props.match.params.id;
    const final = transformData(values);
    final.map((fin) => {
      delete fin.compteurs_ids;
    });
    const uuid = props.match.params.idDemande;
    DataService.putFacture(uuid, final).then(() => {
      notification.success({
        message: "Les modifications ont bien été prises en compte",
      });
      vidangeLocalStorage();
      props.history.push(`/gestionnaire/${idGestionnaire}/maestro`);
    }).catch(() => {
      notification.error({
        className: "error-server",
        message: "Une erreur s'est produite, merci de réessayer.",
        duration: 3,
      });
    });
  };

  return (
    <Layout>
      <Form
        form={form}
        onFinish={(values) => {
          onFinish(values);
        }}
        layout="vertical"
        onValuesChange={(changedValues, allValues) => miseAJourInfosLocalStorage(changedValues, allValues)}
      >
        <CpTitle level={4} style={{
          margin: "32px auto",
          fontSize: "30",
          fontWeight: "bold",
        }}>
          <CpTypeCompteurFormat style={{fontSize: 15}}/> Résultats à valider
        </CpTitle>
        <Form.Item requiredmark="required">
          {copros?.map((item, key) => {
              return (
                item.factures?.length > 0 &&
                <Layout key={key}>
                  <StyledCollapse bordered={true} defaultActiveKey={[key]}>
                    <Panel
                      collapsible
                      key={key}
                      header=
                        {
                          <MaestroCpListHeader
                            copro={item}
                          />
                        }
                    >
                      {item.factures.map((i, index) =>
                        <MaestroCpListCompteurs
                          evenementSuppressionToParent={suppressionDepuisEnfant}
                          segments={segments}
                          key={index}
                          factures={i}
                          idCompteurs={i.id}/>,
                      )}
                    </Panel>
                  </StyledCollapse>
                </Layout>
              );
            },
          )}
          <hr/>

          <CpTitle level={4} style={{
            margin: "32px auto",
            fontSize: "30",
            fontWeight: "bold",
          }}>
            <CpTypeCompteurFormat style={{fontSize: 15}}/> Résultats à qualifier
          </CpTitle>

          {factures?.map((item, key) =>
            <Layout key={key}>
              <StyledCollapse defaultActiveKey={[key]}>
                <Panel key={key} header={<MaestroCpListHeader
                  copro={{factures: [item], ...item}}/>}>
                  <MaestroCpListCompteurs
                    evenementSuppressionToParent={suppressionDepuisEnfant}
                    segments={segments}
                    factures={item}/>
                </Panel>
              </StyledCollapse>
            </Layout>,
          )}

        </Form.Item>
        <Button
          style={{marginLeft: "80%"}}
          type="primary"
          htmlType="submit"
        >
          Valider les compteurs
        </Button>
      </Form>
    </Layout>
  );
}

function transformData({factures, copros}) {
  const facturesList = Object.entries(factures).
    map(([id, facture]) => ({id, ...facture}));
  const coprosList = Object.values(copros);
  return facturesList.map((facture) => {
    facture.date_echeance ?
      facture.date_echeance = moment(facture.date_echeance).
        format("YYYY-MM-DD") :
      facture.date_echeance = "";
    const copro = coprosList.find((c) => c.compteurs_ids.includes(facture.id));
    return {
      ...facture,
      ...copro,
    };
  });
}

export default withRouter(MaestroCpSection);
