import React from "react";
import Moment from "moment";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {inject} from "mobx-react";
import CpText from "../common/CpText";
import variables from "../../stylesheets/tools/variables";

const CpCompteurDeadline = inject("dictionary")(({dictionary, date_end}) => {

  /**
   * Determine if date is closet to now
   * @param {integer} month
   * @returns {boolean}
   */
  const closeToMonth = (month = 0) => {
    if (!date_end || Moment().isAfter(date_end)) {
      return false;
    }

    return !!Moment(date_end, "YYYY-MM-DD").
      isBetween(Moment(), Moment().add(month, "months"));
  };

  const deadlineDays = () => {
    let now = Moment();
    let dateEnd = Moment(date_end);
    return Math.ceil(dateEnd.diff(now, "days", true));
  };
  const deadlineNearCheck = () => {
    return closeToMonth(dictionary.enums.deadlineTypeEnum["NEAR"]);
  }

  const deadlineShortCheck = () => {
    return closeToMonth(dictionary.enums.deadlineTypeEnum["SHORT"]);
  }

  return (
    <>
      {date_end ?
        <>
          {deadlineNearCheck() ?
            <ExclamationCircleOutlined
              style={{color: variables.textHighlightDanger, marginRight: 5}}/>
            : null}

          <CpText type={deadlineDays() === 0 ?
            "danger" :
            deadlineDays() < 0 ?
              "gray" :
              deadlineNearCheck() ? "danger" : deadlineShortCheck() ? "warning" : "success"}
                  bold>{Moment(date_end).format("DD/MM/YYYY")} </CpText>
        </>
        : "-"}
    </>
  );
});

export default CpCompteurDeadline;
