import React from "react";
import CpText from "../common/CpText";
import { FaArrowUp, FaArrowDown, FaMinus } from 'react-icons/fa';

const CpCompteurTendanceMarche = ({prix}) => {
  const getIcone = (prix) => {
    if (prix >= 0) {
      return prix === 0 ? <FaMinus/> : <FaArrowUp/>
    }

    return <FaArrowDown/>
  }

  return (
    <>
      <CpText>{getIcone(prix)} {prix} €</CpText>
    </>
  );
};

export default CpCompteurTendanceMarche;
