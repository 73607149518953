import React from "react";
import {Input, Select, Switch} from "antd";
import CpEditForm from "../CpEditForm";
import {CpIconUser} from "../CpIcon";
import {inject} from "mobx-react";

@inject("dictionary")
class FormManager extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isCreationMode: !props.values.id, //define if new or edit mode
    };

    const {reasons = {}, civility = {}} = this.props.dictionary;

    this.fields = [
      {
        label: "Civilité",
        flex: "15%",
        key: "civility",
        input: <Select
          placeholder="Civilité"
        >
          {Object.values(civility).map((value, index) =>
            <Select.Option key={index} value={(value && value !== "") ? value : null}>{value}</Select.Option>,
          )}
        </Select>,
        options: {
          rules:
            [{required: true, message: "La civilité est obligatoire"}],
        },
      }, {
        label: "Prénom",
        flex: "35%",
        key: "firstname",
        input: <Input placeholder="-"/>,
        options: {
          rules:
            [{required: true, message: "Le prénom est obligatoire"}],
        },
      }, {
        label: "Nom",
        flex: "50%",
        key: "lastname",
        input: <Input placeholder="-"/>,
        options: {
          rules:
            [{required: true, message: "Le nom est obligatoire"}],
        },
      },
      {
        label: "Téléphone",
        flex: "50%",
        key: "phone",
        input: <Input placeholder="-"/>,
      },
      {
        label: "Email",
        flex: "50%",
        key: "email",
        input: <Input type="email" placeholder="-"/>,
        options: {
          validateTrigger: "onBlur",
          rules:
            [
              {required: true, message: "L'adresse est obligatoire"},
              {type: 'email', message: "Merci d'entrer un format valide"}
            ],
        },
      }, {
        label: "Portable",
        flex: "50%",
        key: "mobile",
        input: <Input placeholder="-"/>,
      },
      {
        label: "Raison",
        flex: "50%",
        key: "reason",
        input: <Select>
          {Object.keys(reasons).map((key ) =>
            <Select.Option key={key} value={reasons[key]}>{reasons[key]}</Select.Option>,
          )}
        </Select>,
        options: {
          rules:
            [{required: true, message: "La raison est obligatoire"}],
        },
      },
      {
        label: "Directeur",
        flex: "50%",
        key: "estDirecteur",
        input: <Switch checkedChildren="Oui" unCheckedChildren="Non"/>,
        options: {
          valuePropName:"checked",
        },
      },

      //ASSISTANT
      {
        label: "Assistant(e)",
        type: "separator",
        key: "AssistantSeparator",
      }, {
        label: "Civilité",
        flex: "15%",
        key: "assistantCivility",
        input: <Select
          placeholder="Civilité"
        >
          {Object.values(civility).map((value, index) =>
            <Select.Option key={index} value={(value && value !== "") ? value : null}>{value}</Select.Option>,
          )}
        </Select>,
      }, {
        label: "Prénom",
        flex: "35%",
        key: "assistantFirstname",
        input: <Input placeholder="-"/>,
      }, {
        label: "Nom",
        flex: "50%",
        key: "assistantLastname",
        input: <Input placeholder="-"/>,
      },
      {
        label: "Téléphone",
        flex: "50%",
        key: "assistantPhone",
        input: <Input placeholder="-"/>,
      }, {
        label: "Portable",
        flex: "50%",
        key: "assistantMobile",
        input: <Input placeholder="-"/>,
      },
      {
        label: "Email",
        flex: "50%",
        key: "assistantEmail",
        input: <Input type="email" placeholder="-"/>,
        options: {
          validateTrigger: "onBlur",
          rules:
            [{type: 'email', message: "Merci d'entrer un format valide"}]},
      },
    ];
  }

  render() {
    return <CpEditForm title={<><CpIconUser/> Gestionnaire </>}
                       fields={this.fields} {...this.props}/>;
  }
}

export default (props) => <FormManager {...props} />
