import lang from "../../../lang/fr";

export const DataTypeEnum = {
  AGENCY: "AGENCY",
  COPRO: "COPRO",
  MANAGER: "MANAGER",
  COMPTEUR: "COMPTEUR",
  USER: "USER",
  DIVERS: "DIVERS",
  MAESTRO: "MAESTRO"
};
export const MagicSearchTitleEnum = {
  Agency: "Agences",
  Manager: "Gestionnaires",
  Copro: "Copropriétés",
  Compteur: "Compteurs",
};

export const SwitchAgencyCompteurData = [
  {
    key: "agency",
    path: "/agency",
    pathName: lang.global.switch.agences
  },
  {
    key: "compteur",
    path: "/compteur",
    pathName: lang.global.switch.compteurs
  },
];
