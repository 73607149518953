import React from "react";
import {inject, observer} from "mobx-react";
import CpButton from "./CpButton";

import PropTypes from "prop-types";

@inject("interface")
@observer
class CpExportButton extends React.Component {

  constructor(props) {
    super(props);
    this.export = this.export.bind(this);
  }

  export() {
    const {values = {}, endPoint} = this.props;
    const id = values.id;
    const url = process.env.REACT_APP_SERVER_ROOT + endPoint + id;
    window.location = url;
  }

  render() {
    const {buttonProps = {}} = this.props;
    const buttonTitle = "Exporter";

    return <span>
            <CpButton
              onClick={this.export} {...buttonProps}>{buttonTitle}</CpButton>
        </span>;
  }
}

CpExportButton.propTypes = {
  endPoint: PropTypes.string.isRequired,
};

export default CpExportButton;
