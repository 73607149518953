import React from "react";
import {inject, observer} from "mobx-react";
import CpEditForm from "../CpEditForm";
import CpAjaxSelect from "../CpAjaxSelect";
import endpoint from "../../../services/endpointEnum";

@inject("dictionary")
@observer
class FormCoproMove extends React.Component {

  constructor(props) {
    super(props);
    this.state = {dataSource: this.props.dataSource};
    this.formRef = React.createRef();

    this.fields = [
      {
        label: "Gestionnaire",
        flex: "100%",
        key: "manager_id",
        input: <CpAjaxSelect
          endPoint={endpoint.GESTIONNAIRE_RECHERCHE()}
          labelField="qualifiedName"
          selectedField="manager_id"
          searchField={"qualifiedName"}
          placeholder="Sélectionner un gestionnaire..."
        />,
      },
    ];
  }

  render() {
    return <CpEditForm formRef={this.formRef}
                       fields={this.fields} {...this.props}/>;
  }
}

export default (props) => <FormCoproMove {...props}/>;
