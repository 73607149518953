import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {inject} from "mobx-react";
import variables from "../../stylesheets/tools/variables";
import Pluralize from "../../helpers/Pluralize";

/**
 * Style for approaching text
 */
const approachingStyle = {
  3: {color: variables.textHighlightDanger, text: "3 mois"},
  6: {color: variables.textHighlightWarning, text: "6 mois"},
  12: {color: variables.textHighlightSuccess, text: "12 mois"},
};

/**
 * Get approaching text from data - enum
 * @param data
 * @param deadlineTypeEnum
 * @returns {string}
 */
const getApproachingText = (data, deadlineTypeEnum) => {
  let deadlineText = "";
  for (const key of Object.keys(data).
    filter(x => Object.values(deadlineTypeEnum).indexOf(parseInt(x)) !== -1)) {
    if (data[key] && data[key] > 0) {
      var style = approachingStyle[key];

      deadlineText =
        <p style={{color: style.color, fontWeight: "bold"}}>{data[key]} à
          échoir dans {style.text}</p>;
      break;
    }
  }

  return deadlineText;
};

const CpApproachingDeadline = inject("dictionary")(({dictionary, data, totalCompteurs}) => {
  const [approachingText, setApproachingText] = useState(null);

  useEffect(() => {
    if (dictionary.enums.deadlineTypeEnum) {
      setApproachingText(
        getApproachingText(data, dictionary.enums.deadlineTypeEnum));
    }
  }, [dictionary.enums]);

  return <>
    <Pluralize count={totalCompteurs} plural={"compteurs"}
               title={"compteur"}/>
    <br/>
    {approachingText}
  </>;
});

CpApproachingDeadline.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CpApproachingDeadline;
