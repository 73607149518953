import {action, observable} from "mobx";
import DataService from "../services/dataService";

class Detail {
  //type
  @observable item = {};
  @observable inProgress = false;
  @observable type = null;

  @action setType(type = null) {
    this.type = type;
  }

  @action reset() {
    this.item = {};
    return this;
  }

  @action load(id) {
    this.inProgress = true;
    return DataService.getById(this.type, id).then(action(({data}) => {
      this.item = data;
    })).finally(action(() => {
      this.inProgress = false;
    }));
  }

  @action update(object) {
    return DataService.edit(this.type, object.id, object).then(action(({data}) => {
      this.item = data;
    }));
  }

  @action delete() {
    return DataService.delete(this.type, this.item);
  }
}

export default new Detail();
