import React, {useState} from "react";
import styled from "styled-components";
import variables from "../../stylesheets/tools/variables";
import DataService from "../../services/dataService";

const StyledIconePriorite = styled.span`
  display: inline-flex;
  height: 15px;
  width: 15px;
  margin-left: 5px;
  vertical-align: middle;
  cursor: pointer;
`;
function MarqueurPriorite(props) {
  const conversionPrioriteCouleur = (priorite) => {
    switch (priorite) {
      case "AUCUNE":
        return variables.prioriteAucune;
      case "VERT":
        return variables.prioriteVert;
      case "ORANGE":
        return variables.prioriteOrange;
      case "ROUGE":
        return variables.prioriteRouge;
      default:
        return variables.prioriteAucune;
    }
  }
  const [couleurPriorite, setCouleurPriorite] = useState(conversionPrioriteCouleur(props.priorite))
  const clickIcone = () => {
    DataService.postPriorite(props.agencyId).then(({data}) => {
      setCouleurPriorite(conversionPrioriteCouleur(data.priorite));
    });
  }
  return (
    <StyledIconePriorite
      style={{backgroundColor: couleurPriorite}}
      onClick={clickIcone}
    />
  )
}
export default MarqueurPriorite;
