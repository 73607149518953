export default {
  message:{
    gestionnaireMessage: "Le gestionnaire",
    suppressionEffectue: "a bien été supprimé.",
    confirmationSuppression: "Êtes-vous sûr(e) de vouloir supprimer ce gestionnaire ?",
  },
  label:{
    gestionnaire: "Gestionnaire",
    gestionnaires: "Gestionnaires",
  }
}
