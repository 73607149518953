import React, {useRef, useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Layout, Form, Input, Select, DatePicker, Button, Spin} from "antd";
import dictionary from "../../stores/dictionary";
import DataService from "../../services/dataService";
import endpointEnum from "../../services/endpointEnum";
import CpAjaxSelectAdd from "../common/CpAjaxSelectAdd";
import _ from "lodash";
import CpTransactionSelectEnergy from "../transaction/CpTransactionSelectEnergy";

function FormCompteurSimple(props) {
  const {formRempli, formRef, ajoutPdlParent, ValiderFormParent, visible} = props;

  const [typeEnergie, setTypeEnergie] = useState("");
  const [pdl, setPdl] = useState(props.pdl ? props.pdl : "");
  const [segments, setSegments] = useState(dictionary.segments);
  const [fournisseurs] = useState(dictionary.providers);
  const [cities, setCities] = useState([]);
  const [ville, setVille] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (formRempli.type && formRempli.pdl) {
      setTypeEnergie(formRempli.type);
      setPdl(formRempli.pdl);
      setVille(formRempli.ville);
      if (typeEnergie) {
        formRef.current?.setFieldsValue(formRempli);
      }
    }
    setSegments(dictionary.segments);
  }, [typeEnergie]);

  const formulaireInitialise = (data = {}) => {
    data?.code_postal && handleSearchPostalCode(data.code_postal);
    formRef.current?.setFieldsValue({
      rue: data?.rue,
      code_postal: data?.code_postal,
      segment: data?.segment,
      ville: data?.ville,
      fournisseur: data?.fournisseur,
    });

    onSelectCity({name: data?.ville});
    setLoading(false);
  };

  const onChangeInputPdl = (inputPdl) => {
    setLoading(true);
    setPdl(inputPdl);
    debounceRecupererAdressePdl(inputPdl, typeEnergie);
  };

  let debounceRecupererAdressePdl = useRef(_.debounce((inputPdl, valeurTypeEnergie) => recupererAdressePdl(inputPdl, valeurTypeEnergie), 500)).current;

  const recupererAdressePdl = (inputPdl, valeurTypeEnergie) => {
    if (inputPdl && inputPdl.length >= 8) {
      setLoading(true);
      ajoutPdlParent(valeurTypeEnergie, inputPdl);
      DataService.getAdresseByPdl(valeurTypeEnergie, inputPdl).then(({data}) => {
        formulaireInitialise(data);
      }).catch(() => {
        formulaireInitialise();
      });
    }
  };

  const handleSearchPostalCode = (codePostal) => {
    if (codePostal.length === 5) {
      DataService.getVilles(codePostal).then(({data}) => {
        setCities(data["hydra:member"]);
      });
    }
  };

  const onSetEnergy = (e) => {
    setTypeEnergie(e);
    formRef.current.setFieldsValue({type: e});
  };

  const validerFormEnfant = (form) => {
    ValiderFormParent(form);
  };

  const onSelectCity = (city) => {
    if (city == null)
      return;
    setVille(city.name);
    formRef.current?.setFieldsValue({
      ville: city?.name
    });
  };

  const suiteForm = () => {
    if (!visible) {
      setPdl("");
      setTypeEnergie("");
    }
    if (loading) {
      return <Spin/>;
    } else {
      return (<>
        <Form.Item
          name="date_echeance"
          label="Echéance"
          style={{width: "130px"}}
        >
          <DatePicker format={"DD/MM/YYYY"}/>
        </Form.Item>
        <Form.Item
          name="segment"
          label="Segment"
          rules={[{
            required: true,
            message: "Merci de fournir un segment",
          }]}>
          <Select
            placeholder="Segment"
            name="segment"
          >
            {segments.filter(seg => seg.type === typeEnergie).map((segment, index) => (
              <Select.Option key={index} value={segment["label"]}>{segment["label"]}</Select.Option>
            ))
            }
          </Select>
        </Form.Item>
        <Form.Item
          name="fournisseur"
          label="Fournisseur"
        >
          <Select
            placeholder="Fournisseur"
            name="fournisseur"
          >
            {fournisseurs.map((fournisseur, index) =>
              <Select.Option key={index}
                             value={fournisseur["id"]}
              >
                {fournisseur.nom} <img
                src={fournisseur.logo} alt={"logo"}
                height={"20px"}/>
              </Select.Option>)}
            ))
          </Select>
        </Form.Item>
        <Form.Item
          name="rue"
          label="Adresse"
          rules={[{
            required: true,
            message: "Merci de fournir une adresse",
          }]}
        >
          <Input name="adresse" placeholder="Adresse"/>
        </Form.Item>
        <Form.Item
          name="code_postal"
          label="Code postal"
          rules={[
            {
              required: true,
              message: "Merci de fournir un code postal",
            },
            {pattern: "^[0-9]*$", message: "Format invalide"}
          ]}
        >
          <Input placeholder="Code Postale" onChange={(event) => handleSearchPostalCode(event.target.value)}/>
        </Form.Item>
        {(ville || cities) &&
          <Form.Item
            name="ville"
            label="Ville"
            rules={[{
              required: true,
              message: "Merci de fournir une ville",
            }]}
          >
            <CpAjaxSelectAdd
              formRef={formRef}
              endPoint={endpointEnum.VILLE_RECHERCHE()}
              labelField="name"
              cities={cities}
              onSelectCity={onSelectCity}
              selectedValue={ville}
              placeholder="Adresse - Ville"
            />
          </Form.Item>
        }
        <Button htmlType="submit"
                type="primary"
        >
          Valider
        </Button>
      </>);
    }
  };

  return (
    <Layout style={{background: "none"}}>
      <Form ref={formRef} onFinish={(form) => validerFormEnfant(form)}
            style={{width: "300px", margin: "auto"}}
            layout="vertical"
      >
        <Form.Item
          name="type"
          label="Type"
          rules={[{
            required: true,
            message: "Merci de fournir un type d'énergie",
          }]}
        >
          <CpTransactionSelectEnergy
            depuisFormCompteur={true}
            valid={!!typeEnergie}
            dealType={typeEnergie}
            afficheBtn={false}
            onChangeHandler={(e) => onSetEnergy(e)}
          />
        </Form.Item>
        {typeEnergie &&
          <Form.Item
            name="pdl"
            label="PDL"
            rules={[{
              required: true,
              message: "Merci de fournir un pdl",
            }]}
          >
            <Input placeholder="PDL" onChange={(element) => {
              if (element.target.value.length >= 8) onChangeInputPdl(element.target.value);
            }}/>
          </Form.Item>}
        {pdl && pdl.length >= 8 && formRempli !== {} &&
          suiteForm()
        }
      </Form>
    </Layout>
  );
}

export default withRouter(FormCompteurSimple);
