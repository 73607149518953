import React from "react";
import {inject, observer} from "mobx-react";
import {notification, Spin, Steps} from "antd";
import CpTransactionSelectEnergy
  from "../../transaction/CpTransactionSelectEnergy";
import CpTransactionSelectCompteurs
  from "../../transaction/CpTransactionSelectCompteurs";
import CpTransactionFormCreate from "../../transaction/CpTransactionFormCreate";
import DataService from "../../../services/dataService";
import {DataTypeEnum} from "../enums/DataTypeEnum";
import TransactionSelectManager
  from "../../transaction/TransactionSelectManager";

@inject("dictionary", "list", "detail")
@observer
class CpoModalEditTransaction extends React.Component {

  constructor(props) {
    super(props);
    //instance type for list
    this.state = {
      current: 0,
      transaction: {liste_des_pdl: [], statusTransaction: false},
      listCompteurs: [],
      allCompteurSelected: false,
      noCompteurSelected: true,
      dealname: "", //[nom de la copropriété]-[Nom du gestionnaire]-[copro] (si création depuis page gestionnaire) -[x compteurs ou pdl compteur]
      type: "",
      gestionnaires: [],
      manager: 0,
      nomGestionnaire: "",
      nomAgence: "",
      reconduction: null,
    };
  }

  componentDidMount() {
    const {item} = this.props;
    const {fillDependenciesById} = this.props.list;

    if (!item.compteurs) {
      // -- Make sure we get the dependencies we need
      fillDependenciesById.bind(this.props.list)(item.id, ["compteurs"]);
    }
    this.setState({
      listCompteurs: item.compteurs,
      type: this.props.detail,
    });

    this.setState({
      nomAgence: this.props.detail.type === DataTypeEnum.AGENCY ?
        this.props.name :
        this.props.detail.item.agency.name,
    });

    if (this.props.detail.type === DataTypeEnum.MANAGER) {
      this.setState({nomGestionnaire: this.props.name});
    }

    if (this.props.detail.type === DataTypeEnum.AGENCY) {
      const manager = this.props.manager;
      this.listeGestionnaire(manager);
    }
  }

  listeGestionnaire = (id) => {
    DataService.getById(DataTypeEnum.AGENCY, id).then(({data}) => {
      this.setState({
        gestionnaires: data.managers,
      });
    });
  };

  // -- handle template
  onChange = (current) => {
    this.setState({current});
  };

  goNext = () => {
    if (this.state.type.type !== DataTypeEnum.AGENCY) {
      const next = this.state.current === 2 ? 0 : this.state.current + 1;
      this.onChange(next);
    } else {
      const next = this.state.current === 3 ? 0 : this.state.current + 1;
      this.onChange(next);
    }
    if (this.state.type.type === DataTypeEnum.AGENCY && this.state.current === 2) {
      this.dealnameGenerateur();
    } else if (this.state.type.type === DataTypeEnum.MANAGER && this.state.current === 1) {
      this.dealnameGenerateur();
    }
  };

  goBack = () => {
    const back = this.state.current === 0 ? 0 : this.state.current - 1;
    this.onChange(back);
  };

  // -- set energy
  onSetEnergy = (energie) => {
    const {item} = this.props;
    const listCompteurs = item.compteurs.filter(item => item.type === energie);
    this.setState({
      transaction: {
        dealtype: energie,
        liste_des_pdl: listCompteurs.length === 1 ? [listCompteurs[0].id] : [],
      }, listCompteurs,
    });
  };

  onSelectGestionnaire = (gestionnaire) => {
    this.setState({manager: gestionnaire.key});
    DataService.getById(DataTypeEnum.MANAGER, gestionnaire.key).
      then(({data}) => {
        this.setState({nomGestionnaire: data.qualifiedName});
      });
  };

  dealnameGenerateur = () => {
    let coproNom = "";
    if (this.state.type.type === DataTypeEnum.MANAGER) {
      coproNom = this.props.item.nom ?
        this.props.item.nom :
        this.props.item.rue;
    }
    let nomTransaction = `${this.state.nomAgence ?? ""} - ${this.state.nomGestionnaire} ${coproNom ? "- " + coproNom :
                                                                                          ""}`;
    if (this.state.transaction.liste_des_pdl.length === 1) {
      const compteurSelectionne = this.state.listCompteurs.find(compteur => this.state.transaction.liste_des_pdl[0] === compteur.id);
      const compteurSelectionnePdl = Object.prototype.hasOwnProperty.call(compteurSelectionne, "pdl") ?
                                     compteurSelectionne["pdl"] : compteurSelectionne["number"];
      nomTransaction = `${nomTransaction} - ${compteurSelectionnePdl}`;
    } else {
      nomTransaction = `${nomTransaction} - ${this.state.transaction.liste_des_pdl.length} compteurs`;
    }

    this.setState({
      transaction:
        {
          ...this.state.transaction,
          dealname: nomTransaction,
        },
    });
  };

  // -- toggle compteur
  onSelectCompteur = (compteur) => {
    const {transaction, listCompteurs} = this.state;
    const {liste_des_pdl = []} = transaction;
    let newList = [];

    if (liste_des_pdl && liste_des_pdl.includes(compteur)) {
      // -- we remove it
      newList = liste_des_pdl.filter((i) => i !== compteur);
    } else {
      newList = [...liste_des_pdl, compteur];
    }

    this.setState({
      transaction:
        {
          ...transaction,
          liste_des_pdl: newList,
        },
      allCompteurSelected: newList.length === listCompteurs.length,
      noCompteurSelected: newList.length === 0,
    });
  };

  onSelectAllCompteur = () => {
    const {transaction, listCompteurs} = this.state;

    const {liste_des_pdl = []} = transaction;
    let newList = [];
    let allCompteurSelected, noCompteurSelected = false;

    if (liste_des_pdl.length === 0 || listCompteurs.length >
      liste_des_pdl.length) {
      newList = listCompteurs.map((c) => c.id);
      allCompteurSelected = true;
    } else {
      newList = [];
      noCompteurSelected = true;
    }

    this.setState({
      transaction: {
        ...transaction,
        liste_des_pdl: newList,
      }, allCompteurSelected, noCompteurSelected,
    });
  };

  onValidateTransaction = (form) => {
    const {manager} = this.state.manager !== 0 ? this.state : this.props;
    const {modalClose} = this.props;
    this.setState(
      {transaction: {...this.state.transaction, statusTransaction: true}});
    const data = {...this.state.transaction, ...form, manager};
    delete data.statusTransaction;
    DataService.createTransaction(data).then(({data}) => {
      if (data.id) {
        notification.success({
          message: " Transaction créée dans Hubspot",
        });
        modalClose();
        this.setState({transaction: {statusTransaction: false}});
      }
    });
  };

  // -- fully managed components
  getTemplate = () => {
    const {current} = this.state;
    const {gestionnaires} = this.state;
    const {type} = this.state.type;
    const templates = type === DataTypeEnum.MANAGER ? {
        0: <CpTransactionSelectEnergy
          onFinish={this.goNext}
          title="Sélection de l'énergie"
          valid={!!this.state.transaction.dealtype}
          dealType={this.state.transaction.dealtype}
          onChangeHandler={this.onSetEnergy}/>,
        1: <CpTransactionSelectCompteurs
          onFinish={this.goNext}
          title="Sélection du/des compteurs"
          onBack={this.goBack}
          list={this.state.listCompteurs}
          indeterminate={!this.state.noCompteurSelected &&
            !this.state.allCompteurSelected}
          compteurSelected={this.state.transaction.liste_des_pdl}
          valid={!!(this.state.transaction.liste_des_pdl &&
            this.state.transaction.liste_des_pdl.length)}
          onChangeHandler={this.onSelectCompteur}
          onChangeAllHandler={this.onSelectAllCompteur}
        />,
        2: <CpTransactionFormCreate
          disabled={this.state.transaction.statusTransaction}
          dealname={this.state.transaction.dealname}
          reconduction={this.state.transaction.reconduction}
          onFinish={this.onValidateTransaction}
          onBack={this.goBack}
        />,
      }
      :
      {
        0: <TransactionSelectManager
          title="Sélection du gestionnaire"
          gestionnaires={gestionnaires}
          onChangeHandler={this.onSelectGestionnaire}
          onBack={this.goBack}
          valid={!!(this.state.manager)}
          onFinish={this.goNext}/>,
        1: <CpTransactionSelectEnergy
          onFinish={this.goNext}
          title="Sélection de l'énergie"
          valid={!!this.state.transaction.dealtype}
          dealType={this.state.transaction.dealtype}
          onChangeHandler={this.onSetEnergy}/>,
        2: <CpTransactionSelectCompteurs
          onFinish={this.goNext}
          title="Sélection du/des compteurs"
          onBack={this.goBack}
          list={this.state.listCompteurs}
          indeterminate={!this.state.noCompteurSelected &&
            !this.state.allCompteurSelected}
          compteurSelected={this.state.transaction.liste_des_pdl}
          valid={!!(this.state.transaction.liste_des_pdl &&
            this.state.transaction.liste_des_pdl.length)}
          onChangeHandler={this.onSelectCompteur}
          onChangeAllHandler={this.onSelectAllCompteur}/>,
        3: <CpTransactionFormCreate
          compteurSelected={this.state.transaction.liste_des_pdl}
          disabled={this.state.transaction.statusTransaction}
          dealname={this.state.transaction.dealname}
          reconduction={this.state.transaction.reconduction}
          onFinish={this.onValidateTransaction}
          onBack={this.goBack}/>,
      };
    return templates[current];
  };

  render() {
    const {current} = this.state;
    const {type} = this.state.type;
    // -- when template action is done
    return (
      <>
        <Steps current={current}>
          <Steps.Step subTitle="Gestionnaire"/>
          {type === DataTypeEnum.AGENCY &&
            <Steps.Step subTitle="Energie"/>
          }
          <Steps.Step subTitle="Compteurs"/>
          <Steps.Step subTitle="Informations"/>
        </Steps>
        <div style={{marginTop: 15}}>
          {this.props.item.compteurs && this.getTemplate()}
        </div>
        {!this.props.item.compteurs &&
          <div style={{textAlign: "center"}}>
            <Spin/>
          </div>
        }
      </>
    );
  }
}

export default CpoModalEditTransaction;
