import React from "react";
import styled from "styled-components";
import {NavLink, withRouter} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {FiLogOut} from "react-icons/fi";
import {Layout, Menu, Spin} from "antd";
import lang from "./lang/fr"
import CpMagicSearch from "./components/common/CpMagicSearch";
import AuthenticationRedirect from "./components/login/AuthenticationRedirect";
import Router from "./components/router/Router";

// Import Common Stylesheets (order is important)
import "./stylesheets/antd-theme.less";
import "./stylesheets/imports.scss";

//Import containers
import variables from "./stylesheets/tools/variables";
import {CpIconMail, CpIconUser} from "./components/common/CpIcon";

//Import Zendesk
import Zendesk from "react-zendesk";
import DataService from "./services/dataService";
const ZENDESK_KEY = "ead3a829-7045-4528-9914-2f88cc27f7d7";

const zendeskSetting = {
  webWidget: {
    color: {
      theme: "#3998a3",
      launcherText: "#FFF",
    },
    launcher: {
      label: {
        "*": lang.global.header.aide,
      },
    },
    offset: {
      horizontal: "190px",
      vertical: "-10px",
    },
    position: {
      horizontal: "right",
      vertical: "top",
    },
  },
  helpCenter: {
    title: {
      "*": lang.global.header.aide,
    },
  },
  contactForm: {
    suppress: true,
  },
};

//Header with menu
const StyledHeader = styled(Layout.Header)`
    width: 100vw;
    overflow: hidden;
    margin: 0;
    position: static;
    z-index: 200;

    &>ul, &>ul>li{
        border: 0 !important;
    }
    &>ul>li{
        margin-top: -4px !important;
        top: 0 !important;
    }

    .ant-select-selection-placeholder{
        position: absolute;
        top: 0;
        right: 11px;
        bottom: 0;
        left: 11px;
    }

    .cp-nav-item-logo{
        display: inline-block;
        width: 220px;
        padding: 0;
        text-align:center;
        background-color: ${variables.white};
        img{
            max-width:85%;
            margin: 12px 0 5px 0;
        }
        &>a, &>a:hover{
            color: ${variables.white};
            font-weight: bold;
            font-size: 21px;
        }
    }

    .cp-nav-item-search{
        display: inline-block;
        width: calc(100% - 420px);
        &.small{
            width: calc(100% - 640px);
        }
        font-style: italic;
        .ant-select{
            position: relative;
            top: -4px;
        }
    }

    .cp-nav-item-contact{
        display: inline-block;
        width: 220px;
        padding: 0 12px;
        text-align: center;
        font-size: 13px;
        background-color: #fff;
        a span.anticon {
        position: absolute;
            top: 10px;
            margin-left: 5px;
            font-size: 30px;
        }
        &.ant-menu-item-active{
           color: ${variables.white};

       }
    }

    .cp-nav-item-user{
        display: inline-block;
        width: 200px;
        text-align: center;
        font-size: 13px;
        .ant-menu-submenu-title{
            color: ${variables.white} !important;
            background-color: #9b9a9a;
        }
    }

    .ant-menu-submenu-title:hover{
        color: ${variables.white};
    }
    [role="menuitem"].noHover:hover{
        background-color:white;
    }
`;

//Content container, using a spin for loading indicator
const StyledContent = styled(Layout.Content)`
    height: calc(100vh - 50px);
    overflow-y: auto;
`;

const StyledLayoutContent = styled(Layout)`
    width: ${variables.width};
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 80px;
    margin: auto;
`;

@inject("interface", "dictionary", "user")
@observer
class App extends React.Component {

  componentDidMount() {
    DataService.getCheckSession().then(({data}) => {
      if (data) {
        this.props.dictionary.load();
      } else {
        if (window.location.pathname !== "/login") {
          this.props.history.push("/login");
        }
      }
    });
  }

  onClickMenu({key}) {
    if (key == "/logout") {
      DataService.getLogout().then(() => {
        this.props.user.updateUser(null);
        this.props.history.push("/login");
      });
    }
  }

  render() {
    const rootPathName = "/" + this.props.location.pathname.split("/")[1];
    const { commercials } = this.props.dictionary;
    const {
      isConnected,
      isLoading,
      user,
      isAdmin,
    } = this.props.user;
    const {needAuthenticationRedirect} = this.props.interface;

    if (needAuthenticationRedirect) {
      return <AuthenticationRedirect/>;
    }

    const iconProps = {
      size: 28,
      style: {
        position: "relative",
        top: 8,
      },
    };

    // /!\ /!\ /!\ /!\ Always wait for dictionary to load before showing anything /!\ /!\ /!\ /!\
    if (isLoading) {
      return <Spin size="large" spinning={true}>
        <div style={{height: "100vh", width: "100vw"}}></div>
      </Spin>;
    }

    return (<Layout>
      <StyledHeader>
        <Menu
          className="cp-nav-menu"
          mode="horizontal"
          selectedKeys={[rootPathName]}
          onClick={this.onClickMenu.bind(this)}
        >
          <Menu.Item className="cp-nav-item-logo noHover" key="/agency">
            <NavLink to="/">
              <img src="/images/Pilote Energie_Standard.png"/>
            </NavLink>
          </Menu.Item>

          {isConnected ?
            <Menu.Item className={user.roles.indexOf("ROLE_AGENCY") === -1 ?
              "cp-nav-item-search" :
              "cp-nav-item-search small"} key="/search">
              <CpMagicSearch isAgency={user.roles.indexOf("ROLE_AGENCY") !== -1}/>
            </Menu.Item> :
            null}

          {isConnected && user.roles.indexOf("ROLE_AGENCY") !== -1 ?
            <Menu.Item className="cp-nav-item-contact" key="/contact">
              <div style={{width: "100px"}}>{lang.global.header.contactCourtier}
                {commercials ? <a style={{marginLeft: 5, color: "inherit"}}
                                  href={"mailto:" +
                                  commercials}><CpIconMail/></a> : null}
              </div>
            </Menu.Item>
            : null
          }

          {isConnected ?
            <Menu.SubMenu className="cp-nav-item-user" key="/user"
                          title={user.qualifiedName}>
              {isAdmin ? <Menu.Item key="/admin/user">
                <NavLink to="/admin/user">
                  <CpIconUser size={21}/>{lang.global.header.utilisateurs}
                </NavLink>
              </Menu.Item> : null}
              <Menu.Item key="/logout">
                <FiLogOut {...iconProps}/>{lang.global.header.deconnexion}
              </Menu.Item>
            </Menu.SubMenu> : null}
        </Menu>
      </StyledHeader>

      <StyledContent>
        <Spin style={{height: "calc(100vh - 50px)"}} size="large"
              spinning={this.props.interface.isLoading}>
          <StyledLayoutContent>
            <Router {...this.props} />
          </StyledLayoutContent>
        </Spin>
      </StyledContent>
      { isConnected && user.roles.indexOf("ROLE_AGENCY") === -1 ?
      <Zendesk defer zendeskKey={ZENDESK_KEY} {...zendeskSetting} /> : null}
    </Layout>);
  }
}

export default withRouter(App);

