import _ from "lodash";
import {isNullOrUndefined} from "./index";
import DataService from "../services/dataService";
import moment from "moment";

export function modifierDonneesCompteurLegacyPourPut(donnees) {
    let nouvelObjet = donnees;
//suivant le payload qu'on récupère, on peut avoir un objet ou directement l'id pour la copro et le fournisseur
    nouvelObjet.copropriete = donnees?.copropriete?.id ? donnees?.copropriete?.id : donnees.copropriete;
    nouvelObjet.fournisseur = donnees?.fournisseur?.id ? donnees?.fournisseur?.id : donnees.fournisseur;


    return _.omit(nouvelObjet, [
        "filtre_adresse_copro",
        "filtre_nom_fournisseur",
        "date_creation_transaction",
        "documents",
        "consommation",
        "comment",
        "commentaire_date",
        "commentaire_auteur",
        "comment_author",
        "comment_date",
        "consumption_date",
        "consumption_total",
        "copro_address",
        "copro_name",
        "countDocuments",
        "date_end",
        "fichier_contrat",
        "id",
        "label",
        "logo",
        "name",
        "number",
        "tendance_marche",
        "transaction_id",
        "gestionnaire"
    ]);
}

export function modifierDonneesCoproLegacyPourPut(donnees) {
    let nouvelObjet = { ...donnees };
    if(typeof donnees.commentaire === "object"){
        nouvelObjet.commentaire = donnees.commentaire?.commentaire
        nouvelObjet.commentaire_auteur = donnees.commentaire?.auteur
        nouvelObjet.commentaire_date = donnees.commentaire?.date
    }
    return _.omit(nouvelObjet, [
        "id",
        "adresse",
        "echeances_compteurs",
        "syndicat",
        "total_compteurs",
    ]);
}

export function modifierDonneesCompteurLegacyModalEdition(donneesAEnvoyer) {
    if(!isNullOrUndefined(donneesAEnvoyer.commentaire)) {

        donneesAEnvoyer.commentaire = {
            commentaire: donneesAEnvoyer.commentaire,
            auteur: donneesAEnvoyer.auteur,
            date: donneesAEnvoyer.date,
        };
    }
    return _.omit(donneesAEnvoyer, [
    "comment",
    "fichier_contrat",
    "filtre_adresse_copro",
    "filtre_nom_fournisseur",
    "id",
    "tendance_marche",
    "transaction_id",
    "date_creation_transaction"
]);
}

export function modifierDonneesCompteurLegacyDependance(donneesRecues) {
    let nouvelObjet = {};
    if(!isNullOrUndefined(donneesRecues)){
    nouvelObjet = donneesRecues ;
    nouvelObjet.commentaire = {
        commentaire: donneesRecues?.comment,
        auteur: donneesRecues?.comment_author,
        date: donneesRecues?.comment_date,
    };

    //y a pas copropriete?.id si ça vient de managercoprolist
    nouvelObjet.copropriete = donneesRecues?.copropriete.id;
    nouvelObjet.fournisseur = donneesRecues?.fournisseur.id;
}
    return nouvelObjet;
}

export function formatageCommentaire(typeObjet, donneesAFormater){
    if(isNullOrUndefined(donneesAFormater)) return null;
    if(typeObjet === 'copro'){
        return {
            commentaire: donneesAFormater.commentaire,
            auteur: donneesAFormater.commentaire_auteur,
            date: donneesAFormater.commentaire_date,
        }
    }
    return {
        commentaire: donneesAFormater.comment,
        auteur: donneesAFormater.comment_author,
        date: donneesAFormater.comment_date,
    }
}

export async function recupererGestionnaireCompteurs(compteurs) {
    const gestionnaireIdsUniques = [...new Set(compteurs.map(compteur => compteur.copropriete.gestionnaire))];
    const {data} = await DataService.rechercheById({  id: gestionnaireIdsUniques });
    const gestionnaires =  data["hydra:member"];
    const gestionnaireMap = gestionnaires.reduce((map, gestionnaire) => {
        map[gestionnaire.id] = {
            nom: gestionnaire.lastname,
            prenom: gestionnaire.firstname,
            email: gestionnaire.email,
        };
        return map;
    }, {});

    return compteurs.map(compteur => {
        const gestionnaireId = compteur.copropriete.gestionnaire;
        const gestionnaireData = gestionnaireMap[gestionnaireId] || null;

        return {
            ...compteur,
            gestionnaire: gestionnaireData,
        };
    });
}

export function filtrerListe(params, liste) {
    const filtresDisponibles = [
        {
            condition: (params) => !!params.date_echeance,
            filtre: filtrerParEcheance,
        },
        {
            condition: (params) => Array.isArray(params.type),
            filtre: filtrerParType,
        },
        {
            condition: (params) => !!params.range_echeance,
            filtre: filtrerParDateRange,
        },
        {
            condition: (params) => Array.isArray(params.champsRecherche) && params.champsRecherche.length > 0,
            filtre: filtrerParChampsTexte,
        },
        {
            condition: (params) => !!params.tendance,
            filtre: filtrerParTendance,
        },
        {
            condition: (params) => !!params.transac,
            filtre: filtrerParTransaction,
        },
        {
            condition: (params) => !!params.fournisseur,
            filtre: filtrerParFournisseur,
        },
    ];

    const filtresAppliques = filtresDisponibles
    .filter(({ condition }) => condition(params))
    .map(({ filtre }) => filtre);

  return filtresAppliques.reduce((filteredListe, fn) => fn(params, filteredListe), liste);
}

function filtrerParEcheance(filtres, liste) {
    const dateActuelle = moment().startOf("day");
    const dateFin = moment().add(filtres.date_echeance, "months").endOf("day");

    return liste.filter((compteur) => {
        const dateCompteur = moment(compteur.date_echeance).startOf("day");
        return dateCompteur.isBetween(dateActuelle, dateFin, null, "[]");
    });
}

function filtrerParType(filtres, liste) {
    if(filtres.type.length === 0) return [];
    return liste.filter((compteur) => filtres.type.includes(compteur.type));
}

function filtrerParFournisseur(filtres, liste) {
    return liste.filter(
        (compteur) => compteur.fournisseur.id === filtres.fournisseur
    );
}

function filtrerParTendance(filtres, liste) {
    return liste.filter(
        (compteur) => !isNullOrUndefined(compteur.tendance_marche)
    );
}

function filtrerParTransaction(filtres, liste) {
    return liste.filter((compteur) => compteur.transaction_id);
}

function filtrerParDateRange(filtres, liste) {
    const [dateStart, dateEnd] = filtres.range_echeance.map((date) =>
        moment(date).startOf("day")
    );

    return liste.filter((compteur) => {
        const compteurDate = moment(compteur.date_echeance).startOf("day");
        return compteurDate.isBetween(dateStart, dateEnd, null, "[]");
    });
}

function filtrerParChampsTexte(filtres, liste) {
    const { champsRecherche, fields } = filtres;

    return liste.filter((compteur) => {
        if (!fields || fields.length === 0) return true;

        return fields.some((field) => {
            const champ = compteur[field];
            if (champ && (typeof champ === "string" || typeof champ === "number")) {
                return champsRecherche.some((val) =>
                    champ.toString().toLowerCase().includes(val.toLowerCase())
                );
            }
            return false;
        });
    });
}
