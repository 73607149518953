import React from "react";

import {CpIconElec, CpIconGaz} from "./components/common/CpIcon";

const types = {
  GAZ: {label: "Gaz", icon: <CpIconGaz/>, desc: "GAZ"},
  ELEC: {label: "Electricité", icon: <CpIconElec/>, desc: "ELECTRICITE"},
};

const roles = {
  ROLE_ADMIN: {label: "Administrateur"},
  ROLE_COMMERCIAL: {label: "Commercial"},
  ROLE_AGENCY: {label: "Agence"},
  ROLE_USER: {label: "Utilisateur"},
};

export {types, roles};
