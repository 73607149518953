import React, {useState} from "react";
import CpModal from "../CpModal";
import CpButton from "../buttons/CpButton";
import EditTransaction from "./CpoModalEditTransaction";

const CpModalTransaction = ({
  label,
  item,
  name,
  manager,
  onModalOpen,
  onModalClose,
  buttonProps,
  ...rest
}) => {

  // -- destructure relevant infos from current compteur

  const [visible, setIsVisible] = useState(false);

  const modalOpen = (e) => {

    // -- Remove focus around buttons on click
    e.currentTarget.blur();
    if (onModalOpen) {
      onModalOpen(() => setIsVisible(true));
    } else {
      setIsVisible(true);
    }
  };

  const modalClose = () => {
    setIsVisible(false);
    if (onModalClose) {
      onModalClose();
    }
  };

  return (
    <>
      <CpButton onClick={modalOpen} {...buttonProps}>{label}</CpButton>

      <CpModal
        {...rest}
        title="Transaction"
        size={"m"}
        okText="Valider"
        modalClose={modalClose}
        visible={visible}
      >
        <EditTransaction
          item={item}
          transaction={{}}
          name={name}
          manager={manager}
          modalClose={modalClose}
        />
      </CpModal>
    </>
  );
};

CpModalTransaction.defaultProps = {
  buttonProps: {},
  label: "Transaction",
  size: "m",
  name: "",
};
export default CpModalTransaction;
