import React, {useState, useRef} from "react";
import {withRouter} from "react-router-dom";
import {Layout, Form, Input, DatePicker, Select, Divider, Button} from "antd";
import CpAjaxSelectAdd from "../common/CpAjaxSelectAdd";
import endpointEnum from "../../services/endpointEnum";
import DataService from "../../services/dataService";
import lang from "../../lang/fr";

function FormCopro({validerFormCoproParent, buttonPrecedent}) {
  const [cities, setCities] = useState([]);
  const [ville, setVille] = useState();
  const formRef = useRef();

  const handleSearchPostalCode = (codePostal) => {
    if (codePostal.length === 5) {
      DataService.getVilles(codePostal).then(({data}) => {
        setCities(data["hydra:member"]);
      });
    }
  };

  const onSelectCity = (city) => {
    if (city == null)
      return;
    setVille(city.name);
    formRef.current?.setFieldsValue({
      ville: city?.name
    });
  };
  const validerFormEnfant = (values) => {
    validerFormCoproParent(values);
  };
  return (
    <Layout style={{background: "none"}}>
      <Form ref={formRef} onFinish={(values) => validerFormEnfant(values)} layout="vertical">
        <Form.Item
          name="nom"
          label="Nom"
          rules={[{
              required: true,
              message: "Nom de copro obligatoire",
          }]}
        >
          <Input placeholder="[SDC-Nom Copropriété]" name="nom"/>
        </Form.Item>
        <Form.Item
          name="reference"
          label="Ref interne agence"
        >
          <Input placeholder="Ref interne agence" name="reference"/>
        </Form.Item>
        <Form.Item
          name="rue"
          label="Rue"
          rules={[{
            required: true,
            message: "Adresse obligatoire",
          }]}
        >
          <Input placeholder="Adresse - Rue" name="rue"/>
        </Form.Item>
        <Form.Item
          name="code_postal"
          label="Code Postal"
          rules={[{
            required: true,
            message: "Adresse obligatoire",
          },
            {
              pattern: "^[0-9]*$",
              message: "Format invalide"
            },
          ]}
        >
          <Input placeholder="Code Postal" name="code_postal"
                 onChange={(event) => handleSearchPostalCode(event.target.value)}/>
        </Form.Item>
        <Form.Item
          name="ville"
          label="Ville"
          initialValue={ville}
          rules={[{
            required: true,
            message: "Merci de fournir une ville",
          }]}
        >
          <CpAjaxSelectAdd
            formRef={formRef}
            endPoint={endpointEnum.VILLE_RECHERCHE()}
            labelField="name"
            cities={cities}
            onSelectCity={onSelectCity}
            selectedValue={ville}
            placeholder="Adresse - Ville"
          />
        </Form.Item>
        <Form.Item
          name="date_decision"
          label="Date décision"
          style={{width: "130px"}}
        >
          <DatePicker placeholder="Date décision"
                      format={"DD/MM/YYYY"}/>
        </Form.Item>
        <Form.Item
          name="type_decision"
          label="Type de décision">
          <Select
            placeholder="Sélectionner le type de décision..."
            name="type_decision"
          >
            <Select.Option value="CS">CS (Conseil Syndical)</Select.Option>
            <Select.Option value="AG">AG (Assemblée Générale)</Select.Option>
            <Select.Option value="GS">GS (Gestionnaire)</Select.Option>
          </Select>
        </Form.Item>
        <Divider orientation="left" plain style={{margin: "0px"}}>
          Contact Conseil Syndical
        </Divider>
        <Form.Item
          name="conseil_syndical_nom"
          label="Nom"
        >
          <Input placeholder="Nom"/>
        </Form.Item>
        <Form.Item
          name="conseil_syndical_prenom"
          label="Prenom"
        >
          <Input placeholder="Prenom"/>
        </Form.Item>
        <Form.Item
          name="conseil_syndical_telephone"
          label="Téléphone"
        >
          <Input placeholder="Téléphone"/>
        </Form.Item>
        <Form.Item
          name="conseil_syndical_mail"
          label="Email"
        >
          <Input placeholder="Email"/>
        </Form.Item>
        {buttonPrecedent}
        <Button
          type="primary"
          htmlType="submit"
          justify={"end"} style={{marginTop: "15px", float: "right"}}
        >
          {lang.global.form.valider}
        </Button>

      </Form>
    </Layout>
  );

}

export default withRouter(FormCopro);
