import {action, computed, observable} from "mobx";

// import uuid from 'node-uuid';

class Interface {
  @observable loadingRequests = 0;
  @observable needAuthenticationRedirect = false;

  @computed get isLoading() {
    return this.loadingRequests > 0;
  }

  @action setUpdateAuthenticationRedirect(state) {
    this.needAuthenticationRedirect = state;
  }
}

export default new Interface();
