export default {
  mesCompteurs: "Mes Compteurs",
  compteur: "Compteur",
  filtre:{
    filtrerParEcheance: "Sélectionner une période pour filtrer les dates d'échéance",
    tendance: "Tendance",
    toutesTransactions: "Toutes transactions",
    sansTransactions: "Sans transactions"
  },
  label:{
    segment: "Segment",
    tarif: "Tarif",
    consommation: "consommation",
    consommations: "consommations",
    dateEcheance: "Date d'échéance",
    tendanceMarche: "Tendance Marché",
    sansTransaction: "Sans transaction",
    transactionEnCours: "Transaction en cours",
    plusDeuxTransactions:"2+ documents",
    documents:"documents",
    document:"document",
    compteurs:"compteurs",
    transaction:"Transaction",
    recuperer:"Récupérer",
    rafraichir:"Rafraîchir",
    commentaire:"Commentaire",
    aucunCommentaire:"Aucun commentaire",
    voirTraviata:"Voir dans Traviata",
    voirHubspot:"Voir dans Hubspot",
    telechargerContrat:"Télécharger contrat",
    ajouterCompteur:"Ajouter un compteur",
  },
  message:{
    compteurMessage: "Le compteur",
    suppressionEffectue: "a bien été supprimé.",
    confirmationSuppression: "Êtes-vous sûr(e) de vouloir supprimer ce compteur ?",
  }
}
