import React from "react";
import {Input} from "antd";
import {inject, observer} from "mobx-react";
import CpEditForm from "../CpEditForm";

@inject("dictionary")
@observer
class FormBuildAccess extends React.Component {

  constructor(props) {
    super(props);
    this.state = {dataSource: this.props.dataSource};
    this.formRef = React.createRef();

    this.fields = [
      {
        label: "Login",
        flex: "50%",
        key: "login",
        input: <Input type="text" disabled={true}/>,
      },
      {
        label: "Mot de passe",
        flex: "50%",
        key: "password",
        input: <Input type="text" disabled={true}/>,
      },
    ];
  }

  render() {
    return <CpEditForm validateText="Générer un nouveau mot de passe"
                       formRef={this.formRef}
                       fields={this.fields} {...this.props}/>;
  }
}

export default (props) => <FormBuildAccess {...props}/>;
