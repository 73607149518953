import React from "react";
import {inject, observer} from "mobx-react";
import AgencyAccessRoutes from "./routes/AgencyAccessRoutes";
import CommercialAccessRoutes from "./routes/CommercialAccessRoutes";
import DefaultRoutes from "./routes/DefaultRoutes";

@inject("user")
@observer
class Router extends React.Component {
  render() {
    const {agencyId, isAgency, isConnected} = this.props.user;

    return (
      <>
        {isConnected ?
          (
            isAgency ?
              <AgencyAccessRoutes agencyId={agencyId}/> :
              <CommercialAccessRoutes {...this.props} />
          )
          :  <DefaultRoutes {...this.props} />}
      </>
    );
  }
}

export default Router;
