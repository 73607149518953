import styled from "styled-components";
import variables from "../../stylesheets/tools/variables";

const getColor = (type) => {
  switch (type) {
    case "C1" || "TP" :
      return variables.iconTarifC1;
    case "C2" || "T4" :
      return variables.iconTarifC2;
    case "C3" || "T3" :
      return variables.iconTarifC3;
    case "C4" || "T2" :
      return variables.iconTarifC4;
    case "C5" || "T1" :
      return variables.iconTarifC5;
    default :
      return variables.iconTarifC3; //default when no type
  }
};

const CpIconTarif = styled.span`
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: ${props => getColor(props.type)};
    border-radius: 15px;
    margin-right: 5px
`;

export default CpIconTarif;


