import React, {useState} from "react";
import {CpIconCheck, CpIconCommentaire} from "../CpIcon";
import {Modal, Form, Input} from "antd";
import moment from "moment";
import CpButton from "../buttons/CpButton";
import "../../../stylesheets/main.css";

function ModaleCommentaires(props) {
    const [, setInputValue] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const [valeur, setValeur] = useState(null);
    // il s'agit soit d'un compteur, soit d'une copro
    const idObjet = props.idObjet;
    const commentaire = props.commentaire;
    const utilisateur = props.utilisateur.user.qualifiedName;
    //On a besoin de savoir qui est l'envoyeur, la gestion du payload GET/PUT n'est pas uniformisée en back
    const origineDonnees = props.origineDonnees;
    const styleModale= {
        modalStyle: {
            width: "40% !important"
        },
        btnModalStyle: {
            backgroundColor: "#222028",
            color: "#FAFAFA"
        },
        btnModalHoverStyle:{
            backgroundColor: "#FAFAFA",
            color: "#00939e"
        }
    }

    function modifierCommentaire(){
        props.handleChange(valeur, idObjet, origineDonnees);
        closeModal();
    }
    function showModal() {
        setIsVisible(true);
    }
    function closeModal() {
        setIsVisible(false);
    }
    async function handleInputChange(event) {
        const newValue = event.target.value;
        setInputValue(newValue);
        await setValeur({
            ...valeur,
            commentaire: {
               commentaire: event.target.value,
                auteur: utilisateur,
                date: moment(new Date()),
            }
        });
    }

    return (
        <>
            <CpButton className="show-btn modal-btn-commentaire" onClick={showModal}>
                <div style={{ width: "125px", textOverflow: "ellipsis", overflowX: "hidden" }}>
                    <CpIconCommentaire style={{ textAlign: "left", marginRight: "5px" }} />
                    {commentaire?.commentaire
                        ?
                        commentaire?.commentaire : "Aucun commentaire"}
                </div>
            </CpButton>

            <Modal
                title={<><span style={{ fontWeight: "700" }}><CpIconCommentaire style={{ marginRight: "5px" }} />Commentaire</span></>}
                visible={isVisible}
                maskClosable={true}
                destroyOnClose={true}
                onCancel={closeModal}
                className="modale-commentaire"
                style={styleModale.modalStyle}
                footer={
                    <div>
                        <span style={{ marginRight: "5px" }}>
                            {commentaire?.auteur ? `${commentaire?.auteur} le ${moment(commentaire?.date).format("DD/MM/YYYY")}` : null}
                        </span>
                        <CpButton
                            className="btn-modal"
                            style={styleModale.btnModalStyle}
                            onMouseOver={e => e.currentTarget.style = styleModale.btnModalHoverStyle}
                            onMouseOut={e => e.currentTarget.style = styleModale.btnModalStyle}
                            onClick={modifierCommentaire}>
                            Modifier
                            <CpIconCheck />
                        </CpButton>
                    </div>
                }
                bodyStyle={{ padding: 0 }}
            >
                <Form style={{ padding: "24px" }}>
                    <Form.Item initialValue={commentaire?.commentaire} name="commentaire" style={{ margin: 0, height: '100%' }}>
                        <Input.TextArea
                            onChange={handleInputChange}
                            maxLength={2000}
                            rows={4}
                            style={{ height: '100%', width: '100%' }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
export default ModaleCommentaires;
