import React from "react";
import styled from "styled-components";
import {Input} from "antd";

import PropTypes from "prop-types";
import {Pluralize} from "../../../helpers";
import CpTitle from "../CpTitle";
import CpFormContainer from "../CpFormContainer";
import variables from "../../../stylesheets/tools/variables";
import CpListHeaderEditForm from "./CpListHeaderEditForm";
import CpButton from "../buttons/CpButton";
import {CpIconAdd} from "../CpIcon";

const StyledInputGroup = styled(Input.Group)`
  width: 55%;
  float: right;
  text-align: right;
  display: flex;

  & > * {
    text-align: left;
    margin-left: 10px;
  }

  .ant-select-selector {
    height: 32px;
    overflow: hidden;
    background: ${variables.white} !important;
  }

  .ant-select-selector:hover, .ant-select-selector:focus-within {
    height: unset;
    z-index: 2;
  }
`;

class CpListHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: props.visible ?? false,
    };

    this.showForm = this.showForm.bind(this);
    this.hideForm = this.hideForm.bind(this);
  }

  showForm() {
    this.setState({visible: true});
  }

  hideForm() {
    this.setState({visible: false});
  }

  render() {
    const {visible} = this.state;
    const {
            icon,
            title,
            plural,
            filter,
            count,
            form,
            onSubmit,
            element,
            afficherBoutonFormulaire = true,
          } = this.props;

    return (
      <>
        <CpTitle level={2}>
                    <span className={"table-title"}
                          style={{textTransform: "uppercase"}}>
                        <span
                          style={{marginRight: "5px"}}>{icon}</span> <Pluralize
                      count={count} plural={plural} title={title}/>
                    </span>
          <StyledInputGroup
            style={{justifyContent: "flex-end", marginRight: 10}}>
            {form && afficherBoutonFormulaire ?
              <CpButton onClick={this.showForm}
                        className={"CpTitleButton"}
                        size="large"
                        type="secondary"
                        style={{minWidth: 110}}
                        icon={<CpIconAdd/>}
              >
                {title}
              </CpButton> : null
            }
            {element}
          </StyledInputGroup>

          <div style={{
            marginTop: "5px",
            padding: "10px",
          }}>
            {filter}
          </div>


        </CpTitle>

        {form && visible ?
          <CpFormContainer>
            <CpListHeaderEditForm
              hideForm={this.hideForm}
              {...this.props}
              onSubmit={(values, redirect) => {
                this.hideForm();
                onSubmit(values, redirect);
              }}
            />
          </CpFormContainer> : null}
      </>
    );
  }
}

CpListHeader.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string.isRequired,
  plural: PropTypes.string,
  icon: PropTypes.node.isRequired,
  count: PropTypes.number.isRequired,
  filter: PropTypes.node,
  values: PropTypes.object, //for CpListHeaderEditForm
  endPoint: PropTypes.string, //for CpListHeaderEditForm
  onSubmit: PropTypes.func, //for CpListHeaderEditForm
  onSubmitRedirect: PropTypes.string, //for CpListHeaderEditForm
  form: PropTypes.func, //for CpListHeaderEditForm
};

export default CpListHeader;
