import React from "react";
import styled from "styled-components";
import {Typography} from "antd";
import variables from "../../stylesheets/tools/variables";

const titles = {};

//H1
titles[1] = styled(Typography.Title)`
  &.ant-typography{
      margin: 32px auto;
      font-size: 30px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 39px;
      //text-align: center;
  }
  &>.anticon{font-size: 30px}
`;

//H2
titles[2] = styled(Typography.Title)`
    &.ant-typography{
        text-transform: uppercase;
        color: ${variables.waterGreenPrimary};
        font-size: 25px;
    }
    &>.anticon{
        font-size: 30px;
        margin-right: 10px;
    }
    &>*{
        text-transform: none;
    }
`;
//H3
titles[3] = styled(Typography.Title)`
    &.ant-typography{
        margin-bottom: 15px;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
    }
    &>*{
        vertical-align:middle;
    }
    &>.anticon{
        //margin-bottom: -10px;
        font-size: 30px;
        margin-right: 10px;
        filter: invert(100%);
        position: relative;
        top: -3px;
    }
`;

//H4 or lists
titles[4] = styled.div`
    color: ${variables.black0};
    letter-spacing: 0;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 4px;
`;

titles[5] = styled.div`
  color: ${variables.grey6};
  text-transform: uppercase;
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 0.75px;
  line-height: 20px;
`;

const CpTitle = ({level = 2, ...rest}) => {
  if (titles[level]) {
    return React.createElement(titles[level], {level, ...rest});
  } else {
    return <Typography.Title level={level} {...rest}/>;
  }
};

export default CpTitle;
