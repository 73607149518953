import React from "react";
import {DatePicker, Input, Select} from "antd";
import {inject, observer} from "mobx-react";
import CpEditForm from "../CpEditForm";
import CpAjaxSelect from "../CpAjaxSelect";
import {withRouter} from "react-router-dom";
import DataService from "../../../services/dataService";
import {DataTypeEnum} from "../enums/DataTypeEnum";
import endpointEnum from "../../../services/endpointEnum";
import moment from "moment";

@inject("dictionary")
@inject("detail")
@observer
class FormCompteur extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // segmentType: props.values.type,
      values: props.values,
      fournisseurs: this.props.dictionary.providers,
      segments: props.values.type ?
                this.props.dictionary.segments.filter(
                  x => x.type == props.values.type) :
                this.props.dictionary.segments,
      selectedCoproValue: undefined,
    };
  }
  componentDidMount() {
    // Récupération de l'id de copro depuis l'url (besoin de withRouter pour cette donnée)
    const coproId = this.props?.match?.params?.coproId;
    if (coproId && !this.props.edition && this.props.isAddFromCopro) {
      this.getQualifiedNameFromId(coproId);
    } else {
      this.setState({
        "selectedCoproValue": this.state.values.copropriete instanceof Object ?
                              this.getQualifiedName() :
                              this.state.values.copropriete,
      });
    }
  }

  getQualifiedName() {
    return this.state.values.copropriete ?
           (this.state.values.copropriete.nom ?
            this.state.values.copropriete.nom :
            "") + " " +
             this.state.values.copropriete.adresse
                                         : undefined;
  }
  /**
   * Récupération des infos de copro correspondant à l'id
   * dans le cas où on crée le compteur suite à une création de copro
   * @param coproId
   */
  getQualifiedNameFromId(coproId) {
    DataService.getById(DataTypeEnum.COPRO, coproId).then(({data}) => {
      // Valeur envoyé (id copro)
      this.setState(
        {values: {...this.state.values, copropriete: data.id}});
      // Nom affiché dans le sélecteur 'copropriete'
      this.setState({
        "selectedCoproValue": `${data.nom} ${data.adresse}`,
      });
    });
  }

  render() {
    const {formRef, setRequiredFields, vueEndpoint, onFieldsChange} = this.props;
    const {item} = this.props.detail;
    const {values, segments, fournisseurs} = this.state;
    const fields = [
      {
        label: "RAE/PCE/PDL/PRM",
        flex: "55%",
        key: "pdl",
        input: <Input placeholder="-"
                      onChange={({target: {value}}) => this.setState({
                        values: {
                          ...this.state.values,
                          pdl: value,
                        },
                      })}/>,
        options: {
          rules:
            [
              {
                required: true,
                message: "Le RAE/PCE/PDL/PRM du compteur est obligatoire",
              }],
        },
      },
      {
        label: "Fournisseur",
        flex: "45%",
        key: "fournisseur",
        input: <Select
          showSearch
          optionFilterProp="children"
          placeholder={"Sélectionner un fournisseur"}
          onChange={e => this.setState(
            {values: {...this.state.values, fournisseur: e}})}
        >
          {fournisseurs.map((fournisseur, index) =>
            <Select.Option key={index}
                           value={fournisseur["id"]}
            >
              {fournisseur.nom} <img
              src={fournisseur.logo} alt={"logo"}
              height={"20px"}/>
            </Select.Option>)}
        </Select>,
      },
      {
        label: "Associer une nouvelle copropriété",
        key: "copropriete",
        input: <CpAjaxSelect
          endPoint={endpointEnum.COPRO_ASSOCIEE(vueEndpoint, item.id)}
          labelField={"name, qualifiedAddress"}
          searchField="qualifiedAddress"
          placeholder="Rechercher une copropriété..."
          key={this.state.selectedCoproValue}
          selectedValue={this.state.selectedCoproValue}
          onChange={(e) => this.setState(
            {values: {...this.state.values, copropriete: e}})}
          dataSource={[]}
        />,
        options: {
          rules: [{required: true, message: "La copropriété est obligatoire"}],
        },
      },
      {
        label: "Type",
        flex: "34%",
        key: "type",
        input: <Select
          showSearch
          placeholder="Sélectionner un type"
          optionFilterProp="children"
          onChange={(e) => this.setState({
            values: {...this.state.values, type: e, segment: undefined},
            segments: this.props.dictionary.segments.filter(x => x.type === e),
          })}
        >
          <Select.Option key="1" value="ELEC">Electricité</Select.Option>
          <Select.Option key="2" value="GAZ">Gaz</Select.Option>
        </Select>,
        options: {
          rules:
            [{required: true, message: "Le type du compteur est obligatoire"}],
        },
      },
      {
        label: "Segment",
        flex: "33%",
        key: "segment",
        input: <Select
          showSearch
          disabled={!this.state.values.type}
          placeholder={"Sélectionner un segment"}
          optionFilterProp="children"
          onChange={e => this.setState(
            {values: {...this.state.values, segment: e}})}
        >
          {segments.map((segment, index) =>
            <Select.Option key={index}
                           value={segment["id"]}
            >
              {segment.label}
            </Select.Option>)}
        </Select>,
        options: {
          rules: [{required: true, message: "Le segment est obligatoire"}],
        },
      },
      {
        label: "Date d'échéance",
        type: "date",
        key: "date_echeance",
        flex: "33%",
        input: <DatePicker format={"DD/MM/YYYY"}
                           style={{width: "100%"}}
                           placeholder="Date d'échéance"
                           onChange={(date) => this.setState(
                               {values: {...this.state.values, date_echeance: date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null}})
                           }
        />,
      },
    ];

    return <CpEditForm values={values}
                       fields={fields}
                       formRef={formRef}
                       onFieldsChange={onFieldsChange}
                       setRequiredFields={setRequiredFields}/>;
  }
}

export default withRouter((props) => <FormCompteur {...props}/>);
