import React from "react";
import {Col, Layout, List, Row} from "antd";
import {Link, withRouter} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {DataTypeEnum} from "../../components/common/enums/DataTypeEnum";
import CpListHeader from "../../components/common/list/CpListHeader";
import CpTitle from "../../components/common/CpTitle";
import {CpIconPhone, CpIconUser} from "../../components/common/CpIcon";
import CpText from "../../components/common/CpText";
import CpHeadCapsule from "../../components/common/CpHeadCapsule";
import variables from "../../stylesheets/tools/variables";
import CpCoproDeadline from "../../components/copro/CpCoproDeadline";
import styled from "styled-components";
import lang from "../../lang/fr"
import BreadCrumbPE from "../../components/common/BreadCrumbPE";

const StyledCoproEchues = styled.div`
  position: absolute;
  font-size: 85%;
  font-style: italic;
  font-weight: 550;
  width: 100%;
  top: 85%;
  color: #8c8c8c;
`;

@inject("dictionary", "detail", "user")
@observer
class AgencyDetail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {userIri: null};

    //instance type for detail
    this.props.detail.setType(DataTypeEnum.AGENCY);
  }

  componentDidMount() {
    const {agencyId} = this.props;
    this.props.detail.reset({head: false, list: false, footer: false}).
      load(agencyId);
    const {user} = this.props.user;
    this.setState({userIri: "/api/users/" + user.id});
  }

  render() {

    const {item, inProgress} = this.props.detail;

    if (!item || inProgress) return null;
    const listeNavigation = [
      {
        titre: item.name,
        href: `/agency`
      },
      {
        titre: `Gestionnaires`
      }
    ];
    return (
      <Layout>
        {/* Main Informations & Comments */}
        <BreadCrumbPE listeNavigation={listeNavigation}/>
        <Row align="middle">
          <CpTitle level={1}>
            <span style={{verticalAlign: "middle"}}>
              {item.name}
            </span>
          </CpTitle>
        </Row>

        <CpHeadCapsule style={{
          borderColor: variables.grey4,
          width: "80%",
          margin: "0 auto 20px auto",
        }}>
          <Row align={"top"}>
            <Col span={12}>
              <CpTitle level={5}>{lang.agence.detail.adresse}</CpTitle>
              <CpText>{item.qualifiedAddress}</CpText>
            </Col>
            <Col span={12}>
              <CpTitle level={5}>{lang.agence.detail.siren}</CpTitle>
              <div>{item.siret || <i>-</i>}</div>
            </Col>
          </Row>
        </CpHeadCapsule>

        <List
          header={
            <CpListHeader
              icon={<CpIconUser/>}
              title={lang.agence.detail.gestionnaire}
              count={item.managers ? item.managers.length : 0}
            />
          }
          bordered={false}
          dataSource={item?.managers ?? []}
          renderItem={item => (
            <Link to={`manager/${item.id}`}>
              <List.Item>
                <List.Item.Meta
                  description={
                    <>
                      <CpTitle level={4}>{`${item.nom} ${item.prénom}`}</CpTitle>
                      {item.estDirecteur ?
                        <CpTitle level={5}>{lang.agence.detail.directeur}</CpTitle> :
                        null}
                    </>
                  }
                />
                <List.Item.Meta
                  description={<>{item.email}</>}
                />
                <List.Item.Meta
                  style={{maxWidth: "210px"}}
                  description={
                    (item.fixe || item.mobile) &&
                    <div>
                      <CpIconPhone size={30} style={{
                        float: "left",
                        marginRight: 10,
                        padding: 7,
                      }}/>
                      <div style={{display: "inline-block"}}>
                        {item.fixe ? <div
                          style={{
                            lineHeight: item.mobile ?
                              "22px" :
                              "44px",
                          }}> {item.fixe}</div> : null}
                        {item.mobile ? <div
                          style={{
                            lineHeight: item.fixe ?
                              "22px" :
                              "44px",
                          }}> {item.mobile}</div> : null}
                      </div>
                    </div>
                  }
                />
                <List.Item.Meta
                  style={{maxWidth: "200px"}}
                  title={<>{lang.agence.detail.nbCopros}</>}
                  description={
                    <div style={{position: "relative"}}>
                      <CpCoproDeadline
                        total={item.totalCopropriétés}
                        deadlines={item.echeancesCopros ?? {}}
                      />
                      {item.nbCoprosEchues < 1 ?
                        "" :
                        <StyledCoproEchues>{item.nbCoprosEchues} {lang.agence.copro}{item.nbCoprosEchues >
                        1 ? "s" : ""} {lang.agence.echue}{item.nbCoprosEchues > 1 ?
                          "s" :
                          ""}</StyledCoproEchues>}
                    </div>
                  }
                />
              </List.Item>
            </Link>
          )}
        />
      </Layout>

    );
  }
}

export default withRouter(AgencyDetail);
