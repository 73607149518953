import React from "react";
import PropTypes from "prop-types";
import CpTypeCompteurFormat from "../CpTypeCompteurFormat";
import CpTitle from "../CpTitle";
import CpModal from "../CpModal";
import Documents from "../../document/Documents";
import CpButton from "../buttons/CpButton";
import DataService from "../../../services/dataService";
import {DataTypeEnum} from "../enums/DataTypeEnum";

class CpModalDocument extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      compteur: {},
    };

    //Bindings
    this.modalOpen = this.modalOpen.bind(this);
    this.modalClose = this.modalClose.bind(this);
  }

  modalOpen() {
    const {compteurId} = this.props;
    DataService.getById(DataTypeEnum.COMPTEUR, compteurId).then(({data}) => {
      this.setState({compteur: data, visible: true});
    });
  }

  modalClose() {
    const {onModalClose} = this.props;
    const {compteur} = this.state;

    if (onModalClose) {
      onModalClose(compteur.id);
    }

    this.setState({visible: false});
  }

  render() {
    const {compteur, visible} = this.state;
    const {title, buttonProps} = this.props;

    return (
      <>
        <CpButton onClick={this.modalOpen} {...buttonProps}>{title}</CpButton>

        <CpModal
          title={<>
            <CpTypeCompteurFormat type={compteur.type} style={{fontSize: 22}}/>
            <CpTitle level={4}>{compteur.pdl}</CpTitle>
            <>
              {compteur.copropriete?.nom ? ` - ${compteur.copropriete.nom}` : ""}
            </>
          </>}
          size={"l"}
          okText="Valider"
          modalClose={this.modalClose}
          visible={visible}
        >
          <Documents key={compteur.id} compteur={compteur}
                     dataSource={compteur.documents ?? []}
                     hideDeleteButton={this.props.hideDeleteButton}/>
        </CpModal>
      </>
    );
  }
}

Documents.propTypes = {
  hideDeleteButton: PropTypes.bool,
  // compteurId: PropTypes.number.isRequired
};
Documents.defaultProps = {
  hideDeleteButton: false,
};

export default CpModalDocument;
