import React, { useState, useEffect } from "react";
import { Select } from "antd";

// Variable globale pour les valeurs des options d'échéance
const optionsEcheances = [
    { value: "CLEAR", label: "Pas d'échéance" },
    { value: 3, label: "3 mois" },
    { value: 6, label: "6 mois" },
    { value: 12, label: "12 mois" },
];

function CpFiltreEcheance({ onFilterChange }) {
    const [echeanceSelectionnee, setEcheanceSelectionnee] = useState(null);

    // Effet pour gérer la mise à jour de l'échéance sélectionnée
    useEffect(() => {
        onFilterChange({ date_echeance: echeanceSelectionnee });
    }, [echeanceSelectionnee, onFilterChange]);

    const onSelectEcheance = (echeance) => {
        setEcheanceSelectionnee(echeance !== "CLEAR" ? echeance : null);
    };

    return (
        <Select
            placeholder="Filtrer par échéance"
            onChange={onSelectEcheance}
            style={{ width: "250px", marginLeft: "5px", marginRight: "5px" }}
            allowClear
            value={echeanceSelectionnee ? `${echeanceSelectionnee} mois` : null}
        >
            {optionsEcheances.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                    {option.label}
                </Select.Option>
            ))}
        </Select>
    );
}

export default CpFiltreEcheance;
