import DataService from "../../services/dataService";

export function recupererListeCompteurs(url, id){
try {
        return DataService[url]("COMPTEUR", id ?? id).then(({data}) => {
            return data;
           });
 } catch (error) {
            console.error("Erreur lors du chargement des compteurs:", error);
        }
}
