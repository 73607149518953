import styled from "styled-components";
import variables from "../../stylesheets/tools/variables";

const CpText = styled.span`
    color: ${props => {
  if (props.type == "danger") return variables.textHighlightDanger;
  if (props.type == "warning") return variables.textHighlightWarning;
  if (props.type == "success") return variables.textHighlightSuccess;
  if (props.type == "gray") return "#888888";
}};
    font-weight: ${props => props.bold ? "bold" : "normal"};
`;

export default CpText;
