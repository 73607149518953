import React from "react";
import {inject, observer} from "mobx-react";
import CpEditForm from "../CpEditForm";
import CpAjaxSelect from "../CpAjaxSelect";
import endpointEnum from "../../../services/endpointEnum";

@inject("dictionary")
@observer
class FormCommercial extends React.Component {

  constructor(props) {
    super(props);

    const {agencyId} = props.values;

    this.fields = [
      {
        label: "Sélectionner un commercial",
        flex: "60%",
        key: "commercial",
        type: "multipleEntity",
        input: <CpAjaxSelect
          endPoint={endpointEnum.COMMERCIAL_AJOUT(agencyId)}
          labelField="qualifiedName"
          searchField="qualifiedName"
          dataSource={[]}
        />,
        options: {
          rules: [{required: false}],
        },
      },
    ];
  }

  render() {
    return <CpEditForm fields={this.fields} {...this.props}/>;
  }
}

export default (props) => <FormCommercial {...props}/>;
