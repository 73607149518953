import React, {useRef, useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Button, notification} from "antd";
import FormCompteurSimple from "./FormCompteurSimple";
import QuestionFormulaire from "../common/QuestionFormulaire";
import RechercheCopro from "../copro/RechercheCopro";
import DataService from "../../services/dataService";
import {DataTypeEnum} from "../common/enums/DataTypeEnum";
import FormCopro from "../copro/FormCopro";
import detail from "../../stores/detail";
import TransactionSelectManager from "../transaction/TransactionSelectManager";
import moment from "moment";


function CreationCompteurSimple(props) {
  let {visible, onCancel, recupererCompteurs} = props;
  const [managerId, setManagerId] = useState();
  const [agencyId, setAgencyId] = useState();
  const [current, setCurrent] = useState(0);
  const [disabledNext, setDisabledNext] = useState(true);
  const [disabledBefore, setDisabledBefore] = useState(true);
  const [gestionnaire, setGestionnaire] = useState();
  const [gestionnairesListe, setGestionnairesListe] = useState([]);
  const [coproIdSelectionne, setCoproIdSelectionne] = useState();
  const [pdl, setPdl] = useState("");
  const [form, setForm] = useState({});
  const [formCompteur, setFormCompteur] = useState({});
  const [formRempli, setFormRempli] = useState({});
  const formRef = useRef();


  useEffect(() => {
    detail?.type === DataTypeEnum.AGENCY && listeGestionnaire(detail.item.id);
    if (detail?.type === DataTypeEnum.AGENCY) {
      setAgencyId(detail.item.id);
    } else {
      setAgencyId(detail.item.agency.id);
      setManagerId(props.match.params.id);
    }
  }, [disabledNext, disabledBefore, pdl, formCompteur, formRempli]);

  useEffect(() => () => {
    if (visible) {
      onFermerForm();
    }
  }, [visible]);


// -- handle template
  const onChange = (current) => {
    setCurrent(current);
  };

  const listeGestionnaire = (agencyId) => {
    DataService.getById(DataTypeEnum.AGENCY, agencyId).then(({data}) => {
      setGestionnairesListe(data.managers);
    });
  };
  const actionCreationCoproAuto = (isCreationAuto) => {
    const formCopro = {...form, agence: agencyId, gestionnaire: gestionnaire ? gestionnaire : managerId};
    if (isCreationAuto) {
      DataService.create(DataTypeEnum.COPRO, formCopro).then(({data}) => {
        const idCopro = data.id;
        const compteur = {...formCompteur, copropriete: idCopro};
        DataService.create(DataTypeEnum.COMPTEUR, compteur).then((data) => {
          if (data.status === 200) {
            notification.success({
              message: "Le compteur a bien été ajouté.",
            });
            onFermerForm();
          }
        }).finally(() => {
            return recupererCompteurs()
        });
      });
    } else {
      goNext();
    }
  };
  const getTemplateCoproForm = (isCoproExistante) => {
    goNext(isCoproExistante ? 3 : 4);
  };

  const rechercheCopro = (coproIdSelectionne) => {
    setCoproIdSelectionne(coproIdSelectionne);
  };
  const creationCompteurDepuisCoproExistante = () => {
    const compteur = {...formCompteur, copropriete: coproIdSelectionne};
    DataService.create(DataTypeEnum.COMPTEUR, compteur).then((data) => {
      if (data.status === 200) {
        notification.success({
          message: "Le compteur a bien été ajouté.",
        });
        onFermerForm();
      }
    });
  };

  const validerFormCoproParent = (form) => {
    form.agence = agencyId;
    form.gestionnaire = managerId ? managerId : gestionnaire;
    DataService.create(DataTypeEnum.COPRO, form).then(({data}) => {
      const idCopro = data.id;
      const compteur = {...formCompteur, copropriete: idCopro};
      DataService.create(DataTypeEnum.COMPTEUR, compteur).then((data) => {
        if (data.status === 200) {
          notification.success({
            message: "Le compteur a bien été ajouté.",
          });
          onFermerForm();
        }
      });
    });
  };

  const goNext = (step) => {
    setDisabledBefore(false);
    let next = 0;
    if (step !== undefined) {
      next = step;
    } else {
      if (current === 2 || current === 3) {
        setDisabledNext(true);
        setDisabledBefore(true);
      } else {
        setDisabledNext(next > 2);
      }
      next = current + 1;
    }
    onChange(next);
  };

  const validerFormParent = (form) => {
    setForm({
      nom: form?.nom,
      rue: form.rue,
      code_postal: form.code_postal,
      ville: form.ville
    });
    setFormCompteur({
      date_echeance: form.date_echeance ? moment(form.date_echeance).format('YYYY-MM-DD HH:mm:ss') : null,
      pdl: form.pdl,
      segment: form.segment,
      fournisseur: form.fournisseur,
      type: form.type,
    });
    setFormRempli(form);
    goNext();
  };

  const onFermerForm = () => {
    formRef.current?.resetFields();
    setFormRempli({});
    setDisabledNext(true);
    setDisabledBefore(true);
    setCurrent(0);
    visible = false;
    onCancel();
  };
  const recuperationPDL = async (value) => {
    return setPdl(value);
  };

  const onSelectGestionnaire = (gestionnaire) => {
    setGestionnaire(gestionnaire.key);
    setDisabledNext(false);
    setDisabledBefore(true);

  };

  const goBack = () => {
    setDisabledNext(false);
    let back = 0;
    if (current === 0) {
      back = 0;
      setDisabledBefore(true);
    } else if (current === 3 || current === 4) {
      back = 2;
    } else {
      back = current - 1;
      setDisabledBefore(back === 0);
    }
    onChange(back);
  };

  // -- fully managed components
  const getTemplate = () => {
    const templates = gestionnairesListe.length > 0 && detail?.type === DataTypeEnum.AGENCY ? {
        0: <TransactionSelectManager
          title="Sélection du gestionnaire"
          gestionnaires={gestionnairesListe}
          onChangeHandler={onSelectGestionnaire}
          onFinish={() => goNext()}
          valid={!!(gestionnaire)}
          AfficherButton={false}
        />,
        1: <FormCompteurSimple ajoutPdlParent={recuperationPDL}
                               ValiderFormParent={validerFormParent}
                               formRempli={formRempli ? formRempli : {}}
                               formRef={formRef}
                               visible={visible}
        />,
        2:
          <QuestionFormulaire
            key={current}
            question="Est-ce aussi l'adresse de la copropriété ?"
            valid={true}
            actionChoix={actionCreationCoproAuto}
            buttonPrecedent={
              <Button
                onClick={goBack}
                type="secondary"
                disabled={disabledBefore}
              >
                Précédent
              </Button>}
          />,
        3:
          <QuestionFormulaire
            key={current}
            question="Souhaitez-vous associer le compteur à une copro existante ?"
            valid={true}
            actionChoix={getTemplateCoproForm}
            buttonPrecedent={
              <Button
                onClick={goBack}
                type="secondary"
                disabled={disabledBefore}
              >
                Précédent
              </Button>}
          />,
        4:
          <RechercheCopro
            key={current}
            managerId={managerId}
            onRechercheCopro={rechercheCopro}
            onValider={creationCompteurDepuisCoproExistante}
            buttonPrecedent={
              <Button
                onClick={goBack}
                type="secondary"
                disabled={disabledBefore}
                justify={"start"} style={{marginTop: "15px", float: "left"}}
              >
                Précédent
              </Button>
            }
          />,
        5:
          <><FormCopro validerFormCoproParent={validerFormCoproParent} buttonPrecedent={
            <Button
              onClick={goBack}
              type="secondary"
              disabled={disabledBefore}
              justify={"start"} style={{marginTop: "15px"}}
            >
              Précédent
            </Button>
          }/></>,
      }
                                                                                            :
      {
        0: <FormCompteurSimple ajoutPdlParent={recuperationPDL}
                               ValiderFormParent={validerFormParent}
                               formRempli={formRempli ? formRempli : {}}
                               onCancel={onCancel}
                               formRef={formRef}
                               visible={visible}
        />,
        1:
          <QuestionFormulaire
            key={current}
            question="Est-ce aussi l'adresse de la copropriété ?"
            valid={true}
            actionChoix={actionCreationCoproAuto}
            buttonPrecedent={
              <Button
                onClick={goBack}
                type="secondary"
                disabled={disabledBefore}
              >
                Précédent
              </Button>}
          />,
        2:
          <QuestionFormulaire
            key={current}
            question="Souhaitez-vous associer le compteur à une copro existante ?"
            valid={true}
            actionChoix={getTemplateCoproForm}
            buttonPrecedent={
              <Button
                onClick={goBack}
                type="secondary"
                disabled={disabledBefore}
              >
                Précédent
              </Button>}
          />,
        3:
          <RechercheCopro
            key={current}
            managerId={managerId}
            onRechercheCopro={rechercheCopro}
            onValider={creationCompteurDepuisCoproExistante}
            buttonPrecedent={
              <Button
                onClick={goBack}
                type="secondary"
                disabled={disabledBefore}
                justify={"start"} style={{marginTop: "15px", float: "left"}}
              >
                Précédent
              </Button>
            }
          />,
        4: <><FormCopro validerFormCoproParent={validerFormCoproParent} buttonPrecedent={
          <Button
            onClick={goBack}
            type="secondary"
            disabled={disabledBefore}
            justify={"start"} style={{marginTop: "15px", float: "left"}}
          >
            Précédent
          </Button>
        }/></>,
      };
    return templates[current];
  };

  return (
    <div style={{marginTop: 15}}>
      {getTemplate()}
    </div>
  );
}

export default withRouter(CreationCompteurSimple);
