import styled from "styled-components";
import variables from "../../stylesheets/tools/variables";

const CpHeadCapsule = styled.div`
    background: ${variables.white};
    border: 1px solid ${variables.waterGreenPrimary};
    border-radius: ${variables.borderRadius};
    padding: 8px 20px;
    font-size: 12px;
    margin-bottom: 24px;

    .anticon{
        font-size: 30px;
    }
`;

export default CpHeadCapsule;
