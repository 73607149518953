import React from "react";
import {Button, Checkbox, Tooltip, Col, List, Row, Typography} from "antd";
import propTypes from "prop-types";
import CpTypeCompteurFormat from "../common/CpTypeCompteurFormat";
import CpTitle from "../common/CpTitle";
import CpIconTarif from "../common/CpIconTarif";
import CpProvider from "../common/CpProvider";
import CpCompteurDeadline from "../compteur/CpCompteurDeadline";
import variables from "../../stylesheets/tools/variables";

const CpTransactionSelectCompteur = (props) => {

  const {onChangeHandler, onChangeAllHandler, title, onFinish, onBack, list, valid, compteurSelected, indeterminate} = props;
  const {Title} = Typography;
  return (
    <>
      <Row justify={"center"}>
        <Typography>
          <Title level={4}>{title}</Title>
        </Typography>
      </Row>
      <Row justify={"center"} gutter={24} style={{marginTop: 16}}>
        <Col span={20}>
          {list.length > 1 ?
            <Tooltip title="Tout sélectionner">
              <Checkbox checked={list.length === compteurSelected.length} indeterminate={indeterminate} onChange={() => onChangeAllHandler()} style={{margin:11}}/>
            </Tooltip> : null
          }

          <List
            key={"keyList"}
            bordered={false}
            dataSource={list}
            renderItem={compteur => {
              return <List.Item>
                <List.Item.Meta
                  description={<>
                    <Checkbox checked={compteurSelected.includes(compteur.id) || list.length === compteurSelected.length} onChange={() => onChangeHandler(compteur.id)}/>
                  </>}
                  style={{flex: "0 0 50px"}}
                />
                <List.Item.Meta
                  title={
                    <CpTitle level={4}>
                      <CpTypeCompteurFormat
                        type={compteur.type}
                        style={{
                          fontSize: 22,
                          position: "relative",
                          top: 2,
                        }}
                      />
                      {compteur.number ?? compteur.pdl}
                    </CpTitle>}
                  description={<div style={{textTransform: "uppercase"}}><span
                    style={{color: variables.waterGreenPrimary}}>{compteur.copro_name ?? compteur.copropriete?.nom}</span> {compteur.copro_address ?? compteur.copropriete?.adresse}</div>}
                />
                <List.Item.Meta
                  title={<>Tarif</>}
                  description={<>
                    <CpIconTarif type={compteur.label ?? compteur.segment}/>
                    {compteur.label ?? compteur.segment}
                  </>}
                  style={{flex: "0 0 50px"}}
                />
                <List.Item.Meta
                  description={
                    <CpProvider
                      name={compteur.name ?? compteur.fournisseur?.nom}
                      logo={compteur.logo ?? compteur.fournisseur?.logo}
                      style={{height: 40}}
                    />}
                  style={{textAlign: "center"}}
                />
                <List.Item.Meta
                  title={<>Date d'échéance</>}
                  description={<CpCompteurDeadline
                    date_end={compteur.date_end ?? compteur.date_echeance}/>}
                />
              </List.Item>;
            }}
          />
        </Col>
      </Row>
      <Row justify={"space-between"} style={{marginTop: "15px"}}>
        <Button onClick={onBack} type="secondary">
          Précédent
        </Button>
        <Button onClick={onFinish} type="primary" disabled={!valid}>
          Suivant
        </Button>
      </Row>
    </>

  );
};

CpTransactionSelectCompteur.propTypes = {
  valid: propTypes.bool,
  onChangeHandler: propTypes.func.isRequired,
  onChangeAllHandler: propTypes.func.isRequired,
};

export default (props) => <CpTransactionSelectCompteur {...props} />
