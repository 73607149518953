import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Moment from "moment";
import DataService from "../../../services/dataService";

export function formEdit(WrappedComponent) {
  class formEditClass extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        disabled: false,
        requiredFields: [],
      };

      this.formRef = React.createRef();

      this.handleSubmit = this.handleSubmit.bind(this);
      this.afterSubmit = this.afterSubmit.bind(this);
      this.onFieldsChange = this.onFieldsChange.bind(this);
      this.setRequiredFields = this.setRequiredFields.bind(this);
    }

    setRequiredFields(requiredFields) {
      this.setState({requiredFields}, () => this.onFieldsChange());
    }

    onFieldsChange() {
      const {requiredFields} = this.state;

      let disabled = !this.formRef.current.isFieldsTouched(requiredFields,
        true) || this.formRef.current.getFieldsError().
        filter(({errors}) => errors.length).length ? true : false;
      this.setState({disabled});
    }

    /**
     * Callback called for check rules of every field and do a put/post request
     */
    handleSubmit(redirect = false) {
      this.formRef.current.validateFields().then(newValues => {
        const {values = {}, endPoint, mobxFunc = null, useId=null, dataType} = this.props;
        const updatedValues = _.chain({...values}).
          extend(newValues).
          mapValues((value) => {
            if (value instanceof Moment) return value.format("YYYY-MM-DD");
            else if (value === undefined) return null;
            else return value;
          }).
          omitBy((v, k) => {
            //Ignore objects
            if (_.isObject(v) && !_.isArray(v)) return true;
            //Ignore Arrays that we do not manage
            if (_.isArray(v) && newValues[k] === undefined) return true;
            //Accept everything else
            return false;
          }).
          value();

        if (mobxFunc) {
          if (this.props.closeModalBeforeMobxFunc) {
            this.props.modalClose();
          }
          mobxFunc(updatedValues).then(() => this.afterSubmit());
        } else if(dataType) {
          const promise = updatedValues.id ? DataService.edit(dataType, updatedValues) : DataService.create(dataType, updatedValues);
          promise.then(({data}) => this.afterSubmit(newValues, data, redirect));
        } else {
          if (values.id && !useId) {
            DataService.putFormEdit(endPoint, values.id, updatedValues).
              then(({data}) => this.afterSubmit(newValues, data, redirect));
          } else if (useId) {
            delete updatedValues.id;
            DataService.putFormEdit(endPoint, useId, updatedValues).
              then(({data}) => this.afterSubmit(newValues, data, redirect));
          } else {
            DataService.postFormEdit(endPoint, updatedValues).
              then(({data}) => this.afterSubmit(newValues, data, redirect));
          }
        }
      });
    }

    /**
     * After submit, use callback if any, then close
     * @param newValues
     * @param returnedValues
     */
    afterSubmit(newValues, returnedValues, redirect) {
      const {modalClose = null, onSubmit} = this.props;

      //cpModalEdit callback
      if (modalClose) {
        modalClose();
      }

      //send values to onSubmit callback
      if (onSubmit) {
        const values = _.chain(newValues).
          omitBy((v) => v !== null).
          extend(returnedValues).
          value();

        onSubmit(values, redirect);
      }
    }

    render() {
      return (
        <WrappedComponent formRef={this.formRef}
                          handleSubmit={this.handleSubmit}
                          setRequiredFields={this.setRequiredFields}
                          afterSubmit={this.afterSubmit}
                          onFieldsChange={this.onFieldsChange} {...this.state} {...this.props} />
      );
    }
  }

  formEditClass.propTypes = {
    endPoint: PropTypes.string, //for put or post request
    mobxFunc: PropTypes.func, //for work with mobx store
    onSubmit: PropTypes.func, //called in afterSubmit() if defined in childComponent
    modalClose: PropTypes.func, //@see: cpModalEdit
    useId: PropTypes.number
  };

  return formEditClass;
}
