export default {
  oui: "Oui",
  non: "Non",
  dont: "dont",
  le: "le",
  inactif: "inactif",
  filtrer: "filtrer",
  switch: {
    compteurs: "Vue Compteurs",
    agences: "Vue Agences",
    gestionnaires: "Vue Gestionnaires",
    copropriete: "Vue Copropriétés",
  },
  echoirDans: "à échoir dans",
  moinsUn: "moins d'un",
  mois: "mois",
  aucune: "Aucune",
  header: {
    aide: "Aide",
    contactCourtier: "Contacter mon courtier",
    utilisateurs: "Utilisateurs",
    deconnexion: "Se déconnecter",
  },
  login:{
    titrePage: "Identification",
  },
  titreNavigateur: {
    defaut: "Pilote Energie",
  },
  form:{
    warningTitle: "Informations manquantes",
    annuler: "Annuler",
    validerEt: "Valider et",
    valider: "Valider",
    modifier: "Modifier",
    modifierPar: "Modifié par",
    supprimer: "Supprimer",
    btnUpdate: "Update Compteurs",
    modification: "Modification",
    afficher: "Afficher",
    masquer: "Masquer"
  },
  magicSearch:{
    placeholder: "Nom, Adresse, Compteur, Siret...",
    siret: "SIRET",
    },
  detail:{
    tooltipVoirEntrepriseHS: "Voir entreprise dans HubSpot",
  },
};
