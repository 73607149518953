import React from "react";
import Icon from "@ant-design/icons";

import SvgAdd from "../../stylesheets/icons/add.svg";
import SvgAffaires from "../../stylesheets/icons/affaires.svg";
import SvgAgence from "../../stylesheets/icons/agence.svg";
import SvgCheck from "../../stylesheets/icons/check.svg";
import SvgCommentaire from "../../stylesheets/icons/commentaire_2.svg";
import SvgCompteur from "../../stylesheets/icons/compteur.svg";
import SvgConsoElec from "../../stylesheets/icons/conso-elec.svg";
import SvgConsoGaz from "../../stylesheets/icons/conso-gaz.svg";
import SvgContrats from "../../stylesheets/icons/contrats.svg";
import SvgCopro from "../../stylesheets/icons/copro.svg";
import SvgDelete from "../../stylesheets/icons/delete_2.svg";
import SvgDetail from "../../stylesheets/icons/detail.svg";
import SvgDown from "../../stylesheets/icons/down.svg";
import SvgElec from "../../stylesheets/icons/elec.svg";
import SvgFermer from "../../stylesheets/icons/fermer.svg";
import SvgGaz from "../../stylesheets/icons/gaz.svg";
import SvgHubspot from "../../stylesheets/icons/hubspot.svg";
import SvgInfo from "../../stylesheets/icons/info.svg";
import SvgLocation from "../../stylesheets/icons/location.svg";
import SvgMail from "../../stylesheets/icons/mail.svg";
import SvgPasCheck from "../../stylesheets/icons/pas-check.svg";
import SvgPhone from "../../stylesheets/icons/phone.svg";
import SvgReload from "../../stylesheets/icons/reload.svg";
import SvgSearch from "../../stylesheets/icons/search_2.svg";
import SvgTicket from "../../stylesheets/icons/ticket.svg";
import SvgUser from "../../stylesheets/icons/user.svg";
import SvgImporter from "../../stylesheets/icons/importer.svg";
import SvgWarning from "../../stylesheets/icons/warning.svg";
import SvgEuro from "../../stylesheets/icons/euro.svg";
import SvgMaestro from "../../stylesheets/icons/maestro.svg";

const CpIcon = ({size, style = {}, ...rest}) => {
  const finalStyle = style;
  if (size) {
    finalStyle.fontSize = size;
  }
  return <Icon style={finalStyle} {...rest}/>;
};

const CpIconAdd = props => <CpIcon className="cp-icon"
                                   component={SvgAdd} {...props}/>;
const CpIconAffaires = props => <CpIcon className="cp-icon"
                                        component={SvgAffaires} {...props}/>;
const CpIconAgence = props => <CpIcon className="cp-icon"
                                      component={SvgAgence} {...props}/>;
const CpIconCheck = props => <CpIcon className="cp-icon"
                                     component={SvgCheck} {...props}/>;
const CpIconCommentaire = props => <CpIcon className="cp-icon"
                                           component={SvgCommentaire} {...props}/>;
const CpIconCompteur = props => <CpIcon className="cp-icon"
                                        component={SvgCompteur} {...props}/>;
const CpIconConsoElec = props => <CpIcon className="cp-icon"
                                         component={SvgConsoElec} {...props}/>;
const CpIconConsoGaz = props => <CpIcon className="cp-icon"
                                        component={SvgConsoGaz} {...props}/>;
const CpIconContrats = props => <CpIcon className="cp-icon"
                                        component={SvgContrats} {...props}/>;
const CpIconCopro = props => <CpIcon className="cp-icon"
                                     component={SvgCopro} {...props}/>;
const CpIconDelete = props => <CpIcon className="cp-icon"
                                      component={SvgDelete} {...props}/>;
const CpIconDetail = props => <CpIcon className="cp-icon"
                                      component={SvgDetail} {...props}/>;
const CpIconDown = props => <CpIcon className="cp-icon"
                                    component={SvgDown} {...props}/>;
const CpIconElec = props => <CpIcon className="cp-icon"
                                    component={SvgElec} {...props}/>;
const CpIconFermer = props => <CpIcon className="cp-icon"
                                      component={SvgFermer} {...props}/>;
const CpIconGaz = props => <CpIcon className="cp-icon"
                                   component={SvgGaz} {...props}/>;
const CpIconHubspot = props => <CpIcon className="cp-icon"
                                       component={SvgHubspot} {...props}/>;
const CpIconInfo = props => <CpIcon className="cp-icon"
                                    component={SvgInfo} {...props}/>;
const CpIconLocation = props => <CpIcon className="cp-icon"
                                        component={SvgLocation} {...props}/>;
const CpIconMail = props => <CpIcon className="cp-icon"
                                    component={SvgMail} {...props}/>;
const CpIconPasCheck = props => <CpIcon className="cp-icon"
                                        component={SvgPasCheck} {...props}/>;
const CpIconPhone = props => <CpIcon className="cp-icon"
                                     component={SvgPhone} {...props}/>;
const CpIconReload = props => <CpIcon className="cp-icon"
                                      component={SvgReload} {...props}/>;
const CpIconSearch = props => <CpIcon className="cp-icon"
                                      component={SvgSearch} {...props}/>;
const CpIconTicket = props => <CpIcon className="cp-icon"
                                      component={SvgTicket} {...props}/>;
const CpIconUser = props => <CpIcon className="cp-icon"
                                    component={SvgUser} {...props}/>;
const CpIconWarning = props => <CpIcon className="cp-icon"
                                       component={SvgWarning} {...props}/>;
const CpIconImporter = props => <CpIcon className="cp-icon"
                                        component={SvgImporter} {...props}/>;
const CpIconEuro = props => <CpIcon className="cp-icon"
                                        component={SvgEuro} {...props}/>;
const CpMaestro = props => <CpIcon className="cp-icon"
                                    component={SvgMaestro} {...props}/>;

export {
  CpIconAdd,
  CpIconAffaires,
  CpIconAgence,
  CpIconCheck,
  CpIconCommentaire,
  CpIconCompteur,
  CpIconConsoElec,
  CpIconConsoGaz,
  CpIconContrats,
  CpIconCopro,
  CpIconDelete,
  CpIconDetail,
  CpIconDown,
  CpIconElec,
  CpIconFermer,
  CpIconGaz,
  CpIconHubspot,
  CpIconInfo,
  CpIconLocation,
  CpIconMail,
  CpIconPasCheck,
  CpIconPhone,
  CpIconReload,
  CpIconSearch,
  CpIconTicket,
  CpIconUser,
  CpIconWarning,
  CpIconImporter,
  CpIconEuro,
  CpMaestro,
};
