import React from "react";
import {Button, Col, Radio, Row, Typography} from "antd";
import propTypes from "prop-types";
import CpTypeCompteurFormat from "../common/CpTypeCompteurFormat";

const CpTransactionSelectEnergy = (props) => {
  const {depuisFormCompteur} = props;
  const {onChangeHandler, onFinish, title, valid, dealType, afficheBtn = true} = props;
  const {Title} = Typography;

  return (
    <div>
      {title &&
        <Row justify={"center"}>
          <Typography>
            <Title level={4}>{title}</Title>
          </Typography>
        </Row>
      }
      <Row justify={"center"}>
        <Col span={depuisFormCompteur && 24}>
          <Radio.Group value={dealType} size="large"
                       onChange={e => onChangeHandler(e.target.value)} style={{display: depuisFormCompteur && "flex"}}>
            <Radio.Button value="GAZ"
                          style={{width: depuisFormCompteur && "100%"}}>
              <CpTypeCompteurFormat type={"GAZ"}
                                    style={{fontSize: 22}}/>&nbsp;GAZ
            </Radio.Button>
            <Radio.Button value="ELEC"
                          style={{width: depuisFormCompteur && "100%"}}>
              <CpTypeCompteurFormat type={"ELEC"}
                                    style={{fontSize: 22}}/>&nbsp;Électricité
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      {afficheBtn &&
        <Row justify={"end"}>
          <Button onClick={onFinish} type="primary" disabled={!valid}>
            Suivant
          </Button>
        </Row>
      }
    </div>
  );
};

CpTransactionSelectEnergy.propTypes = {
  title: propTypes.string,
  valid: propTypes.bool,
  onChangeHandler: propTypes.func.isRequired,
};

export default (props) => <CpTransactionSelectEnergy {...props} />
