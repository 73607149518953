import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import AgencyList from "../../../containers/commercial/AgencyList";
import AgencyDetail from "../../../containers/commercial/AgencyDetail";
import ManagerDetail from "../../../containers/commercial/ManagerDetail";
import UserList from "../../../containers/admin/UserList";
import MaestroFactureForm from "../../maestro/MaestroFactureForm";
import MaestroUploadForm from "../../maestro/MaestroUploadForm";
import CompteursListe from "../../../containers/commercial/CompteursListe";

function CommercialAccessRoutes() {
  return (
    <Switch>
      <Route exact path="/agency" component={AgencyList}/>,
      <Route exact path="/compteur" component={CompteursListe}/>,
      <Route exact path="/gestionnaire/:id/maestro" component={MaestroUploadForm}/>,
      <Route exact path="/gestionnaire/:id/demandes-traitement-facture/:idDemande" component={MaestroFactureForm}/>,
      <Route exact path="/agency/:id/:tab/:create?" render={props =>
        <AgencyDetail
          key={props.match.params.id} {...props}/>}/>,

      <Route exact path={"/manager/:id/:tab/:create?/:coproId?"}
             render={props => <ManagerDetail
               key={props.match.params.id} {...props}/>}/>,
      <Route exact path="/admin/user" component={UserList}/>,
      <Route component={() => <Redirect to={"/agency"}/>}/>
    </Switch>
  );
}

export default CommercialAccessRoutes;
