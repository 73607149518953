import React from "react";
import CpModalEditContent from "./CpModalEditContent";
import {observer} from "mobx-react";

@observer
class CpModalEdit extends React.Component {
  constructor(props) {
    super(props);

    //Initial state
    this.state = {visible: false};

    //Bindings
    this.modalOpen = this.modalOpen.bind(this);
    this.modalClose = this.modalClose.bind(this);
  }

  modalOpen(e) {
    //Remove focus around buttons on click
    e.currentTarget.blur();
    if (this.props.onModalOpen) {
      this.props.onModalOpen(() => this.setState({visible: true}));
    } else {
      this.setState({visible: true});
    }
  }

  modalClose() {
    this.setState({visible: false});
    if (this.props.onModalClose) {
      this.props.onModalClose();
    }
  }

  render() {
    return <CpModalEditContent {...this.state} {...this.props}
                               modalOpen={this.modalOpen}
                               modalClose={this.modalClose}/>;
  }
}

export default CpModalEdit;
