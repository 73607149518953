import React from "react";
import PropTypes from "prop-types";
import CpModal from "../CpModal";
import CpButton from "../buttons/CpButton";
import FormBuildAccess from "../forms/FormBuildAccess";
import FormSendMailAccess from "../forms/FormSendMailAccess";
import {notification} from "antd";
import DataService from "../../../services/dataService";

class CpModalAccess extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      mails: [],
      agencyAccountAccess: props.agencyAccountAccess ?
        props.agencyAccountAccess :
        {},
    };

    //Bindings
    this.modalOpen = this.modalOpen.bind(this);
    this.modalClose = this.modalClose.bind(this);
    this.onValuesChange = this.onValuesChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.generateAccessSubmit = this.generateAccessSubmit.bind(this);
  }

  modalOpen() {
    this.setState({visible: true});
  }

  modalClose() {
    this.setState({visible: false, mails: []});
  }

  /**
   * Validate mail
   * @source: Code source of Chromium
   * @param email
   * @returns {boolean}
   */
  validateEmail(email) {
    /* eslint-disable-next-line */
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleSubmit() {
    const {mails, agencyAccountAccess} = this.state;
    const {agencyId} = this.props;

    if (!Object.keys(agencyAccountAccess).length) {
      notification.error({
        className: "error-server",
        message: "Vous devez avoir généré un accès avant de pouvoir l'envoyer.",
        duration: 3,
      });
      return;
    }

    if (mails.filter(x => !this.validateEmail(x)).length !== 0) {
      notification.error({
        className: "error-server",
        message: "Vous devez renseigner des adresses emails valides.",
        duration: 3,
      });
      return;
    }

    DataService.postAccount(agencyId, {mails}).then(({data}) => {
      switch (data.success) {
        case true:
          notification.success({
            message: "L'accès à bien été envoyés",
          });
          this.modalClose();
          break;
        default:
          break;
      }
    });
  }

  /**
   * Function for change login/password
   */
  generateAccessSubmit() {
    const {agencyId} = this.props;
    DataService.getAccess(agencyId).then(({data}) => {
      this.setState({agencyAccountAccess: data});
    });
  }

  /**
   * Function for setState mails
   * @param values
   * @param allValues
   */
  onValuesChange(values, allValues) {
    this.setState({
      mails: allValues.mails.length ?
        allValues.mails.split(";").map(mail => mail.replace(" ", "")) :
        [],
    });
  }

  render() {
    const {visible, agencyAccountAccess, mails} = this.state;
    const {buttonProps, children, title} = this.props;

    return (
      <>
        <CpButton onClick={this.modalOpen} {...buttonProps}>{children ?
          children :
          title}</CpButton>

        <CpModal
          disabled={!mails.length}
          title="Gérer les accès"
          okText="Envoyer"
          modalClose={this.modalClose}
          visible={visible}
          handleSubmit={this.handleSubmit}
        >
          <p>Les collabrateurs d'une agence (gestionnaire, comptable, directeur)
            peuvent accéder à Pilote
            Energie via le login et mot de passe suivant.</p>

          <p>Ils ont alors accès aux pages gestionnaires, copropriétés et
            compteurs associées à l'agence mais
            n'ont pas de bouton d'action.</p>


          <FormBuildAccess
            values={agencyAccountAccess ? agencyAccountAccess : {}}
            handleSubmit={this.generateAccessSubmit}
          />


          <p>Vous pouvez envoyer cet accès par email à vos contacts, en séparant
            les emails par le caractère
            ;</p>


          <FormSendMailAccess onValuesChange={this.onValuesChange}/>

        </CpModal>
      </>
    );
  }
}

CpModalAccess.propTypes = {
  agencyAccountAccess: PropTypes.object,
};

export default CpModalAccess;
