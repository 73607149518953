import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import {Layout, List, Input, Select, DatePicker, Form, Divider, Collapse} from "antd";
import {toUpper} from "lodash";
import {ZoomInOutlined, ZoomOutOutlined} from "@ant-design/icons";
import dictionary from "../../stores/dictionary";
import moment from 'moment';
import CpDeleteButton from "../common/buttons/CpDeleteButton";
import CpButton from "../common/buttons/CpButton";

function MaestroCpListCompteurs(props) {
  const {Panel} = Collapse;
  const factures = props.factures ? props.factures : "";
  const {segments} = props;
  const providers = dictionary.providers;
  const [type, setType] = useState(factures.energie === "electricite" ? "ELEC" : toUpper(factures.energie));
  const [fournisseur] = useState(factures.fournisseur);
  const dateEcheance = factures.date_echeance ? moment(factures.date_echeance, 'YYYY-MM-DD') : "";
  let [panelOuvert, setPanelOuvert] = useState(false);

  const onDeleteFacture = (factureId) => {
    props.evenementSuppressionToParent(factureId);
  };

  const ouverturePdf = () => {
    if (panelOuvert === true) {
      setPanelOuvert(false);
    } else {
      setPanelOuvert(true);
    }
    // Collapse interfère avec le scrollIntoView de façon aléatoire sur quelques cas. L'utilisation du setTimeout permet de régler ce soucis.
    setTimeout(() => document.getElementById(`${factures.id}_pdf`).scrollIntoView({
      behavior: 'smooth',
      block: "start"
    }), 200);
  };

  const pageFacture =
    <Layout id={`${factures.id}_pdf`}>
      <Divider orientation="left" plain style={{margin: "0px"}}>{factures.nom_fichier}
        {factures.documents !== null &&
          <CpButton onClick={ouverturePdf}
                    icon={panelOuvert === false ? <ZoomInOutlined/> : <ZoomOutOutlined/>}
                    style={{border: "none", marginLeft: "5px", background: "none"}}/>
        }
      </Divider>
      <List.Item style={{backgroundColor: "#fafafa"}}>
        <div>
          <Form.Item
            name={["factures", factures.id, "pdl"]}
            label="PDL"
            initialValue={factures.pdl}
            rules={[{
              required: true,
              message: "Merci de fournir un pdl",
            }]}
          >
            <Input name={["factures", factures.id, "pdl"]}/>
          </Form.Item>
        </div>
        <Form.Item
          name={["factures", factures.id, "fournisseur"]}
          label="Fournisseur"
          initialValue={fournisseur}
          rules={[{
            required: true,
            message: "Merci de fournir un fournisseur",
          }]}
          style={{display: "flex", width: "200px"}}
        >
          <Select
            name={["factures", factures.id, "fournisseur"]}
            placeholder="Fournisseur"
          >
            {providers.map((provider, i) => (
              <Select.Option key={i} value={provider["id"]}>{provider["nom"]}</Select.Option>
            ))
            }
          </Select>
        </Form.Item>
        <Form.Item
          name={["factures", factures.id, "energie"]}
          label="Type"
          initialValue={type}
          rules={[{
            required: true,
            message: "Merci de fournir un type d'énergie",
          }]}
          style={{width: "120px"}}
        >
          <Select
            name={["factures", factures.id, "type"]}
            placeholder="Type"
            onChange={(key) => setType(key)}
          >
            <Select.Option key="gaz" value="GAZ">Gaz</Select.Option>
            <Select.Option key="elec" value="ELEC">Electricité</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name={["factures", factures.id, "date_echeance"]}
          label="Echéance"
          initialValue={dateEcheance}
          style={{width: "130px"}}
        >
          <DatePicker format={"DD/MM/YYYY"}/>
        </Form.Item>
        <Form.Item
          name={["factures", factures.id, "segment"]}
          label="Segment"
          initialValue={factures.segment}
          rules={[{
            required: true,
            message: "Merci de fournir un segment",
          }]}>
          <Select
            placeholder="Segment"
            name={["factures", factures.id, "segment"]}
          >
            {segments.filter(seg => seg.type === type).map((segment, index) => (
              <Select.Option key={index} value={segment["label"]}>{segment["label"]}</Select.Option>
            ))
            }
          </Select>
        </Form.Item>
        <Form.Item>
          <CpDeleteButton
            buttonProps={{
              size: "small",
              style: {marginTop: "20px"}
            }}
            entityIri={`/api/facture/${factures.id}`}
            onDelete={() => onDeleteFacture(factures.id)}
          />
        </Form.Item>
      </List.Item>

      {factures.documents &&
        <Collapse activeKey={panelOuvert}>
          <Panel showArrow={false} key={true}>
            {factures?.documents.map((facture, key) => {
              return (
                <embed src={facture.url} key={key}
                       style={{width: "100%", height: "500px"}} type="application/pdf"/>
              );
            })
            }
          </Panel>
        </Collapse>
      }
    </Layout>;

  const contenu = () => {
    if (!Object.prototype.hasOwnProperty.call(factures, "pdl")) {
      return <></>;
    } else {
      return pageFacture;
    }
  };

  return (
    <Layout>
      {contenu()}
    </Layout>
  );
}

export default withRouter(MaestroCpListCompteurs);
