import React from "react";
import styled from "styled-components";
import {Col, Modal, Row} from "antd";
import PropTypes from "prop-types";
import CpButton from "./buttons/CpButton";
import {CpIconCheck} from "./CpIcon";
import {inject, observer} from "mobx-react";
import variables from "../../stylesheets/tools/variables";

const StyledModal = styled(Modal)`
    .ant-modal-title{
        font-weight: 700;
        display: flex;
        line-height: 30px;
        .anticon {
            margin-right: 10px;
        }
    }
    .ant-modal-close{
        text-align: right;
    }
    .ant-modal-close-x{
        width: 100px;

        &>div{
            display: inline-block;
            position: absolute;
            top: 16px;
            right: 16px;

            vertical-align: bottom;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 9px;

            height: 26px;
            width: 75px;
            line-height: 26px;

            padding: 0 6px;

            background-color: ${variables.grey10};
            border: 1px solid ${variables.grey4};
            border-radius: ${variables.borderRadius};
            color: ${variables.$grey8};
        }

        .anticon{
            position: relative;
            top: 2px;
            left: 4px;
        }
    }
`;

@inject("interface")
@observer
class CpModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      width: "520", //default antd API
    };
  }

  componentDidMount() {
    const {size = "s"} = this.props;

    //get width of modal from size xs, l, xl, m

    let width = "50%";
    switch (size) {
      case "xs":
        width = "30%";
        break;
      case "s":
        width = "40%";
        break;
      case "m":
        width = "55%";
        break;
      case "l":
        width = "75%";
        break;
      case "xl":
        width = "90%";
        break;
      default:
        return;
    }

    this.setState({width});
  }

  renderModifierBtn() {
    return (<CpButton
        type="principal"
        htmlType="submit"
        onClick={this.props.handleSubmit} disabled={this.props.disabled}>
          {this.props.okText}
      <CpIconCheck/>
    </CpButton>);
  }

  render() {
    const {
      title = "Modifier",
      modalClose,
      visible,
      children,
      footerComponents = [],
    } = this.props;

    const {width} = this.state;

    return (
      <StyledModal
        title={title}
        width={width}
        visible={visible}
        onCancel={modalClose}
        maskClosable={true}
        confirmLoading={this.props.interface.isLoading}
        destroyOnClose={true}
        footer={
          this.props.handleSubmit ?
            <Row align="top" justify="end">
              {[
                ...footerComponents,
                this.renderModifierBtn(),
              ].map((component, index) => <Col style={{marginLeft: "2px"}} key={index}>{component}</Col>)
              }
            </Row>
            : ""}
      >
        {children}
      </StyledModal>
    );
  }
}

CpModal.propTypes = {
  // title: PropTypes.string,
  handleSubmit: PropTypes.func,
  modalClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  okText: PropTypes.string,
  footerComponents: PropTypes.arrayOf(PropTypes.node),
  size: PropTypes.string, //xs, s, m, l, xl
};

CpModal.defaultProps = {
  okText: "Modifier"
};

export default CpModal;
