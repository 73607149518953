import React from "react";
import {formEdit} from "../hoc/FormEdit";
import PropTypes from "prop-types";
import CpButton from "../buttons/CpButton";
import {CpIconCheck} from "../CpIcon";
import {Modal} from "antd";
import lang from "../../../lang/fr"
import {withRouter} from 'react-router-dom';

class CpListHeaderEditForm extends React.Component {
  constructor(props) {
    super(props);

    //Instanciate our form, with ref
    const {
      form,
      values,
      formRef,
      onFieldsChange,
      setRequiredFields,
    } = this.props;
    this.formElement = form({values, formRef, onFieldsChange, setRequiredFields});
  }

  componentDidMount() {
    const {onFieldsChange} = this.props;

    if (onFieldsChange) {
      onFieldsChange();
    }
  }

  render() {
    const {
      handleSubmit,
      hideForm,
      disabled,
      onSubmitRedirect,
      customCheck,
    } = this.props;

    const beforeSubmit = (e, redirect = false) => {
      const notValidable = customCheck != null ?
        customCheck(this.formElement.props.formRef.current) :
        false;
      if (notValidable) {
        Modal.warning({
          title: lang.global.form.warningTitle,
          content: notValidable,
        });
      } else {
        handleSubmit(redirect);
      }
    };

    const annuler = () => {
      hideForm();
      if (this.formElement.props.cancelRedirect) {
        this.props.history.push(this.formElement.props.cancelRedirect);
      }
    }

    return (
      <>
        {this.formElement}

        <CpButton type="secondary" htmlType="button" onClick={annuler}>
          {lang.global.form.annuler}
        </CpButton>

        {onSubmitRedirect ?
          <CpButton type="principal"
                    htmlType="submit"
                    onClick={(e) => beforeSubmit(e, true)}
                    disabled={
                      disabled ||
                      (this.props.onSubmitRedirectConstraintsFields &&
                        this.props.onSubmitRedirectConstraintsFields.filter(
                          c => {
                            // -- I rewrite it a bit to let make it more lisible
                            const currentValue = this.props.formRef?.current?.getFieldsValue()[c];
                            return currentValue === undefined;
                          }).length)
                    }
                    style={{float: "right"}}>
            {lang.global.form.validerEt} {onSubmitRedirect} <CpIconCheck/>
          </CpButton>
          : null}

        <CpButton type="principal" htmlType="submit" onClick={beforeSubmit}
                  disabled={disabled}
                  style={{float: "right"}}>
          {lang.global.form.valider} <CpIconCheck/>
        </CpButton>
      </>
    );
  }
}

CpListHeaderEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  endPoint: PropTypes.string,
  form: PropTypes.func.isRequired,
  onSubmit: PropTypes.func, //callback called in formEdit afterSubmit()
  values: PropTypes.object,
  dataType: PropTypes.string,
};

export default withRouter(formEdit(CpListHeaderEditForm));
