import PropTypes from "prop-types";

const Capitalize = (valeur) => {
  return valeur.charAt(0).toUpperCase() + valeur.slice(1);
};
Capitalize.propTypes = {
  valeur: PropTypes.string.isRequired,
};

export default Capitalize;
