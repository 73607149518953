import React, {useState} from "react";
import CpAjaxSelect from "../common/CpAjaxSelect";
import {withRouter} from "react-router-dom";
import endpointEnum from "../../services/endpointEnum";
import lang from "../../lang/fr";
import {Button, Row, Layout} from "antd";

const RechercheCopro = (props) => {
  const {managerId, onRechercheCopro, onValider, buttonPrecedent} = props;
  const [selectedCoproValue, setSelectedCoproValue] = useState();

  const onSelectChange = (e) => {
    onRechercheCopro(e);
    setSelectedCoproValue(e);
  };
  const onConfirmer = () => {
    onValider();
  };

  return (
    <Layout style={{background: "none"}}>
      <CpAjaxSelect
        endPoint={endpointEnum.COPRO_ASSOCIEE("managers", managerId)}
        labelField={"name, qualifiedAddress"}
        searchField="qualifiedAddress"
        placeholder="Rechercher une copropriété..."
        selectedValue={selectedCoproValue}
        onChange={onSelectChange}
        dataSource={[]}
      />
      <Row justify={"space-between"} style={{marginTop: "15px"}}>
        {buttonPrecedent}
        {(onValider && selectedCoproValue) &&
          <Button htmlType="submit"
                  type="primary"
                  onClick={onConfirmer}
                  justify={"end"} style={{marginTop: "15px", float: "right"}}
          >
            {lang.global.form.valider}
          </Button>
        }
      </Row>
    </Layout>
  );
};

export default withRouter(RechercheCopro);
