import React from "react";
import PropTypes from "prop-types";

import {formEdit} from "../hoc/FormEdit";
import {inject, observer} from "mobx-react";
import CpButton from "../buttons/CpButton";
import CpModal from "../CpModal";

@inject("interface")
@observer
class CpModalEditContent extends React.Component {
  constructor(props) {
    super(props);

    //Instanciate our form, with ref
    const {form, values, formRef, onFieldsChange, setRequiredFields} = this.props;
    this.formElement = form({values, formRef, onFieldsChange, setRequiredFields});

  }

  componentDidUpdate() {
    const {form, values, formRef, onFieldsChange, setRequiredFields} = this.props;

    if (this.formElement && values && this.formElement.props.values !==
      values) {
      this.formElement = form({values, formRef, onFieldsChange, setRequiredFields});
    }
  }

  render() {
    const {
            buttonProps,
            modalOpen,
            children,
            ...rest
          } = this.props;

    return <span>
            <CpButton onClick={modalOpen} {...buttonProps}>{children ?
              children :
              this.props.title}</CpButton>

            <CpModal {...rest}>
                {this.formElement}
            </CpModal>
        </span>;
  }
}

CpModalEditContent.propTypes = {
  visible: PropTypes.bool.isRequired,
  modalClose: PropTypes.func.isRequired,
  modalOpen: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired, //formEdit HoC
  disabled: PropTypes.bool, //formEdit HoC
  okText: PropTypes.string,
  footerComponents: PropTypes.arrayOf(PropTypes.node),
};

CpModalEditContent.defaultProps = {
  buttonProps: {},
  title: "Modifier",
};

export default formEdit(CpModalEditContent);
