import React from "react";
import PropTypes from "prop-types";

const Pluralize = ({count, title, plural}) => {

  return (
    <>{count ? count : 0} {!count ?
      title :
      (count == 1 ? title : (plural ? plural : title + "s"))}</>
  );
};

Pluralize.propTypes = {
  count: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  plural: PropTypes.string,
};

export default Pluralize;
