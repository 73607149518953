import React from "react";
import {Input, Select} from "antd";
import {inject, observer} from "mobx-react";
import CpEditForm from "../CpEditForm";

@inject("dictionary")
@observer
class FormUser extends React.Component {

  constructor(props) {
    super(props);

    this.fields = [
      {
        label: "Nom",
        flex: "50%",
        key: "lastname",
        input: <Input placeholder="-" disabled/>,
      },
      {
        label: "Prénom",
        flex: "50%",
        key: "firstname",
        input: <Input placeholder="-" disabled/>,
      },
      {
        label: "Roles",
        key: "roles",
        input: <Select
          mode="multiple"
          placeholder="Please select"
        >
          <Select.Option value="ROLE_COMMERCIAL">COMMERCIAL</Select.Option>
          <Select.Option value="ROLE_ADMIN">ADMIN</Select.Option>
          <Select.Option value="ROLE_USER">UTILISATEUR</Select.Option>
        </Select>,
        options: {
          rules: [
            {
              required: true,
              message: "Ce champ est obligatoire",
            }],
        },
      },
    ];
  }

  render() {
    return <CpEditForm fields={this.fields} {...this.props}/>;
  }
}

export default (props) => <FormUser {...props}/>;
