import React from "react";
import {inject, observer} from "mobx-react";
import {Col, Typography} from "antd";
import {withRouter} from "react-router-dom";
import lang from "../lang/fr";

import LoginForm from "../components/login/LoginForm";
import DataService from "../services/dataService";

@inject("interface", "dictionary", "user")
@observer
class Login extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    //If we are connected, redirect to the home page
    DataService.getCheckSession().then(({data}) => {
      if (data) {
        if (this.props.user.isConnected) {
          this.props.history.push("/agency");
        }
      } else {
        if (window.location.pathname !== "/login") {
          this.props.history.push("/login");
        }
      }
    });
  }

  onSubmit({username, password}) {
    DataService.getLogin({username, password}).then(({data}) => {
      this.props.user.updateUser(data.user, data.agencyId);
      this.props.dictionary.load();
      this.props.history.push("/agency");
    });
  }

  render() {
    return (
      <span>
                <Col span={8} offset={8}>
                    <Typography.Title>{lang.global.login.titrePage}</Typography.Title>
                    <LoginForm onSubmit={this.onSubmit.bind(this)}/>
                </Col>
            </span>
    );
  }
}

export default withRouter(Login);
