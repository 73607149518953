import React from "react";
import {Select, Spin} from "antd";
import {CancelToken} from "axios";
import _ from "lodash";
import {withRouter} from "react-router-dom";
import {
  FaBuilding,
  FaExclamationTriangle,
  FaHome,
  FaSearch,
  FaUserTie,
} from "react-icons/fa";
import {AiFillDashboard} from "react-icons/ai";
import Highlighter from "react-highlight-words";
import {MagicSearchTitleEnum} from "./enums/DataTypeEnum";
import lang from "../../lang/fr"
import variables from "../../stylesheets/tools/variables";
import DataService from "../../services/dataService";

class CpMagicSearch extends React.Component {

  constructor(props) {
    super(props);

    //Initial state
    this.state = {
      dataSource: [],
      loading: false,
      source: null,
      error: null,
      typedValue: null,
    };
  }

  /**
   * On typing in the select, perform a search
   * @param value
   */
  handleSearch = (value) => {
    if (this.state.source) {
      //Cancel previously running request, if any
      this.state.source.cancel();
    }

    if (!value) {
      //Cleared search: reset & do nothing
      this.setState({loading: false, dataSource: []});
      return;
    }

    if (value.length < 3) {
      //If not at least X chars, do not take in account
      return;
    }

    //Create cancel token
    const source = CancelToken.source();

    //Set state
    this.setState(
      {source, loading: true, dataSource: [], error: null, typedValue: value});

    //Launch search
    DataService.getRechercheMagique(value, {cancelToken: source.token}).
      then(({data}) => {
        const {results = [], error = null} = data;
        this.setState({loading: false, dataSource: results, error});
      });
  };

  /**
   * On selecting a value
   * @param value
   */
  handleSelect = (value) => {
    this.props.history.push(value);
  };

  render() {
    const {dataSource, loading, error, typedValue} = this.state;
    const handleSearch = _.debounce(this.handleSearch, 250);
    const iconStyle = {position: "relative", top: 1};
    const { isAgency } = this.props;

    const icons = {
      Agency: <FaHome style={iconStyle}/>,
      Manager: <FaUserTie/>,
      Copro: <FaBuilding/>,
      Compteur: <AiFillDashboard style={iconStyle}/>,
    };

    const messagesNonAccessiblesSansProprietairesAssocies = {
      Agency: "Vous n'avez pas accès à cette agence.",
      Manager: "Vous n'avez pas accès à ce gestionnaire.",
      Copro: "Vous n'avez pas accès à cette copro.",
      Compteur: "Vous n'avez pas accès à ce compteur.",
    };

    const messagesNonAccessibles = {
      Agency: "Cette agence est gérée par ",
      Manager: "Ce gestionnaire est géré par ",
      Copro: "Cette copro est gérée par ",
      Compteur: "Ce compteur est géré par ",
    };

    //Error message, if any
    const errorMessage = error ?
      <span style={{color: "#666"}}><FaExclamationTriangle
        style={iconStyle}/> {error}</span> :
      null;

    return <Select
      showSearch
      showArrow={false}
      filterOption={false}
      dropdownMatchSelectWidth={false}
      placeholder={<span><FaSearch style={iconStyle}/>{lang.global.magicSearch.placeholder}</span>}
      notFoundContent={loading ? <Spin size="small"/> : errorMessage}
      loading={loading}
      onSearch={handleSearch}
      onSelect={this.handleSelect}
      className="cp-magic-search"
      value={null} //clear selected value
      style={{width: isAgency ? "100%" : "90%", height: 32}}
    >
      {_.chain(dataSource).groupBy("typeResultat").map((entities, group) => {
        return <Select.OptGroup key={group} label={
          <span>{icons[group]}&nbsp;{MagicSearchTitleEnum[group]}</span>}>
          {entities.map(d => (
            <Select.Option key={d.lien} disabled={d.accessibleAuCourtier ? '' : "disabled"} style={!d.accessibleAuCourtier ? {background:
              variables.grey4 , color:"black", opacity:0.40} : {}}>
              {icons[d.typeResultat]}&nbsp;
              {d.nom ?
                <Highlighter searchWords={[typedValue]}
                             textToHighlight={d.nom}></Highlighter> :
                null}
              {d.siret?
                <span className="cp-magic-search-details">{` - ${lang.global.magicSearch.siret} : `}<Highlighter searchWords={[typedValue]}
                                   textToHighlight={d.siret}></Highlighter></span>
                 :
                null}
              <div className="cp-magic-search-details">{d.details ?
                <Highlighter searchWords={[typedValue]}
                             textToHighlight={d.details}></Highlighter> :
                null}
              </div>

              <div className="cp-magic-search-details">
                {d.accessibleAuCourtier ?
                 null : <span className="cp-magic-search-details">
                    {d.proprietaires ?
                      messagesNonAccessibles[d.typeResultat] + d.proprietaires + '.' :
                      messagesNonAccessiblesSansProprietairesAssocies[d.typeResultat]
                    }
                </span>
                }
              </div>
            </Select.Option>
          ))}
        </Select.OptGroup>;
      }).value()}
    </Select>;
  }
}

export default withRouter(CpMagicSearch);
