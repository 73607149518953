import React from "react";
import {inject, observer} from "mobx-react";
import {Col, List, Row, Upload} from "antd";
import CpDeleteButton from "../common/buttons/CpDeleteButton";

import {CpIconContrats, CpIconImporter} from "../common/CpIcon";

import CpButton from "../common/buttons/CpButton";
import CpTitle from "../common/CpTitle";
import PropTypes from "prop-types";
import CpListHeader from "../common/list/CpListHeader";
import DataService from "../../services/dataService";

const {Dragger} = Upload;

@inject("dictionary", "interface", "user")
@observer
class Documents extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dataSource: this.props.dataSource,
      fileList: [],
      selectedDocuments: [],
    };
  }

  componentDidMount() {
  }

  /**
   * After deleting, remove the document from the dataSource
   * @param record
   */
  onDelete = (record) => {
    const dataSource = [...this.state.dataSource];
    dataSource.splice(dataSource.indexOf(record), 1);
    this.setState({dataSource});
  };

  /**
   * After click, open the document from the dataSource
   * @param data
   */
  onOpen = (data) => {
    window.open(data.url, "_blank");
  };

  /**
   * Will upload one file on the server
   */
  handleUpload = ({file}) => {
    const {agency, copro, compteur} = this.props;
    const {fileList} = this.state;

    //Set uploading state + update file list
    this.setState({
      fileList: [...fileList, file],
    });

    //Create our multi part
    const data = new FormData();

    if (copro) {
      data.append("copro", copro.id);
    } else if (compteur) {
      data.append("compteur", compteur.id);
    } else {
      data.append("agency", agency.id);
    }

    data.append("file", file);

    //Send our file
    DataService.postDocument(data).then(({data}) => {
      const dataSource = [...this.state.dataSource, data];

      //Update list of file in queue + dataSource
      this.setState({dataSource});
    }).finally(() => {
      const fileList = [...this.state.fileList];
      fileList.splice(fileList.indexOf(file), 1);
      this.setState({fileList});
    });
  };

  render() {
    //Our variables
    const {fileList, dataSource} = this.state;
    const isUploading = fileList.length > 0;
    const {user} = this.props.user;

    //Upload component props
    const uploadProps = {
      multiple: true,
      showUploadList: false,
      disabled: isUploading,
      fileList,
      customRequest: this.handleUpload,
    };

    return <div style={{marginTop: "16px"}}>
      <CpListHeader
        icon={<CpIconContrats/>}
        title="Document"
        count={dataSource ? dataSource.length : 0}
      />
      {user.roles.indexOf("ROLE_AGENCY") === -1 ?
        <Row gutter={[20, 20]}>
          <Col span={8}>
            <Dragger style={{width: "100%"}} {...uploadProps}>
              <CpIconImporter size={100}/>
              <p style={{padding: "5px", marginTop: "15px", fontSize: "14px"}}
                 className="ant-upload-text">Ajouter des documents</p>
            </Dragger>
          </Col>
          <Col span={16}>
            <List
              bordered={false}
              dataSource={this.state.dataSource}
              renderItem={data => (
                <List.Item actions={[
                  <CpButton
                    key={data["id"]}
                    onClick={this.onOpen.bind(this, data)}
                  >
                    Ouvrir
                  </CpButton>,
                  <CpDeleteButton
                    key={data["id"]}
                    entityIri={`/api/documents/${data["id"]}`}
                    onDelete={this.onDelete.bind(this, data)}
                    buttonProps={{
                      size: "small",
                      style: {marginLeft: 5},
                    }}/>,
                ]}>
                  <List.Item.Meta
                    title={<CpTitle title={data.documentName} level={4} style={{
                      display: "inline-block",
                      textOverflow: "ellipsis",
                      overflowX: "hidden",
                      maxWidth: "250px",
                      whiteSpace: "nowrap",
                    }}>{data.documentName}</CpTitle>}
                    description={<>{data.documentDate}</>}
                  />
                  <List.Item.Meta
                    title={<>Type</>}
                    description={<>{data.documentType}</>}
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
        :
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <List
              bordered={false}
              dataSource={this.state.dataSource}
              renderItem={data => (
                <List.Item actions={[
                  <CpButton
                    key={data["id"]}
                    onClick={this.onOpen.bind(this, data)}>
                    Ouvrir
                  </CpButton>,
                ]}>
                  <List.Item.Meta
                    title={<CpTitle title={data.documentName} level={4} style={{
                      display: "inline-block",
                      textOverflow: "ellipsis",
                      overflowX: "hidden",
                      maxWidth: "250px",
                      whiteSpace: "nowrap",
                    }}>{data.documentName}</CpTitle>}
                    description={<>{data.documentDate}</>}
                  />
                  <List.Item.Meta
                    title={<>Type</>}
                    description={<>{data.documentType}</>}
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      }

    </div>;
  }
}

Documents.propTypes = {
  hideDeleteButton: PropTypes.bool,
};
Documents.defaultProps = {
  hideDeleteButton: false,
};

export default Documents;
