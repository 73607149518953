import React, { useState, useEffect } from "react";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

function CpDateRangeFilter({ onFilterChange, extraFooter }) {
    const [plageDatesSelectionnees, setPlageDatesSelectionnees] = useState(null);

    const handleChange = (dates) => {
        setPlageDatesSelectionnees(dates && dates.length === 2 ? dates : null);
    };

    // Effet pour appeler onFilterChange lorsque plageDatesSelectionnees change
    useEffect(() => {
        onFilterChange({ range_echeance: plageDatesSelectionnees });
    }, [plageDatesSelectionnees, onFilterChange]);

    return (
        <RangePicker
            format="DD/MM/YYYY"
            onChange={handleChange}
            value={plageDatesSelectionnees}
            renderExtraFooter={() => extraFooter}
        />
    );
}

export default CpDateRangeFilter;
