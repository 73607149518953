const endpointEnum =  {
  GESTIONNAIRE_RECHERCHE: () => "/api/gestionnaires/recherche",
  VILLE_RECHERCHE: () => "/api/cities",
  DEPARTEMENT_RECHERCHE: "/api/departments",
  FOURNISSEURS_RECHERCHE: () => "/api/providers",
  AGENCE_RECHERCHE: () => "/api/agences",
  COMPTEUR_RECHERCHE: () => "/api/compteur",
  COPRO_RECHERCHE: () => "/api/copros",
  COMMERCIAL_AJOUT: (id) => `/api/agency/${id}/commercial`,
  COPRO_ASSOCIEE: (endpoint, id) => `/api/${endpoint}/${id}/copros`,
  EXPORT: () => "/api/export/",
  IMPORT: () => "/api/import/",
  IMPORT_UPDATE: () => "/api/import/update/",
};

export default endpointEnum;
