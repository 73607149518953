import React from "react";
import {Button, Form, Input} from "antd";
import {LockOutlined, UserOutlined} from "@ant-design/icons";

class LoginForm extends React.Component {

  handleSubmit = values => {
    this.props.onSubmit(values);
  };

  render() {
    return (
      <Form onFinish={this.handleSubmit} initialValues={{remember: true}}
            className="login-form">
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Merci de fournir votre nom d'utilisateur!",
            }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon"
                                  style={{color: "rgba(0,0,0,.25)"}}/>}
            placeholder="Nom d'utilisateur"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Merci de fournir votre mot de passe!",
            }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon"
                                  style={{color: "rgba(0,0,0,.25)"}}/>}
            type="password"
            placeholder="Mot de passe"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button"
                  block>
            Connexion
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default LoginForm;
