import {action, computed, observable} from "mobx";
import DataService from "../services/dataService";
import Moment from "moment";
import {DataTypeEnum} from "../components/common/enums/DataTypeEnum";

class List {
  @observable data = observable.map({});
  @observable filters = {
    [DataTypeEnum.COMPTEUR]: {
      default: null,
    },
    [DataTypeEnum.COPRO]: {
      default: null,
    },
    [DataTypeEnum.AGENCY]: {
      default: null,
      filterCommerciaux: null,
      filterNameDep: null,
    },
    [DataTypeEnum.USER]: {
      default: null,
    },
  };
  @observable filterFields = {};
  @observable filterCompteurType = {
    values: [],
    field: "",
  };
  @observable filterCompteurTrend = {
    value: false,
  };
  @observable filterTransaction = {
    value: false,
  };
  @observable filterConsommation = {
      type: null,
      montant: null
  };
  @observable filterFournisseur = null;
  @observable filtersDeadline = {
    value: null,
    field: "",
  };
  @observable filterDateRange = {
    dateStart: "",
    dateEnd: "",
    dateField: "",
  };
  @observable inProgress = false;
  @observable dependencyProgress = false;
  @observable type = null;

  @computed
  get items() {
    let filteredItems = [...this.data.values()];

    //get filters from type
    var filters = this.getFilters();

    if (filters && Object.keys(filters).length) {
      for (const [key, value] of Object.entries(this.filterFields)) {
        filteredItems = !filters[key] || !filters[key].length ?
          filteredItems :
          filteredItems.filter(
            item => this.fieldsMapper(item, filters[key] ? filters[key] : [],
              value),
          );
      }
    }

    if (this.filterCompteurType.field) {
      filteredItems = filteredItems.filter(
        item => this.filterCompteurType.values.indexOf(
          item[this.filterCompteurType.field]) !== -1,
      );
    }

    //CpDeadlineFilter
    if (this.filtersDeadline.value) {
      filteredItems = filteredItems.filter(item =>
        item[this.filtersDeadline.field] !== null &&
        Moment(item[this.filtersDeadline.field]).
          isBetween(Moment(),
            Moment().add(this.filtersDeadline.value, "months")),
      );
    }

    if (this.filterDateRange.dateStart && this.filterDateRange.dateEnd) {
      filteredItems = filteredItems.filter(
        item => {
          if (item[this.filterDateRange.dateField]) {
            const itemDate = Moment(item[this.filterDateRange.dateField]).
              format("DD/MM/YYYY");

            return Moment(itemDate, "DD/MM/YYYY").isBetween(
              Moment(this.filterDateRange.dateStart, "DD/MM/YYYY"),
              Moment(this.filterDateRange.dateEnd, "DD/MM/YYYY"),
            );
          }
          return false;
        },
      );
    }
    if (this.filterCompteurTrend.value) {
      filteredItems = filteredItems.filter(
        item => item.tendance_marche !== null,
      );
    }
    if (this.filterTransaction.value) {
      const datePlusDe12Mois = Moment(new Date()).subtract(12, "months");
      filteredItems = filteredItems.filter(
        item => item.transaction_id === null ||
          (item.date_creation_transaction !== null && Moment(
            item.date_creation_transaction).isBefore(datePlusDe12Mois))
      );

      }
      if (this.filterFournisseur) {
          filteredItems = filteredItems.filter((item) => {
              return this.filterFournisseur === 'inconnu' ?  item.fournisseur === null : item?.fournisseur?.id === this.filterFournisseur;
          })
      }
      if(this.filterConsommation.montant && this.filterConsommation.type){
          switch (this.filterConsommation.type) {
              case 'superieur':
                  filteredItems = filteredItems.filter((item) => item?.consommation?.totale > this.filterConsommation.montant);
                  break;
              case 'inferieur':
                  filteredItems = filteredItems.filter((item) => item?.consommation?.totale < this.filterConsommation.montant);
                  break;
              case 'egal':
                  filteredItems = filteredItems.filter((item) => item?.consommation?.totale === this.filterConsommation.montant
                  );
                  break;
          }
      }
      return filteredItems;
  }

  @action setType(type = null) {
    this.type = type;
    this.reset();
  }

  /**
   * Set filters from value
   * @param value
   * @param name
   */
  @action setFilters(value, name) {
    //prevent user to don't repertories filters in this store
    if (!Object.keys(this.getFilters()).
      find(filterName => filterName === name)) {
      console.warn(
        `The filter with name "${name}" has not initialized for "${this.type}" type see listStore -> filters.`);
    }

    switch (this.type) {
      case DataTypeEnum.COPRO:
      case DataTypeEnum.COMPTEUR:
        this.filters[DataTypeEnum.COPRO][name] = value;
        this.filters[DataTypeEnum.COMPTEUR][name] = value;
        break;
      default:
        this.filters[this.type][name] = value;
        break;
    }
  }

  /**
   * Reset filter by type
   * @param {DataTypeEnum} filterType
   */
  @action resetFilter(filterType) {
    if (!this.filters[filterType]) {
      return;
    }

    for (const [key] of Object.entries((this.filters[filterType]))) {
      this.filters[filterType][key] = null;
    }
  }

  /**
   * Return this.filters by actual type
   * @returns {array}
   */
  getFilters() {
    return this.filters[this.type];
  }

  @action reset() {
    this.data = observable.map({});

    // reset date range filter
    this.filterDateRange = {
      dateStart: "",
      dateEnd: "",
      dateField: "",
    };

    //reset deadline filter
    this.filtersDeadline = {
      value: null,
      field: "",
    };

    //reset compteur type filter
    this.filterCompteurType = {
      values: [],
      field: "",
    };
    // reset compteur trend filter
    this.filterCompteurTrend = {
      value: false,
    };
    this.filterTransaction = {
      value: false,
    };
  this.filterConsommation = {
      type: null,
      montant: null,
  };
  this.filterFournisseur = null;
  }

  @action load(params) {
    this.inProgress = true;
    return DataService.getAll(this.type, params).then(action(({data}) => {
      this.data.clear();
      if (data["hydra:member"]) {
        data["hydra:member"].forEach(item => this.data.set(item.id, item));
        return;
      }
      data.forEach(item => this.data.set(item.id, item));
    })).finally(action(() => {
      this.inProgress = false;
    }));
  }
  @action getByAgencyId(agencyId) {
    this.inProgress = true;
    return DataService.getByAgencyId(this.type, agencyId).then(action(({data}) => {
      this.data.clear();
      if (data["hydra:member"]) {
        data["hydra:member"].forEach(item => this.data.set(item.id, item));
        return;
      }
      data.sort((a, b) => {
        if (a.date_echeance) {
          return new Date(a.date_echeance) - new Date(b.date_echeance)
        }
      })
      data.forEach(item => this.data.set(item.id, item));
    })).finally(action(() => {
      this.inProgress = false;
    }));
  }

  /**
   * Action for fill dependency by id
   * @param id
   * @param dependencyFields
   * @returns {*}
   */
  @action fillDependenciesById(id, dependencyFields) {

    let item = this.getItem(id);

    if (!item) {
      console.error(
        `fillDependenciesById: error no object found with ${id} id.`);
      return;
    }

    if (!dependencyFields || !dependencyFields.length) {
      console.error(
        `fillDependenciesById: you need to give fields for fill dependency by field.`);
      return;
    }

    this.dependencyProgress = true;

    return DataService.getDependenciesFromId(id).
      then(action(({data}) => {
          for (let field in dependencyFields) {
                  item[dependencyFields[field]] = data[dependencyFields[field]];
        }
      })).
      finally(action(() =>
          this.dependencyProgress = false
      ));
  }

  /**
   * Action fpour ajouter ou modifier un item
   * @param data
   */
  @action updateItem(data) {
    this.data.set(data.id, data);
  }

  /**
   * Action fpour ajouter ou modifier un item
   * @param id
   */
  @action removeItem(id) {
    this.data.delete(id);
  }

  // /**
  //  * Update Item Attribute
  //  * @param id
  //  * @param field
  //  * @param data
  //  */
  // @action updateItemField(id, field, data){
  //     let item = this.getItem(id);
  //     item[field] = data;
  // }
  //
  // /**
  //  * Reload single list Item
  //  * @param id
  //  * @returns {*}
  //  */
  // @action reloadItem(id) {
  //     let item = this.getItem(id.toString());
  //
  //     if (!item) {
  //         console.error(`reloadItem: error no object found with ${id} id.`);
  //         return;
  //     }
  //
  //     return DataService.getById(this.type, id)
  //         .then(({ data }) => {
  //             this.data.set(item.id, data);
  //             return data;
  //         })
  // }

  /**
   * Local Functions
   */
  clear() {
    this.data.clear();
  }

  /**
   * Gets Item from List by indexed Id
   * @param id
   * @returns {any}
   */
  getItem(id) {
    return this.data.get(id);
  }

  /**
   * Do filter mapping from object and field
   * @param item
   * @param filters
   * @param fields
   * @returns {boolean}
   */
  fieldsMapper(item, filters, fields) {
    let result = false;
    fields.map(field => {
      if (item[field] && this.matchWithFilters(filters, item[field])) {
        result = true;
      }
    });

    return result;
  }

  /**
   * Matching filter with includes
   * @param filters
   * @param value
   * @returns {boolean}
   */
  matchWithFilters(filters, value) {
    return !!filters.filter(
      filter => value.toLowerCase().includes(filter.toLowerCase())).length;
  }
}

export default new List();
