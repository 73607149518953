import React from "react";
import styled from "styled-components";
import {Button} from "antd";
import variables from "../../../stylesheets/tools/variables";

const buttons = {};

const BaseButton = styled(Button)`
    height: ${({cpsize}) => ["lg", "large"].indexOf(cpsize) >= 0 ?
  "32px" :
  "26px"};
    line-height: ${({cpsize}) => ["lg", "large"].indexOf(cpsize) >= 0 ?
  "32px" :
  "26px"};
    vertical-align: bottom;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 9px;
    padding: ${({cpsize}) => ["lg", "large"].indexOf(cpsize) >= 0 ?
  "0 8px" :
  "0 6px"};

    &[disabled]{
        opacity: 0.1 !important;
    }

    .anticon{
        font-size: 15px;
        position: relative;
        top: 2px;
    }
`;

buttons.principal = styled(BaseButton)`
    &, &.ant-btn[disabled] {
        background-color: ${variables.grey9};
        border-color: ${variables.grey4};
        color: ${variables.white};
    }
`;

buttons.primary = styled(BaseButton)`
    &, &.ant-btn[disabled] {
        background-color: ${variables.grey10};
        border-color: ${variables.grey4};
        color: ${variables.$grey8};
    }
`;

buttons.secondary = styled(BaseButton)`
    &, &.ant-btn[disabled] {
        background-color: ${variables.white};
        border-color: ${variables.grey4};
        color: ${variables.grey8};
    }
`;

buttons.tertiary = styled(BaseButton)`
    &, &.ant-btn[disabled] {
        background-color: ${variables.grey10};
        border-color: ${variables.grey4};
        color: ${variables.$grey11};
    }
`;

const CpButton = React.forwardRef(({type = "primary", size, ...rest}, ref) => {

  if (buttons[type]) {
    return React.createElement(buttons[type],
      {cpsize: size, ...rest, ref: ref});
  } else {
    return <BaseButton ref={ref} type={type} size={size} {...rest}/>;
  }
});

export default CpButton;
