import React from 'react';
import {Breadcrumb} from "antd";

const BreadCrumbPE = ({listeNavigation}) => {
    return (
      <Breadcrumb style={{marginTop: "15px"}}>
        <Breadcrumb.Item>
          <a href="/">Accueil</a>
        </Breadcrumb.Item>
        {listeNavigation.map((item, key)=>
          <Breadcrumb.Item key={key}>
            { item.href ?
              <a href={item.href}>{item.titre}</a> : <span>{item.titre}</span>
            }
          </Breadcrumb.Item>)
        }
      </Breadcrumb>
    );
};

export default BreadCrumbPE;
