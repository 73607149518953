import React from "react";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {Col, Layout, Row} from "antd";
import {CpIconMail, CpIconPhone} from "../../components/common/CpIcon";
import CpTitle from "../../components/common/CpTitle";
import {DataTypeEnum} from "../../components/common/enums/DataTypeEnum";
import CpHeadCapsule from "../../components/common/CpHeadCapsule";
import AgencyCoproList from "./AgencyCoproList";
import variables from "../../stylesheets/tools/variables";
import BreadCrumbPE from "../../components/common/BreadCrumbPE";

@inject("dictionary", "detail", "user")
@observer
class ManagerDetail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {userIri: null};

    //instance type for detail
    this.props.detail.setType(DataTypeEnum.MANAGER);
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.detail.reset().load(id);
    const {user} = this.props.user;
    this.setState({userIri: "/api/users/" + user.id});
  }

  render() {
    const {item} = this.props.detail;

    if (!item || item.agency === undefined) return null;
    const listeNavigation = [
      {
        titre: item.agency.name,
        href: "/agency"
      },
      {
        titre: item.qualifiedName,
        href: `/manager/${item.id}`
      },
      {
        titre: `Copropriétés`}
    ];
    return (
      <Layout>

        {/* Main Informations & Comments */}
        <BreadCrumbPE listeNavigation={listeNavigation}/>
        <Row align="middle">
          <CpTitle level={1}> {item.qualifiedName}
          </CpTitle>
        </Row>

        {/* GESTIONNAIRE INFO */}
        <CpHeadCapsule style={{width: "60%", margin: "0 auto 20px auto"}}>
          <Row align={"top"}>
            <Col span={24}>
              <div style={{
                display: "inline-block",
                verticalAlign: "middle",
                marginRight: "15px",
              }}>{item.phone ?
                <CpIconPhone/> : null}
                {item.email ? <a style={{marginLeft: 5, color: "inherit"}}
                                 href={"mailto:" +
                                 item.email}><CpIconMail/></a> : null}
              </div>
              <div style={{display: "inline-block", verticalAlign: "middle"}}>
                {item.email}<br/>
                {item.phone}
                {item.mobile && item.phone ?
                  <span style={{
                    color: variables.waterGreenPrimary,
                    marginRight: 5,
                    marginLeft: 5,
                  }}>//</span> :
                  ""}
                {item.mobile}
              </div>
            </Col>
          </Row>
        </CpHeadCapsule>

        <AgencyCoproList managerId={item.id}/>
      </Layout>
    );
  }
}

export default withRouter(ManagerDetail);
