import React, {useEffect, useState} from "react";
import logoMaestro from "../../public/images/logo_maestro.jpg";
import {Upload, message, Layout, List} from "antd";
import CpTitle from "../common/CpTitle";
import {CpIconImporter} from "../common/CpIcon";
import BreadCrumbPE from "../common/BreadCrumbPE";
import {Link, withRouter} from "react-router-dom";
import DataService from "../../services/dataService";
import {DataTypeEnum} from "../common/enums/DataTypeEnum";
import "../../stylesheets/main.css";
import Moment from "moment";

function MaestroUploadForm(props) {
  let [listeDemande, setListeDemande] = useState();
  let listeEnvoyee = 0;
  let [setStatut] = useState();

  const {Dragger} = Upload;
  const [nomGestionnaire, setNomGestionnaire] = useState(props.location?.managerName);
  const [idAgence, setIdAgence] = useState(0);
  const {id} = props.match.params;

  useEffect(() => {
    setListeDemande(getListeDemande);
  }, []);

  setStatut = (item) => {
    return item.date_reception == null ? "En attente de réception depuis Maestro" :
           item.traitee ? "Traitée" : "Prête à être traitée";
  };

  DataService.getById(DataTypeEnum.MANAGER, id).then(({data}) => {
    return [setNomGestionnaire(data.qualifiedName), setIdAgence(data.agency.id)];
  }, []);

  const getListeDemande = () => {
    DataService.getDemandes(id).then(({data}) => {
      return setListeDemande(data.sort((a, b) => Number(a.traitee) - Number(b.traitee) || new Date(a.date_demande) - new Date(b.date_demande)));
    });
  };

  const description = (date) => {
    if (date) {
      const momentDate = Moment(date);
      return `${momentDate.format("DD/MM/YYYY")} à ${momentDate.format("HH:mm:ss")}`;
    } else {
      return "La demande n'est pas encore reçue";
    }
  };

  //necessaire pour désactiver le comportement par défaut du composant Upload
  const customRequest = () => {
    return true;
  };

  const beforeUpload = (info, files) => {
    //On incrémente la variable listeEnvoyee pour appeler l'API une seule fois par demande
    listeEnvoyee++;
    if (listeEnvoyee !== files.length) {
      return;
    }
    listeEnvoyee = 0;
    let tailleTotale = 0;
    const factures = new FormData();
    files.forEach((fichier) => {
      tailleTotale += fichier.size;
      factures.append("files[]", fichier);
    });
    if (tailleTotale >= 1073741824) {
      message.error(`Les fichiers ne doivent pas excéder 1Go`);
      return;
    }
    DataService.postFacture(id, factures).then((res) => {
      if (res.status === 200) {
        message.success(
          `Chargé avec succès. Vous recevrez un email lorsque la demande sera traitée.`);
        setListeDemande(getListeDemande);
      } else {
        message.error(
          `Une erreur s'est produite, la demande n'a pas pu être importée.`);
      }
    }).catch();
  };

  const parametresDragger = {
    name: "files[]",
    accept: ".pdf,.zip",
    headers: {
      "X-Requested-With": null, //Avoids Unauthorized header x-requested-with
    },
    showUploadList: false,
    withCredentials: true, //ajax upload with cookie sent
    multiple: true,
    customRequest,
    beforeUpload,
  };

  const listeNavigation = [
    {
      titre: `Agences`,
      href: "/agency",
    },
    {
      titre: "Gestionnaires",
      href: `/agency/${idAgence}/copros`,
    },
    {
      titre: nomGestionnaire,
      href: `/manager/${id}/copros`,
    },
    {
      titre: `Maestro`,
    },
  ];

  return (
    <Layout>
      <BreadCrumbPE listeNavigation={listeNavigation}/>
      <div className="upload-maestro">
        <img className="img-maestro-form" src={logoMaestro}
             alt="maestro"/>
        <Dragger {...parametresDragger}>
          <CpTitle
            title="Maestro"
            level={2}>Maestro
          </CpTitle>
          <CpIconImporter size={100}/>
          <p className="ant-upload-text">Déposez vos factures ici et laissez le chef d'orchestre s'en occuper.</p>
          <p className="ant-upload-text">Il vous enverra les résultats par email.</p>
        </Dragger>
      </div>
      <div className="info-upload-maestro">
        <p className="p-phrase-intro-maestro">Quelques infos à respecter pour que le travail de Maestro se passe bien : </p>
        <p className="p-liste-contraintes">Le temps de traitement peut varier en fonction de l'utilisation globale du
          service (5 secondes par facture)<br/>
          Les fichiers que vous envoyez ne doivent pas dépasser 1Go et être au format .ZIP<br/>
          Maestro ne lit qu'un seul PDL par PDF</p>
        <a href="https://opera-hubspot.zendesk.com/hc/fr/articles/4795727435025-C-est-quoi-Maestro" target="_blank"
           rel="noopener noreferrer" style={{display: "grid", justifyContent: "center"}}>En savoir +</a>
      </div>
      {listeDemande?.length > 0 ?
       <h3>{listeDemande.length} demande{listeDemande.length > 1 ? "s" : ""} effectuée{listeDemande.length > 1 ? "s" :
                                                                                       ""}</h3> :
       <h4 className="titre-demande-en-cours">Aucune demande en cours pour ce gestionnaire</h4>}
      {listeDemande?.map((item, key) => {
        return (
          <Layout key={key}>
            <Link className={item.traitee ? "demande-traitement-link traitee" : "demande-traitement-link"}
                  to={`/gestionnaire/${id}/demandes-traitement-facture/${item.id}`}
                  disabled={item.traitee || item.date_reception === null}>
              <List.Item style={{display: "flex"}} className="demande-traitement-item">
                <List.Item.Meta
                  title="Envoyée le :"
                  description={description(item.date_demande)}/>
                <List.Item.Meta
                  title="Reçue le :"
                  description={description(item.date_reception)}/>
                {item.date_reception == null ? <List.Item.Meta/> : <List.Item.Meta
                  className="statut"
                  title="Statut :"
                  description={setStatut(item)}/>
                }
              </List.Item>
            </Link>
          </Layout>
        );
      })
      }
    </Layout>
  );
}

export default withRouter(MaestroUploadForm);
