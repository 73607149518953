import React from "react";
import {Divider, Input, notification, Select} from "antd";
import PropTypes from "prop-types";

class CpAjaxSelectAdd extends React.Component {

  constructor(props) {
    super(props);

    //Initial state
    this.state = {name: ""};

    this.onCityChange = this.onCityChange.bind(this);
    this.addCity = this.addCity.bind(this);
    this.onCitySelect = this.onCitySelect.bind(this);
  }

  onCityChange = event => {
    this.setState({
      name: event.target.value,
    });
  };

  onCitySelect = (value, object) => {
    const {formRef, onSelectCity = null, cities = []} = this.props;
    formRef.current.setFieldsValue({addressCity: value});

    const city = cities.find(c => c.name === object.value);

    if (onSelectCity) {
      onSelectCity(city);
    }
  };

  addCity = () => {
    const {cities, onSelectCity} = this.props;
    const {name} = this.state;

    if (cities.find(c => c.name === name)) {
      notification.error({
        message: `La ville "${name}" est déjà présente.`,
      });
      return;
    }

    cities.push({name: name, zipCode: ""});
    this.setState({
      name: "",
    });
    onSelectCity({name: name, zipCode: ""});
  };

  render() {
    const {
            selectedValue,
            cities,
          } = this.props;

    return <Select
      defaultValue={selectedValue}
      onChange={(value, object) => this.onCitySelect(value, object)}
      placeholder="-"
      value={selectedValue}
      dropdownRender={menu => (
        <div>
          {menu}
          <Divider style={{margin: "4px 0"}}/>
          <div style={{display: "flex", flexWrap: "nowrap", padding: 8}}>
            <Input style={{flex: "auto"}} value={this.state.name}
                   onChange={this.onCityChange}/>
            <a
              style={{
                flex: "none",
                padding: "8px",
                display: "block",
                cursor: "pointer",
              }}
              onClick={this.addCity}
            >
              Ajouter
            </a>
          </div>
        </div>
      )}
    >
      {cities.map((item, i) => (
        <Select.Option key={i} value={item.name}>{item.name}</Select.Option>
      ))}
    </Select>;
  }
}

CpAjaxSelectAdd.propTypes = {
  endPoint: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  selectedValue: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

export default CpAjaxSelectAdd;
