export default {
  message:{
    coproMessage: "La copropriété",
    suppressionEffectue: "a bien été supprimée.",
    confirmationSuppression: "Êtes-vous sûr(e) de vouloir supprimer cette copropriété ?",
  },
  label:{
    referenceID: "ID de référence:",
    adresse: "adresse",
    copropriete: "Copropriété",
    coproprietes: "Copropriétés",
    compteurs: "Compteurs",
    conseilSyndical: "Conseil syndical",
    dateDecision: "Date décision",
    deplacerCopro: "Déplacer la copropriété",
    tousCompteursDocsEtc: "Tous les compteurs, documents, contrats, etc...",
    liesCopro: "liés à la copropriété",
    serontDeplacesVersGestionnaire: "seront également déplacés vers ce gestionnaire.",
    commentaire:"Commentaire",
    aucunCommentaire:"Aucun commentaire",
    transaction:"Transaction",
    voirTraviata:"Voir dans Traviata",
    voirHubspot:"Voir dans Hubspot",
  },
}
