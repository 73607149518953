import React from "react";
import {Button, Row, Select, Typography} from "antd";

export default function TransactionSelectManager(props) {
  const {AfficherButton = true, onBack, onChangeHandler, onFinish, title, valid, gestionnaires} = props;
  const {Title} = Typography;

  return (
    <>

      <Row justify={"center"}>
        <Typography>
          <Title level={4}>{title}</Title>
        </Typography>
      </Row>
      <Row gutter={24} justify={"center"}>
        <Select
          placeholder={title}
          onChange={key => onChangeHandler({key})}
        >
          {gestionnaires.map((gestionnaire, i) => (
            <Select.Option key={i}
                           value={gestionnaire.id}>{`${gestionnaire.prénom} ${gestionnaire.nom}`}</Select.Option>
          ))
          }
        </Select>
      </Row>
      {AfficherButton &&
        <Row justify={"space-between"} style={{marginTop: "15px"}}>
          <Button onClick={onBack} type="secondary">
            Précédent
          </Button>
        </Row>}
      <Row justify={"end"}>
        <Button onClick={onFinish} type="primary" disabled={!valid}>
          Suivant
        </Button>
      </Row>
    </>);


}
